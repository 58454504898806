import { ThemeProvider } from "@mui/system";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import store from "./dashboard/store";
import ActivateGroupUserPage from "./pages/ActivateGroupUserPage";
import ActivateUserPage from "./pages/ActivateUserPage";
import AnswerReportStolenDeviceQuestionsPage from "./pages/AnswerReportStolenDeviceQuestionsPage";
import AnswerSecurityQuestionsPage from "./pages/AnswerSecurityQuestionsPage";
import AuthMobilePage from "./pages/AuthMobilePage";
import AuthPage from "./pages/AuthPage";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import RegisterPage from "./pages/RegisterPage";
import ReportStolenDevicePage from "./pages/ReportStolenDevicePage";
import ResetUserPage from "./pages/ResetUserPage";
import UpdateSequenceForGroupUserActivationPage from "./pages/UpdateSequenceForGroupUserActivationPage";
import { AuthProvider } from "./providers/AuthProvider";
import { queryClient } from "./query-client";
import { RequireAuth } from "./routes";
import { theme } from "./theme";
import HomePage from "./pages/HomePage";
import { lightTheme } from "./share/themas";
import { Box } from "@mui/material";
import { DocumentTitle } from "./share/utils/documentTitle";
import CheckDevice from "./components/CheckUserAgent/CheckDevice";

const routes = [
  {
    path: "/",
    element: (
      <ThemeProvider theme={lightTheme}>
        <HomePage />
      </ThemeProvider>
    ),
    title: 'SyMania',
  },
  {
    path: "/chk",
    element: <CheckDevice />,
    title: 'Check Device',
  },
  {
    path: "/register",
    element: (
      <ThemeProvider theme={lightTheme}>
        <RegisterPage />
      </ThemeProvider>
    ),
    title: 'Register',
  },
  // {
  //   path: "/login",
  //   element: <LoginPage />,
  // },
  {
    path: "/logout",
    element: <LogoutPage />,
    title: 'Logout',
  },
  // {
  //   path: "/auth",
  //   element: <AuthPage />,
  // },
  {
    path: "/auth-mobile",
    element: <AuthMobilePage />,
    title: 'Auth Mobile'
  },
  {
    path: "/reset-secrets",
    element: <ResetUserPage />,
    title: 'Reset secrets',
  },
  {
    path: "/reset-secrets/:resetUserToken",
    element: <AnswerSecurityQuestionsPage />,
    title: 'Reset secrets',
  },
  {
    path: "/activate-group-user/:activateGroupUserToken",
    element: <ActivateGroupUserPage />,
    title: 'Activate user',
  },
  {
    path: "/activate-user/:activateUserToken",
    element: <ActivateUserPage />,
    title: 'Activate user',
  },
  {
    path: "/activate-group-user/:activateGroupUserToken/update-sequence",
    element: <UpdateSequenceForGroupUserActivationPage />,
    title: 'Activate user',
  },
  {
    path: "/report-stolen-device",
    element: <ReportStolenDevicePage />,
    title: 'Report',
  },
  {
    path: "/report-stolen-device/answer",
    element: <AnswerReportStolenDeviceQuestionsPage />,
    title: 'Report',
  },
  {
    path: "/:userId/*",
    element: (
      <RequireAuth>
        <DashboardPage />
      </RequireAuth>
    ),
  },
];

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                {routes.map((route) => (
                  <Route key={route.path} {...route} />
                ))}
              </Routes>
              <DocumentTitle routes={routes} />
            </BrowserRouter>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
