import { AppBar, IconButton, Toolbar } from "@mui/material";
import { LightLogo } from "src/components/SymanisLogo";
import Close from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router-dom";
import { OutlinedButton } from "src/components/Button";
import { appColor } from "src/share/colors";

interface AuthLayoutProps {
  children: React.ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
  const navigate = useNavigate()
  const location = useLocation()
  function handleHome() {
    if (location.pathname !== "/register") navigate('/')
  }
  function handleBack() {
    navigate(-1)
  }
  return (
    <div className="flex flex-col px-16 py-10 min-h-screen bg-hero bg-cover bg-center bg-no-repeat">
      <AppBar sx={{ boxShadow: 'none', borderBottom: '1px solid #DCDBE8' }} position="sticky">
        <Toolbar className='bg-white p-2 rounded-t-lg flex justify-between'>
          <IconButton
            size="large"
            edge="start"
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            <Close />
          </IconButton>
          <div className="cursor-pointer flex" onClick={handleHome}>
            <LightLogo />
          </div>
          <div>
            {/* <OutlinedButton title='Need Help?' titleColor={appColor.black}/> */}
          </div>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
}

export default AuthLayout;
