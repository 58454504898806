import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import {
  AppContent,
  AppSidebar,
  AppFooter,
  AppHeader,
  AppBreadcrumb,
} from "../components/index";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const DashboardWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
  currentpath?: string;
}>(({ theme, open, currentpath = "" }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  backgroundColor: "white",
  minHeight: "100vh",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface DefaultLayoutProps {
  routes: any[];
}

const DefaultLayout = ({ routes }: DefaultLayoutProps) => {
  const sidebarShow = useSelector<any, any>((state) => state.sidebarShow);
  const [value, setValue] = useState(0);
  const location = useLocation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* <AppSidebar drawerWidth={drawerWidth} /> */}
      <DashboardWrapper currentpath={location.pathname} open={sidebarShow}>
        <AppHeader value={value} handleChange={handleChange} />
        <AppContent routes={routes} />
        <AppFooter />
      </DashboardWrapper>
    </div>
  );
};

export default DefaultLayout;
