import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const addPhoneFn = async ({
  countryCode,
  phoneNumber,
}: AddPhoneInput) => {
  const { data } = await apiRequest.post<AddPhoneResponse>(ENDPOINTS.PHONES, {
    country_code: countryCode,
    phone: phoneNumber,
  });
  return data.unique;
};

export const validatePhoneFn = async ({ unique, code }: ValidatePhoneInput) => {
  await apiRequest.post(ENDPOINTS.CODES + '/validate', { unique, code });
};

type AddPhoneInput = {
  countryCode: string;
  phoneNumber: string;
};

type AddPhoneResponse = {
  unique: string;
};

type ValidatePhoneInput = {
  unique: string;
  code: string;
};
