import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getColorsFn = async ({
  page,
  pageSize,
  query,
}: GetColorsInput) => {
  const { data } = await apiRequest.get<GetColorsResponse>(ENDPOINTS.COLORS, {
    params: {
      ...query,
      page: page + 1,
      limit: pageSize,
    },
  });
  return data;
};

export const updateColorFn = async (data: Color) => {
  await apiRequest.put(`${ENDPOINTS.COLORS}/${data.id}`, data);
};

export const createColorFn = async (data: NewColor) => {
  await apiRequest.post(ENDPOINTS.COLORS, data);
};

export const deleteColorFn = async (id: number) => {
  await apiRequest.delete(`${ENDPOINTS.COLORS}/${id}`);
};

type GetColorsInput = {
  page: number;
  pageSize: number;
  query?: any;
};

type GetColorsResponse = {
  meta: {
    total: number;
    page: number;
    limit: number;
  };
  data: Color[];
};

type Color = {
  id: number;
  name: string;
  hex: string;
};

type NewColor = Omit<Color, 'id'>;
