import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "src/providers/AuthProvider";
import { UserDetailsProvider } from "src/providers/UserDetailsContext";
import DefaultLayout from "./layout/DefaultLayout";
import { orgAdminRoutes, personalRoutes, symAdminRoutes } from "./routes";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page404/Page404";
import { Box } from "@mui/material";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const { user } = useAuth();

  if (!user) {
    return <Page404 />;
  }

  return (
    <UserDetailsProvider userId={user.id}>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route
            path="/org-admin/:orgId/*"
            element={<DefaultLayout routes={orgAdminRoutes} />}
          />
          <Route
            path="/sym-admin/*"
            element={<DefaultLayout routes={symAdminRoutes} />}
          />
          <Route
            path="/personal/*"
            element={<DefaultLayout routes={personalRoutes} />}
          />
        </Routes>
      </Suspense>
    </UserDetailsProvider>
  );
}

export default App;
