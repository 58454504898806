import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GroupsTab from "./GroupsTab";

function GroupsPage() {
  const navigate = useNavigate();
  const { userId, orgId } = useParams();

  const handleGroupClick = (groupId: number) => {
    navigate(`/${userId}/org-admin/${orgId}/users?groupID=${groupId}&isCurGroupUsers=true`);
  };

  return (
    <Box pt={5}>
      <GroupsTab handleGroupClick={handleGroupClick}/>
    </Box>
  );
}

export default GroupsPage;
