import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  Divider,
  ListSubheader,
  Tab,
  Link,
  Tabs,
  styled,
} from "@mui/material";
import { MdMenu as MenuIcon } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "src/providers/AuthProvider";
import {
  LinkProps,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { USER_ROLES } from "src/share/constants";
import { useUserDetails } from "src/providers/UserDetailsContext";
import { useMutation } from "react-query";
import { logoutUserFn } from "src/api";
import { Link as RouterLink } from "react-router-dom";
import { Images } from "src/assets";
import UpdateUserImageForm from "src/components/UpdateUserImageForm";
import { decryptFn } from "src/share/utils/decrypt";
import { getInitialsAndColor } from "src/share/utils/getInitialsAndColor";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "white",
  },
});

interface StyledTabProps {
  label: string;
  component?: React.ComponentType<LinkProps>; // For React Router Link components
  to?: string | { pathname: string }; // For React Router location objects
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#fff",
    fontWeight: "bold",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

interface AppHeaderProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const AppHeader = ({ value, handleChange }: AppHeaderProps) => {
  const { user, logout } = useAuth();
  const { userDetails, refetchUserDetails } = useUserDetails();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarShow = useSelector<any>((state) => state.sidebarShow);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { orgId: currentOrgId } = useParams();
  const location = useLocation();
  let currentOrgName

  const [storedTabIndex, setStoredTabIndex] = useState<number>(() => {
    const storedIndex = localStorage.getItem("activeTabIndex");
    return storedIndex ? parseInt(storedIndex) : 0;
  });


  const { mutate: logoutUser } = useMutation(logoutUserFn, {
    onSettled: () => {
      logout();
      navigate("/");
      handleCloseUserMenu();
    },
  });


  const availableOrganizations = useMemo(() => {
    return (
      userDetails?.orgs.filter(
        (org) => currentOrgId === undefined || org.id !== parseInt(currentOrgId)
      ) ?? []
    );
  }, [currentOrgId, userDetails?.orgs]);

  if (currentOrgId) {
    currentOrgName = userDetails?.orgs.find((org) => +currentOrgId === org.id)
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    localStorage.setItem("activeTabIndex", "0");
    setStoredTabIndex(0)

  };

  useEffect(() => {
    // Store the active tab index when the value changes
    localStorage.setItem("activeTabIndex", value.toString());
  }, [value]);

  const handleLogout = async () => {
    localStorage.setItem("activeTabIndex", "0");
    logoutUser();
  };

  let fullname = ''
  if (userDetails) {
    fullname = `${decryptFn(String(userDetails.firstName))} ${decryptFn(String(userDetails.lastName))}`
  }
  const { initials, color } = getInitialsAndColor(fullname || "User");
  return (
    <AppBar position="static" color="inherit">
      <Container
        sx={{
          position: "relative",
          backgroundImage: `url(${Images.icBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "286px",
        }}
        maxWidth={false}
      >
        <Toolbar disableGutters>
          <IconButton >
            <img width={180} height={42} src={Images.icLogoSymania} />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user?.firstName}
                  src={userDetails?.image ?? ""}
                  sx={{
                    backgroundColor: userDetails?.image ? 'transparent' : color,
                  }}
                >
                  {(!userDetails?.image) && initials}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                },
                mt: "45px",
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* Personal Environment Link */}
              {!location.pathname.includes("personal") && (
                <MenuItem
                  component={Link}
                  href={`/${user?.id}/personal/settings/profile`}
                  onClick={handleCloseUserMenu}
                >
                  Personal Dashboard
                </MenuItem>
              )}

              {/* Sym Admin Environment Link */}
              {!location.pathname.includes("sym-admin") &&
                user?.roles.includes(USER_ROLES.SYM_ADMIN) && (
                  <MenuItem
                    component={Link}
                    href={`/${user?.id}/sym-admin/users`}
                    onClick={handleCloseUserMenu}
                  >
                    Sym Admin Dashboard
                  </MenuItem>
                )}

              {/* Organization Environment Links List */}
              {availableOrganizations.length > 0 && (
                <ListSubheader>Organizations</ListSubheader>
              )}
              {availableOrganizations.map((org) => (
                <MenuItem
                  key={org.id}
                  component={Link}
                  href={`/${user?.id}/org-admin/${org.id}/groups`}
                  onClick={handleCloseUserMenu}
                >
                  <Typography textAlign="center">{org.name}</Typography>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <Typography
          sx={{
            fontWeight: 700,
            color: "white",
            fontSize: 40,
            textAlign: "center",
          }}
        >
          Hello {decryptFn(String(userDetails?.firstName))} {currentOrgId ? `(${String(currentOrgName?.name)})` : ''}!
        </Typography>
        <Typography
          sx={{ color: "white", fontSize: 24, textAlign: "center" }}
        >
          Nice to see you again
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            bottom: 0,
            alignItems: "flex-end",
          }}
        >
          <UpdateUserImageForm
            fullname={fullname}
            imageURL={userDetails?.image}
            onSubmit={refetchUserDetails}
          />
          {location.pathname.includes("personal") && (
            <Box>
              <StyledTabs
                value={storedTabIndex}
                onChange={(event, newValue) => {
                  handleChange(event, newValue);
                  setStoredTabIndex(newValue);
                }}
                aria-label="styled tabs example"
              >
                <StyledTab
                  label="My Profile"
                  component={RouterLink}
                  to={`/${user?.id}/personal/settings/profile`}
                />
                <StyledTab
                  label="Authentication Settings"
                  component={RouterLink}
                  to={`/${user?.id}/personal/settings/symania`}
                />
                <StyledTab
                  label="Security Questions"
                  component={RouterLink}
                  to={`/${user?.id}/personal/settings/questions`}
                />
                {/* <StyledTab
                  label="Policies"
                  component={RouterLink}
                  to={`/${user?.id}/personal/settings/policies`}
                /> */}
              </StyledTabs>
            </Box>)}
          {location.pathname.includes("org-admin") && (
            <Box>
              <StyledTabs
                value={storedTabIndex}
                onChange={(event, newValue) => {
                  handleChange(event, newValue);
                  setStoredTabIndex(newValue);
                }}
                aria-label="styled tabs example"
              >
                <StyledTab
                  label="Groups"
                  component={RouterLink}
                  to={`/${user?.id}/org-admin/${currentOrgId}/groups`}
                />
                <StyledTab
                  label="Users"
                  component={RouterLink}
                  to={`/${user?.id}/org-admin/${currentOrgId}/users`}
                />
                <StyledTab
                  label="Service Providers"
                  component={RouterLink}
                  to={`/${user?.id}/org-admin/${currentOrgId}/service-providers`}
                />
                <StyledTab
                  label="Oauth Clients"
                  component={RouterLink}
                  to={`/${user?.id}/org-admin/${currentOrgId}/oauth-clients`}
                />
                <StyledTab
                  label="Subscription"
                  component={RouterLink}
                  to={`/${user?.id}/org-admin/${currentOrgId}/subscriptions`}
                />
              </StyledTabs>
            </Box>)}
          {location.pathname.includes("sym-admin") && (
            <Box>
              <StyledTabs
                value={storedTabIndex}
                onChange={(event, newValue) => {
                  handleChange(event, newValue);
                  setStoredTabIndex(newValue);
                }}
                aria-label="styled tabs example"
              >
                <StyledTab
                  label="Users"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/users`}
                />
                <StyledTab
                  label="Organization"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/organizations`}
                />
                <StyledTab
                  label="Logs"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/logs/api`}
                />
                {/* to add it as menu inside / basic ui
                {
                  name: "Api Logs",
                to: "/api",
      },
                {
                  name: "Audit Logs",
                to: "/audit",
      },
                {
                  name: "Email History",
                to: "/email-history",
      }, */}
                {/* <StyledTab
                  label="General Settings"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/settings`}
                /> */}
                <StyledTab
                  label="Colors Settings"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/colors-settings`}
                />
                <StyledTab
                  label="Symbols Settings"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/symbols-settings`}
                />
                <StyledTab
                  label="Subscriptions"
                  component={RouterLink}
                  to={`/${user?.id}/sym-admin/subscriptions`}
                />
              </StyledTabs>
            </Box>)}
        </Box>

      </Container>
    </AppBar>
  );
};

export default AppHeader;
