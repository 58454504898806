import { Button, Typography, Box, BoxProps } from "@mui/material";
import { useEffect, useState, FC } from "react";
import { useSearchParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { env } from "src/env";

const ContentWrapper: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box p={2} bgcolor="white" {...rest}>
      {children}
    </Box>
  );
};

function AuthMobilePage() {
  const [searchParams] = useSearchParams();
  const [sequenceNum, setSequenceNum] = useState(0);
  const [clientSocket, setClientSocket] = useState<Socket | null>(null);
  const [sessionState, setSessionState] = useState<SessionState>();

  useEffect(() => {
    const sessionId = searchParams.get("sessionId");
    const deviceId = searchParams.get("deviceId");
    if (!sessionId || !deviceId) {
      return;
    }
    const socket = io(env.API_URL_WS + "/auth", {
      query: {
        sessionId: sessionId,
        "user-agent":
          "Mozilla/5.0 (iPhone; CPU iPhone OS 14_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Mobile/15E148 Safari/604.1",
      },
      autoConnect: false,
      extraHeaders: {
        "device-id": deviceId,
        "Access-Control-Allow-Origin": "*",
      },
    });
    socket.on("connect", () => {
      setSessionState({ _tag: "new" });
    });
    socket.on("disconnect", (reason) => {
      // Do not fail the session if the client disconnect itself
      if (reason === "io client disconnect") {
        return;
      }
      setSessionState({ _tag: "failed" });
    });
    socket.on("sessionStarted", (locationMap) => {
      setSessionState({ _tag: "in-progress", locationMap });
    });
    socket.on("sessionTimeout", () => {
      setSessionState({ _tag: "timeout" });
    });
    socket.on("sessionCancelled", () => {
      setSessionState({ _tag: "cancelled" });
    });
    socket.on("sessionFailed", () => {
      setSessionState({ _tag: "failed" });
      socket.disconnect();
    });
    socket.on("sessionSuccess", () => {
      setSessionState({ _tag: "success" });
      socket.disconnect();
    });

    setClientSocket(socket);
  }, [searchParams]);

  useEffect(() => {
    if (!clientSocket) {
      return;
    }
    clientSocket.connect();
  }, [clientSocket]);

  if (!sessionState || !clientSocket) {
    return (
      <ContentWrapper>
        <Typography>Connecting...</Typography>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "new") {
    return (
      <ContentWrapper>
        <Typography>
          Open the browser on your desktop or laptop to start authenticating...
        </Typography>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "timeout") {
    return (
      <ContentWrapper>
        <Typography>Session timed out</Typography>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "cancelled") {
    return (
      <ContentWrapper>
        <Typography>Session cancelled</Typography>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "failed") {
    return (
      <ContentWrapper>
        <Typography>Session failed</Typography>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "success") {
    return (
      <ContentWrapper>
        <Typography>Session success</Typography>
      </ContentWrapper>
    );
  }

  const increaseSequence = () => {
    setSequenceNum((prev) => prev + 1);
  };

  const handleCellClick = (locationChar: string) => {
    clientSocket.emit("selectLocation", locationChar, (res: any) => {
      if (res.success) {
        increaseSequence();
      }
    });
  };

  const handleDoneClick = () => {
    clientSocket.emit("finishSession");
  };

  if (sessionState._tag === "in-progress") {
    return (
      <ContentWrapper>
        <Typography>Sequence: {sequenceNum}</Typography>
        <div>
          {sessionState.locationMap.map((row, rowIndex) => (
            <div key={rowIndex}>
              {row.map((cell) => (
                <span
                  key={cell}
                  style={{
                    backgroundColor: "#ddd",
                    width: "50px",
                    height: "50px",
                    aspectRatio: 1,
                    borderRadius: "50%",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCellClick(cell)}
                ></span>
              ))}
            </div>
          ))}
        </div>
        <Button variant="contained" color="primary" onClick={handleDoneClick}>
          Done
        </Button>
      </ContentWrapper>
    );
  }

  return (
    <Typography>
      A mobile simulator for testing authentication process
    </Typography>
  );
}

type SessionState =
  | NewSession
  | InProgressSession
  | TimeoutSession
  | CancelledSession
  | FailedSession
  | SuccessSession;

type FailedSession = {
  _tag: "failed";
};

type SuccessSession = {
  _tag: "success";
};

type NewSession = {
  _tag: "new";
};

type TimeoutSession = {
  _tag: "timeout";
};

type CancelledSession = {
  _tag: "cancelled";
};

type InProgressSession = {
  _tag: "in-progress";
  locationMap: string[][];
};

export default AuthMobilePage;
