import {
  Pagination as MuiPagination,
  TablePaginationProps,
} from "@mui/material";
import {
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
} from "@mui/x-data-grid";

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageCount = Math.ceil(rowCount / pageSize);

  return (
    <MuiPagination
      color="primary"
      sx={{
        '& .MuiPaginationItem-root': {
          '&.Mui-selected': {
            background: '#303030',
            color: 'white',
          },
        },
      }}
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

export function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default CustomPagination;
