import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const deleteUserAccount = async ({
  userID,
}: GetUserDeleteAccountInput) => {
  await apiRequest.post(ENDPOINTS.USERS + `/${userID}/deleteUserAccount`, {
    userID,
  });
};

type GetUserDeleteAccountInput = {
  userID: number | string;
};
