import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { updateUserDetailsFn } from "src/api";
import { useAuth } from "src/providers/AuthProvider";
import { useState } from "react";

type UserDetailsFormInputs = {
  firstName: string;
  lastName: string;
};

interface UpdateUserDetailsFormProps {
  firstName: string;
  lastName: string;
  onSubmit: () => void;
}

function UpdateUserDetailsForm({
  firstName,
  lastName,
  onSubmit,
}: UpdateUserDetailsFormProps) {
  const {
    register,
    formState: { isValid, isDirty, errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<UserDetailsFormInputs>({
    defaultValues: {
      firstName,
      lastName,
    },
  });

  const { user } = useAuth();
  const [open, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [dialogFieldName, setDialogFieldName] = useState("");

  const { mutate: updateUserDetails, isLoading: isSubmitting } = useMutation(
    updateUserDetailsFn,
    {
      onSuccess: () => {
        reset(
          {},
          {
            keepValues: true,
            keepIsValid: false,
            keepTouched: false,
            keepErrors: false,
            keepIsSubmitted: false,
          }
        );
        onSubmit()
        alert("User details updated successfully");
      },
    }
  );

  const submitUpdateUserDetailsForm = async () => {
    if (!isValid || !user) return;
    updateUserDetails({
      userId: user.id,
      first_name: getValues("firstName"),
      last_name: getValues("lastName"),
    });
  };

  const handleApply = () => {
    submitUpdateUserDetailsForm();
    setIsOpen(false);
  };

  return (
    <Box sx={{ maxWidth: '600px'}}>
      <form onSubmit={handleSubmit(submitUpdateUserDetailsForm)}>
        <Box sx={{ maxWidth: '600px' }}>
          <TextField
            {...register("firstName", { required: "First name is required" })}
            margin="normal"
            fullWidth
            variant="outlined"
            label="First Name"
            error={errors.firstName ? true : false}
            helperText={errors.firstName ? errors.firstName.message : null}
          />
          <TextField
            {...register("lastName", { required: "Last name is required" })}
            margin="normal"
            fullWidth
            label="Last Name"
            variant="outlined"
            error={errors.lastName ? true : false}
            helperText={errors.lastName ? errors.lastName.message : null}
          />
        </Box>
        <Button
          sx={{
            "&:hover": { backgroundColor: "#111111" },
            width: "140px",
            height: "40px",
            padding: "10px 20px",
            borderRadius: "12px",
            color: "white",
            fontSize: 16,
            marginTop: 2,
            textTransform: "none",
            backgroundColor: "#111111",
          }}
          variant="contained"
          type="submit"
          disabled={!isDirty || isSubmitting || !isValid}
        >
          Update
        </Button>
      </form>
    </Box>

  );
}

export default UpdateUserDetailsForm;
