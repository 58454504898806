import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "src/providers/AuthProvider";
import { useUserDetails } from "src/providers/UserDetailsContext";


const Dashboard = () => {
  const { user } = useAuth();
  const { userDetails } = useUserDetails();
  const { orgId: currentOrgId } = useParams();

  const isOrgAdmin = !window.location.pathname.includes('/org-admin/');;


  const organizations = useMemo(() => {
    return (
      userDetails?.orgs.filter(
        (org) => currentOrgId === undefined || org.id === parseInt(currentOrgId)
      ) ?? []
    );
  }, [currentOrgId, userDetails?.orgs]);

  return (
    <>
      {isOrgAdmin ? (
        <h1>Welcome {user?.firstName}</h1>
      ) : (
        <h1>{user?.firstName} welcome in "{organizations[0]?.name}"</h1>
      )}
    </>
  );
};

export default Dashboard;
