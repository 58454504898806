import CryptoJS from 'crypto-js'
import { env } from 'src/env';


export function decryptFn(text: string): string {
  try {
    const textParts = text.split(':');
    const iv = CryptoJS.enc.Hex.parse(textParts.shift()!);
    const encryptedText = CryptoJS.enc.Hex.parse(textParts.join(':'));
    const secretKey = CryptoJS.enc.Hex.parse(String(env.SECRET_KEY));

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedText } as any,
        secretKey,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch(error) {
    return text
  }
}
