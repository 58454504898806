import Page403 from "src/dashboard/views/pages/Page403";
import { useAuth } from "src/providers/AuthProvider";
import RequireAuth from "./RequireAuth";

interface RequireRoleProps {
  role: string;
  children: JSX.Element;
}

function RequireRole({ children, role }: RequireRoleProps) {
  const { user } = useAuth();

  return (
    <RequireAuth>
      {!user?.roles.includes(role) ? <Page403 /> : children}
    </RequireAuth>
  );
}

export default RequireRole;
