import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { Filter, FilterColumns } from "src/share/types";
import FilterableDataTable, {
  PaginationModel,
} from "src/dashboard/components/DataTable/FilterableDataTable";
import LongTextCellWithPopover from "src/dashboard/components/LongTextCellWithPopover";
import { useQuery } from "react-query";
import { getEmailHistoryFn } from "src/api";
import { Box, Typography } from "@mui/material";
import { CiSearch } from "react-icons/ci";

const filterColumns: FilterColumns = [
  {
    name: "id",
    type: "number",
    label: "ID",
  },
  {
    name: "template_id",
    type: "number",
    label: "Template ID",
  },
  {
    name: "user_id",
    type: "number",
    label: "User ID",
  },
  {
    name: "user_email",
    type: "string",
    label: "User Email",
  },
  {
    name: "sent_time",
    type: "timestamp",
    label: "Sent Time",
  },
  {
    name: "subject",
    type: "string",
    label: "Subject",
  },
  {
    name: "body",
    type: "string",
    label: "Body",
  },
  {
    name: "status",
    type: "string",
    label: "Status",
  },
];

const columns: GridColDef[] = [
  {
    field: "id", headerName: "Id", width: 80, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "template_id", headerName: "Template ID", headerClassName: "column-style",
    width:140,
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "user_id", headerName: "User ID", headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    width:110,
  },
  {
    field: "user_email",
    headerName: "User Email",
    width: 260,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => {
      return <LongTextCellWithPopover value={params.value} />;
    },
  },
  {
    field: "sent_time",
    headerName: "Sent Time",
    width: 170,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "subject",
    headerName: "Subject",
    width: 160,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => {
      return <LongTextCellWithPopover value={params.value} />;
    },
  },
  {
    field: "body",
    headerName: "Body",
    width: 180,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => {
      return <LongTextCellWithPopover value={params.value} />;
    },
  },
  {
    field: "status", headerName: "Status", width:120, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
];

function EmailHistory() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);

  const [rowCount, setRowCount] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const applyFilter = (data: GridRowsProp) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row) =>
      row.id.toString().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const {
    isFetching,
    isError,
    data: emailHistory,
  } = useQuery({
    queryKey: [
      "emailHistory",
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () =>
      getEmailHistoryFn({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      }),
    keepPreviousData: true,
    staleTime: 0, // always get the latest email history
  });

  useEffect(() => {
    if (!emailHistory) return;
    setRows(emailHistory.data);
    setRowCount(emailHistory.meta.total);
  }, [emailHistory]);

  const initFilters = useMemo(() => {
    const query = searchParams;
    const newFilters = [];
    for (const col of Array.from(query.keys())) {
      const value = query.get(col);
      if (value === null) continue;
      newFilters.push({ column: col, value });
    }
    return newFilters;
  }, []);

  const handleOnApplyFilters = (filters: Filter[]) => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});
    // set the search query of the current URL
    setSearchParams(query);
  };

  if (isError) {
    return (
      <SectionContainer>
        <Typography variant="h4">Trouble loading email history</Typography>
      </SectionContainer>
    );
  }

  return (
    <Box sx={{
      height: 630,
      marginBottom: 10,
      width: 'fit-content',
      '& .column-style': {
        backgroundColor: '#F3F2F7',
        fontWeight: "bold"
      },
      '& .row-style': {
        color: '#111111',
        fontSize: '14px'
      },

    }}>
      <Box className="header-group">
        <div className="product-search">
          <CiSearch size={20} />
          <input
            type="text"
            placeholder="Search log"
            className="products__search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
      </Box>
      <FilterableDataTable
        loading={isFetching}
        initFilters={initFilters}
        columns={columns}
        rows={filteredRows}
        rowCount={rowCount}
        filterColumns={filterColumns}
        paginationMode={"server"}
        paginationModel={paginationModel}
        onPaginationChange={setPaginationModel}
        onApplyFilters={handleOnApplyFilters}
      />
    </Box>
  );
}

export default EmailHistory;
