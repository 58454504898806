import { createTheme } from '@mui/material/styles'
import { appColor } from 'src/share/colors'

export const lightTheme = createTheme({
  typography: {
    fontFamily: [
      'Inter'
    ].join(',')
  },
  palette: {
    primary: {
      main: appColor.main,
      // light: appColor.white,
    },
    error: {
      main: appColor.rred
    }
  }
})
