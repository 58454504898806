import { useEffect } from "react";
import { useMutation } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { activateUserFn } from "src/api";
import LoadingIndicator from "src/components/LoadingIndicator";
import Page403 from "src/dashboard/views/pages/Page403";

function ActivateUserPage() {
  const { activateUserToken } = useParams();
  const {
    mutate: activateUser,
    data,
    isSuccess,
    isError,
  } = useMutation(activateUserFn);

  useEffect(() => {
    if (activateUserToken) {
      activateUser(activateUserToken);
    }
  }, [activateUserToken]);

  if (isSuccess && data) {
    return <Navigate to="/register" state={{ userId: data.id }} />;
  }

  if (isError) {
    return <Page403 />;
  }

  return <LoadingIndicator variant="fullScreen" />;
}

export default ActivateUserPage;
