import { ChangeEvent, FormEventHandler, useRef, useState, KeyboardEvent } from "react";
import { clsx } from "clsx";
import styles from "./CodeModal.module.css";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import OtpInput from "react18-input-otp";
import { Typography } from "@mui/material";

interface CodeModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (code: string) => void;
  screen: string;
}

const CODE_LENGTH = 6;
export function CodeModal({
  onSubmit,
  isOpen,
  onRequestClose,
  screen
}: CodeModalProps) {
  const [code, setCode] = useState("");

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (code.length === CODE_LENGTH) {
      onSubmit(code);
      setCode("");
      onRequestClose();
    }
  };

  const getModalTitle = () => {
    if (screen === "email") return "Email Verification";
    if (screen === "phone") return "Phone Verification";
    return "Two-Factor Authentication";
  };

  const getModalMessage = () => {
    if (screen === "email") return "A message with a verification code has been sent to your email address. Enter the code to verify.";
    if (screen === "phone") return "A message with a verification code has been sent to your phone. Enter the code to verify.";
    return "A message with a verification code has been sent. Enter the code to continue.";
  };

  return (
    <div
      className={clsx(styles.modalBackdrop, {
        [styles.hidden]: !isOpen,
      })}
    >
      <div
        className={clsx(styles.modal, {
          [styles.hidden]: !isOpen,
        })}
      >
        <div className={styles.closeIcon} onClick={() => {
          setCode("");
          onRequestClose();
        }}>
          <CloseIcon />
        </div>
        <Typography className="text-center text-black" style={{ fontWeight: "600", fontSize: '32px', textAlign: 'center' }}>
        {getModalTitle()}
        </Typography>
        <p>{getModalMessage()}</p>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center space-y-4 m-4">
            <OtpInput
              value={code}
              numInputs={CODE_LENGTH}
              onChange={setCode}
              inputStyle='text-base/loose py-2 font-inter w-12 mx-1 border'
            />
            <button
              className={clsx(styles.button, styles.buttonPrimary, styles.submitButton)}
              type="submit"
              disabled={code.length < CODE_LENGTH}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CodeModal;
