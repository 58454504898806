import React from "react";
import { useSelector, useDispatch } from "react-redux";

// sidebar nav config
import { adminNav, orgAdminNav, personalNav } from "../_nav";
import { Divider, Drawer, Typography, useTheme } from "@mui/material";
import { AppSidebarNav } from "./AppSidebarNav";
import { useAuth } from "src/providers/AuthProvider";
import { useLocation } from "react-router-dom";

const AppSidebar = ({ drawerWidth }: { drawerWidth: number }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sidebarShow = useSelector<any, any>((state) => state.sidebarShow);
  const location = useLocation();

  const pathName = location.pathname;
  const navItems = pathName.includes("sym-admin")
    ? adminNav
    : pathName.includes("org-admin")
    ? orgAdminNav
    : personalNav;

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={sidebarShow}
    >
      <div>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          sx={{ paddingY: 2 }}
        >
          Symania
        </Typography>
      </div>
      <Divider />
      <AppSidebarNav items={navItems} />
    </Drawer>
  );
};

export default React.memo(AppSidebar);
