import { Navigate, useParams } from "react-router-dom";
import Page404 from "src/dashboard/views/pages/page404/Page404";
import { useAuth } from "src/providers/AuthProvider";

interface RequireAuthProps {
  children: JSX.Element;
}

function RequireAuth({ children }: RequireAuthProps) {
  const { userId } = useParams();
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/" />;
  }

  if (user.id !== userId) {
    return <Page404 />;
  }

  return children;
}

export default RequireAuth;
