import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getResetUserSecurityQuestionsFn = async ({
  resetUserToken,
}: GetResetUserSecurityQuestionsInput) => {
  const { data } = await apiRequest.get<GetResetUserSecurityQuestionsResponse>(
    ENDPOINTS.SECURITY_QUESTIONS + `/${resetUserToken}`
  );
  return data;
};

export const answerResetUserSecurityQuestionsFn = async ({
  resetUserToken,
  ...answers
}: AnswerResetUserSecurityQuestionsInput) => {
  const { data } =
    await apiRequest.post<AnswerResetUserSecurityQuestionsResponse>(
      ENDPOINTS.SECURITY_QUESTIONS + `/${resetUserToken}`,
      answers
    );
  return data;
};

export const getSecurityQuestionsFn = async () => {
  const { data } = await apiRequest.get<GetSecurityQuestionsResponse>(
    ENDPOINTS.SECURITY_QUESTIONS
  );
  return data;
};

export const updateUserSecurityQuestionsFn = async ({
  userId,
  ...updateUserQuestionsDetails
}: UpdateUserSecurityQuestionsInput) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.USERS + `/${userId}/security-questions`,
    updateUserQuestionsDetails
  );
  return data;
};

type GetResetUserSecurityQuestionsInput = {
  resetUserToken: string;
};

type GetResetUserSecurityQuestionsResponse = any[];

type AnswerResetUserSecurityQuestionsInput = {
  resetUserToken: string;
  answer1: string;
  answer2: string;
  answer3: string;
};

type AnswerResetUserSecurityQuestionsResponse = {
  token: string;
};

type GetSecurityQuestionsResponse = Array<{
  id: number;
  question: string;
}>;

type UpdateUserSecurityQuestionsInput = {
  userId: number | string;
  question1Id: number;
  answer1: string;
  question2Id: number;
  answer2: string;
  question3Id: number;
  answer3: string;
};
