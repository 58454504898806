import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getServiceProvidersFn = async ({
  orgId,
}: {
  orgId: number | string;
}) => {
  return await apiRequest.get(ENDPOINTS.SAML + `/${orgId}/service-providers`);
};

export const createServiceProviderFn = async (sp: ServiceProvider) => {
  await apiRequest.post(ENDPOINTS.SAML + `/${sp.orgId}/service-providers`, sp);
};

export const updateServiceProviderFn = async (sp: ServiceProvider) => {
  if (!sp.initial_entity_id) {
    return
  }
  await apiRequest.put(
    ENDPOINTS.SAML + `/${sp.orgId}/service-providers`,
    sp
  );
};

export const deleteServiceProviderFn = async ({
  orgId,
  entityId,
}: {
  orgId: number;
  entityId: string;
}) => {
  await apiRequest.delete(
    ENDPOINTS.SAML + `/${orgId}/service-providers`, {
      data: { entityId },
    });
};

type ServiceProvider = {
  initial_entity_id?: string
  orgId: number;
  appName: string;
  acsUrl: string;
  logoutUrl: string;
  nameId: 'email' | 'userID';
  entity_id: string;
};
