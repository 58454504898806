import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { answerReportStolenDeviceSecurityQuestionsFn } from "src/api";
import AuthLayout from "src/layouts/AuthLayout";

function AnswerReportStolenDeviceQuestionsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    formState: { isValid },
    handleSubmit,
    getValues,
  } = useForm();
  const {
    mutate: answerSecurityQuestions,
    isLoading: isSubmitting,
    isSuccess: answerSuccess,
  } = useMutation(answerReportStolenDeviceSecurityQuestionsFn);

  if (!location.state) {
    navigate("/report-stolen-device");
    return null;
  }

  const { token, questions } = location.state;

  if (!token || !questions) {
    navigate("/report-stolen-device");
    return null;
  }

  const securityQuestions = [
    questions.question1,
    questions.question2,
    questions.question3,
  ];

  const submitSecurityQuestionsAnswers = async () => {
    if (!isValid) return;

    const { answer1, answer2, answer3 } = getValues();
    answerSecurityQuestions({ token, answer1, answer2, answer3 });
  };

  if (answerSuccess) {
    return (
      <AuthLayout>
        <Box
          sx={{
            borderRadius: 2,
            maxWidth: "1000px",
            margin: "0 auto",
            backgroundColor: "white",
            overflow: "hidden",
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h2">
            Stolen Device Reported
          </Typography>
          <Typography variant="body1" component="p">
            You reported your device is no longer in your possession. We
            disabled all your connected devices. If you want to re-connect
            simply login to mobile app again.
          </Typography>
        </Box>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Box
        sx={{
          borderRadius: 2,
          maxWidth: "1000px",
          margin: "0 auto",
          backgroundColor: "white",
          overflow: "hidden",
          padding: 2,
        }}
      >
        <form onSubmit={handleSubmit(submitSecurityQuestionsAnswers)}>
          {securityQuestions.map((q, index) => (
            <div key={q.id}>
              <Typography>{q.question}</Typography>
              <TextField
                {...register(`answer${index + 1}`, { required: true })}
                fullWidth
                margin="normal"
                required
                label={`Answer ${index + 1}`}
              />
            </div>
          ))}
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        </form>
      </Box>
    </AuthLayout>
  );
}

export default AnswerReportStolenDeviceQuestionsPage;
