import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import LoadingIndicator from "src/components/LoadingIndicator";
import UpdateUserDetailsForm from "src/components/UpdateUserDetailsForm";
import UpdateUserEmailForm from "src/components/UpdateUserEmailForm";
import UpdateUserPhoneForm from "src/components/UpdateUserPhoneForm";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { useUserDetails } from "src/providers/UserDetailsContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useAuth } from "src/providers/AuthProvider";
import { deleteUserAccount } from "src/api/services/deleteUserAccount.service";
import { decryptFn } from "src/share/utils/decrypt";

function Profile() {
  const { user } = useAuth();
  const userID = user?.id;
  const { userDetails, refetchUserDetails } = useUserDetails();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteClick = async (userID: string) => {
    await deleteUserAccount({ userID });
    handleClose();
    refetchUserDetails();
  };

  const style = {
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    textAlign: 'center',
  };

  if (!userDetails)
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );

  return (
    <>
      <Grid
          sx={{
          "@media (max-width: 1475px)": {
            paddingLeft: "150px",
          },
          "@media (max-width: 1380px)": {
            paddingLeft: "200px",
          },
          "@media (max-width: 1265px)": {
            paddingLeft: "300px",
          },
          }}
          container
          gap={2}
        >
        <Grid item xs={10} sx={{ maxWidth: 600}} >
        <Typography pl={1} pt={6} pb={2} sx={{ color: "#111111", fontWeight: "bold", marginTop: '40px' }}>
          Your personal details
        </Typography>
          <UpdateUserDetailsForm
            firstName={decryptFn(userDetails.firstName)}
            lastName={decryptFn(userDetails.lastName)}
            onSubmit={refetchUserDetails}
          />
          <UpdateUserPhoneForm
            countryCode={userDetails.countryCode.replace("+", "")}
            phone={decryptFn(userDetails.phoneNumber.toString())}
            onSubmit={refetchUserDetails}
          />
          <UpdateUserEmailForm email={decryptFn(userDetails.email)} onSubmit={refetchUserDetails} />
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleOpen}
              startIcon={<DeleteIcon />}
              size="small"
              sx={{
                borderRadius: "12px",
                fontSize: "16px"
              }}
              disabled={userDetails.selfDeletedAt !== null}
            >
              delete account
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              Are you sure you want to delete your account?
            </Typography>
            <Typography variant="h6">
              Please note that by continuing with the process, your data will be
              saved for 30 days. Afterwards, all your details will be permanently
              deleted from Symania's system.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  if (userID) {
                    handleDeleteClick(userID);
                  }
                }}
                sx={{
                  borderRadius: "12px",
                  fontSize: "16px"
                }}
              >
                delete
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                borderRadius: "12px",
                fontSize: "16px"
                }}
              >
                cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Profile;
