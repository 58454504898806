export const env = {
  API_URL:
    process.env.REACT_APP_API_URL || 'https://api.symania.iqdesk.xyz/api/v1',
  API_URL_WS:
    process.env.REACT_APP_API_URL_WS || 'https://api.symania.iqdesk.xyz',
  SECRET_KEY: process.env.REACT_APP_SECRET_KEY || 'secretKey'
};

// https://4ff7-195-72-146-220.ngrok-free.app
// https://api.symania.iqdesk.xyz
// https://api-symania-dev.iqdesk.xyz
