import { Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  answerResetUserSecurityQuestionsFn,
  getResetUserSecurityQuestionsFn,
} from "src/api";
import { Images } from "src/assets";
import LoadingIndicator from "src/components/LoadingIndicator";
import { useAuth } from "src/providers/AuthProvider";

function AnswerSecurityQuestionsPage() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { resetUserToken } = useParams();
  const {
    register,
    formState: { isValid },
    handleSubmit,
    getValues,
  } = useForm();
  const { isFetching: isQuestionsLoading, data: securityQuestions } = useQuery({
    queryKey: ["userSecurityQuestions", resetUserToken],
    queryFn: () => {
      if (!resetUserToken) return;
      return getResetUserSecurityQuestionsFn({ resetUserToken });
    },
  });
  const { mutate: answerSecurityQuestions, isLoading: isSubmitting } =
    useMutation(answerResetUserSecurityQuestionsFn, {
      onSuccess: (data) => {
        // Continue activating group user
        const token = sessionStorage.getItem("activate-group-user-token");
        if (token) {
          navigate(`/activate-group-user/${token}`);
        } else {
          login(data.token, (user) => {
            navigate(`/${user.id}/personal/settings/profile`);
          });
        }
      },
    });

  const submitSecurityQuestionsAnswers = async () => {
    if (!resetUserToken) return;
    if (!isValid) return;

    const { answer1, answer2, answer3 } = getValues();
    answerSecurityQuestions({ resetUserToken, answer1, answer2, answer3 });
  };

  if (isQuestionsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={{ width: '100%', flexDirection: "column", display: 'flex', alignItems: 'center' }}>
      <img style={{ padding: 10 }} width={186} height={60} src={Images.icLightLogo} />
      <Divider style={{ width: "100%" }} />
      <form style={{ marginTop: 80, width: 528 }} onSubmit={handleSubmit(submitSecurityQuestionsAnswers)}>
        <Typography textAlign="center" fontWeight="600" fontSize="32px">Reset user</Typography>
        <Typography textAlign="center" fontSize="18px">
          We need you to answer the security questions you <br />
          set up during registration
        </Typography>
        {(securityQuestions ?? []).map((q, index) => (
          <div style={{ marginTop: 32 }} key={q.id}>
            <Typography>{q.question}</Typography>
            <TextField
              autoComplete="off"
              sx={{
                '& label.Mui-focused': {
                  color: '#93929B'
                },
                '& .MuiOutlinedInput-root': {
                  borderColor: '#C4C3CF',
                  '&.Mui-focused fieldset': {
                    border: '1px solid  #C4C3CF'
                  }
                }
              }}
              {...register(`answer${index + 1}`, { required: true })}
              fullWidth
              margin="normal"
              required
              label={`Answer ${index + 1}`}
            />
          </div>
        ))}
        <Button
          sx={{
            "&:hover": { backgroundColor: "#303030" },
            mt: 3,
            textTransform: "none",
            backgroundColor: "#303030",
            height: 52,
            borderRadius: "12px",
          }}
          fullWidth
          variant="contained" type="submit" disabled={isSubmitting || !isValid}>
          {isSubmitting ? <CircularProgress /> : "Submit"}
        </Button>
      </form>
    </Box>
  );
}

export default AnswerSecurityQuestionsPage;
