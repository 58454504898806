import countriesData from 'countries-list';

export const USER_ROLES = {
  SYM_ADMIN: 'sym_admin',
};

const getFlag = (countryCode: string): string => {
  return countryCode
    .toUpperCase()
    .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt(0)));
};

export const countriesPhoneOptions = Object.values(countriesData.countries)
.filter(country => country.name !== 'Kosovo' && country.name !== 'Dominican Republic')
.sort((a, b) => {
  if (a.name > b.name) return 1;
  else if (a.name < b.name) return -1;
  return 0;
})
.map((country) => ({
  value: country.phone,
  label: `${country.emoji} ${country.name} (+${country.phone})`,
}));
countriesPhoneOptions.push({
  value: "+383",
  label: "🇽🇰 Kosovo (+383)"
});
countriesPhoneOptions.push({
  value: "+1809",
  label: "🇩🇴 Dominican Republic (+1809)"
});
countriesPhoneOptions.sort((a, b) => {
  const nameA = a.label.substring(a.label.indexOf(' ') + 1, a.label.lastIndexOf(' '));
  const nameB = b.label.substring(b.label.indexOf(' ') + 1, b.label.lastIndexOf(' '));

  if (nameA > nameB) return 1;
  else if (nameA < nameB) return -1;
  return 0;
});

export const __DEV__ = false
export const testingFirstName = 'John'
export const testingLastName = 'Smith'
export const testingEmail = 'jackwullems@outlook.com'
export const testingEmailCode = '123456'
export const testingPhone = '618383564'

export const countries = [
  { code: 'IL', label: 'Israel', phone: '972', flag: getFlag('IL') },
  { code: 'AD', label: 'Andorra', phone: '376', flag: getFlag('AD') },
  { code: 'AE', label: 'United Arab Emirates', phone: '971', flag: getFlag('AE') },
  { code: 'AF', label: 'Afghanistan', phone: '93', flag: getFlag('AF') },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268', flag: getFlag('AG') },
  { code: 'AI', label: 'Anguilla', phone: '1-264', flag: getFlag('AI') },
  { code: 'AL', label: 'Albania', phone: '355', flag: getFlag('AL') },
  { code: 'AM', label: 'Armenia', phone: '374', flag: getFlag('AM') },
  { code: 'AO', label: 'Angola', phone: '244', flag: getFlag('AO') },
  { code: 'AQ', label: 'Antarctica', phone: '672', flag: getFlag('AQ') },
  { code: 'AR', label: 'Argentina', phone: '54', flag: getFlag('AR') },
  { code: 'AS', label: 'American Samoa', phone: '1-684', flag: getFlag('AS') },
  { code: 'AT', label: 'Austria', phone: '43', flag: getFlag('AT') },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true, flag: getFlag('AU') },
  { code: 'AW', label: 'Aruba', phone: '297', flag: getFlag('AW') },
  { code: 'AX', label: 'Alland Islands', phone: '358', flag: getFlag('AX') },
  { code: 'AZ', label: 'Azerbaijan', phone: '994', flag: getFlag('AZ') },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387', flag: getFlag('BA') },
  { code: 'BB', label: 'Barbados', phone: '1-246', flag: getFlag('BB') },
  { code: 'BD', label: 'Bangladesh', phone: '880', flag: getFlag('BD') },
  { code: 'BE', label: 'Belgium', phone: '32', flag: getFlag('BE') },
  { code: 'BF', label: 'Burkina Faso', phone: '226', flag: getFlag('BF') },
  { code: 'BG', label: 'Bulgaria', phone: '359', flag: getFlag('BG') },
  { code: 'BH', label: 'Bahrain', phone: '973', flag: getFlag('BH') },
  { code: 'BI', label: 'Burundi', phone: '257', flag: getFlag('BI') },
  { code: 'BJ', label: 'Benin', phone: '229', flag: getFlag('BJ') },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590', flag: getFlag('BL') },
  { code: 'BM', label: 'Bermuda', phone: '1-441', flag: getFlag('BM') },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673', flag: getFlag('BN') },
  { code: 'BO', label: 'Bolivia', phone: '591', flag: getFlag('BO') },
  { code: 'BR', label: 'Brazil', phone: '55', flag: getFlag('BR') },
  { code: 'BS', label: 'Bahamas', phone: '1-242', flag: getFlag('BS') },
  { code: 'BT', label: 'Bhutan', phone: '975', flag: getFlag('BT') },
  { code: 'BV', label: 'Bouvet Island', phone: '47', flag: getFlag('BV') },
  { code: 'BW', label: 'Botswana', phone: '267', flag: getFlag('BW') },
  { code: 'BY', label: 'Belarus', phone: '375', flag: getFlag('BY') },
  { code: 'BZ', label: 'Belize', phone: '501', flag: getFlag('BZ') },
  { code: 'CA', label: 'Canada', phone: '1', suggested: true, flag: getFlag('CA') },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61', flag: getFlag('CC') },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243', flag: getFlag('CD') },
  { code: 'CF', label: 'Central African Republic', phone: '236', flag: getFlag('CF') },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242', flag: getFlag('CG') },
  { code: 'CH', label: 'Switzerland', phone: '41', flag: getFlag('CH') },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225', flag: getFlag('CI') },
  { code: 'CK', label: 'Cook Islands', phone: '682', flag: getFlag('CK') },
  { code: 'CL', label: 'Chile', phone: '56', flag: getFlag('CL') },
  { code: 'CM', label: 'Cameroon', phone: '237', flag: getFlag('CM') },
  { code: 'CN', label: 'China', phone: '86', flag: getFlag('CN') },
  { code: 'CO', label: 'Colombia', phone: '57', flag: getFlag('CO') },
  { code: 'CR', label: 'Costa Rica', phone: '506', flag: getFlag('CR') },
  { code: 'CU', label: 'Cuba', phone: '53', flag: getFlag('CU') },
  { code: 'CV', label: 'Cape Verde', phone: '238', flag: getFlag('CV') },
  { code: 'CW', label: 'Curacao', phone: '599', flag: getFlag('CW') },
  { code: 'CX', label: 'Christmas Island', phone: '61', flag: getFlag('CX') },
  { code: 'CY', label: 'Cyprus', phone: '357', flag: getFlag('CY') },
  { code: 'CZ', label: 'Czech Republic', phone: '420', flag: getFlag('CZ') },
  { code: 'DE', label: 'Germany', phone: '49', suggested: true, flag: getFlag('DE') },
  { code: 'DJ', label: 'Djibouti', phone: '253', flag: getFlag('DJ') },
  { code: 'DK', label: 'Denmark', phone: '45', flag: getFlag('DK') },
  { code: 'DM', label: 'Dominica', phone: '1-767', flag: getFlag('DM') },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809', flag: getFlag('DO') },
  { code: 'DZ', label: 'Algeria', phone: '213', flag: getFlag('DZ') },
  { code: 'EC', label: 'Ecuador', phone: '593', flag: getFlag('EC') },
  { code: 'EE', label: 'Estonia', phone: '372', flag: getFlag('EE') },
  { code: 'EG', label: 'Egypt', phone: '20', flag: getFlag('EG') },
  { code: 'EH', label: 'Western Sahara', phone: '212', flag: getFlag('EH') },
  { code: 'ER', label: 'Eritrea', phone: '291', flag: getFlag('ER') },
  { code: 'ES', label: 'Spain', phone: '34', flag: getFlag('ES') },
  { code: 'ET', label: 'Ethiopia', phone: '251', flag: getFlag('ET') },
  { code: 'FI', label: 'Finland', phone: '358', flag: getFlag('FI') },
  { code: 'FJ', label: 'Fiji', phone: '679', flag: getFlag('FJ') },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500', flag: getFlag('FK') },
  { code: 'FM', label: 'Micronesia', phone: '691', flag: getFlag('FM') },
  { code: 'FO', label: 'Faroe Islands', phone: '298', flag: getFlag('FO') },
  { code: 'FR', label: 'France', phone: '33', suggested: true, flag: getFlag('FR') },
  { code: 'GA', label: 'Gabon', phone: '241', flag: getFlag('GA') },
  { code: 'GB', label: 'United Kingdom', phone: '44', suggested: true, flag: getFlag('GB') },
  { code: 'GD', label: 'Grenada', phone: '1-473', flag: getFlag('GD') },
  { code: 'GE', label: 'Georgia', phone: '995', flag: getFlag('GE') },
  { code: 'GF', label: 'French Guiana', phone: '594', flag: getFlag('GF') },
  { code: 'GG', label: 'Guernsey', phone: '44', flag: getFlag('GG') },
  { code: 'GH', label: 'Ghana', phone: '233', flag: getFlag('GH') },
  { code: 'GI', label: 'Gibraltar', phone: '350', flag: getFlag('GI') },
  { code: 'GL', label: 'Greenland', phone: '299', flag: getFlag('GL') },
  { code: 'GM', label: 'Gambia', phone: '220', flag: getFlag('GM') },
  { code: 'GN', label: 'Guinea', phone: '224', flag: getFlag('GN') },
  { code: 'GP', label: 'Guadeloupe', phone: '590', flag: getFlag('GP') },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240', flag: getFlag('GQ') },
  { code: 'GR', label: 'Greece', phone: '30', flag: getFlag('GR') },
  { code: 'GT', label: 'Guatemala', phone: '502', flag: getFlag('GT') },
  { code: 'GU', label: 'Guam', phone: '1-671', flag: getFlag('GU') },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245', flag: getFlag('GW') },
  { code: 'GY', label: 'Guyana', phone: '592', flag: getFlag('GY') },
  { code: 'HK', label: 'Hong Kong', phone: '852', flag: getFlag('HK') },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672', flag: getFlag('HM') },
  { code: 'HN', label: 'Honduras', phone: '504', flag: getFlag('HN') },
  { code: 'HR', label: 'Croatia', phone: '385', flag: getFlag('HR') },
  { code: 'HT', label: 'Haiti', phone: '509', flag: getFlag('HT') },
  { code: 'HU', label: 'Hungary', phone: '36', flag: getFlag('HU') },
  { code: 'ID', label: 'Indonesia', phone: '62', flag: getFlag('ID') },
  { code: 'IE', label: 'Ireland', phone: '353', flag: getFlag('IE') },
  { code: 'IM', label: 'Isle of Man', phone: '44', flag: getFlag('IM') },
  { code: 'IN', label: 'India', phone: '91', flag: getFlag('IN') },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246', flag: getFlag('IO') },
  { code: 'IQ', label: 'Iraq', phone: '964', flag: getFlag('IQ') },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98', flag: getFlag('IR') },
  { code: 'IS', label: 'Iceland', phone: '354', flag: getFlag('IS') },
  { code: 'IT', label: 'Italy', phone: '39', flag: getFlag('IT') },
  { code: 'JE', label: 'Jersey', phone: '44', flag: getFlag('JE') },
  { code: 'JM', label: 'Jamaica', phone: '1-876', flag: getFlag('JM') },
  { code: 'JO', label: 'Jordan', phone: '962', flag: getFlag('JO') },
  { code: 'JP', label: 'Japan', phone: '81', flag: getFlag('JP') },
  { code: 'KE', label: 'Kenya', phone: '254', flag: getFlag('KE') },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996', flag: getFlag('KG') },
  { code: 'KH', label: 'Cambodia', phone: '855', flag: getFlag('KH') },
  { code: 'KI', label: 'Kiribati', phone: '686', flag: getFlag('KI') },
  { code: 'KM', label: 'Comoros', phone: '269', flag: getFlag('KM') },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869', flag: getFlag('KN') },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850', flag: getFlag('KP') },
  { code: 'KR', label: 'Korea, Republic of', phone: '82', flag: getFlag('KR') },
  { code: 'KW', label: 'Kuwait', phone: '965', flag: getFlag('KW') },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345', flag: getFlag('KY') },
  { code: 'KZ', label: 'Kazakhstan', phone: '7', flag: getFlag('KZ') },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856', flag: getFlag('LA') },
  { code: 'LB', label: 'Lebanon', phone: '961', flag: getFlag('LB') },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758', flag: getFlag('LC') },
  { code: 'LI', label: 'Liechtenstein', phone: '423', flag: getFlag('LI') },
  { code: 'LK', label: 'Sri Lanka', phone: '94', flag: getFlag('LK') },
  { code: 'LR', label: 'Liberia', phone: '231', flag: getFlag('LR') },
  { code: 'LS', label: 'Lesotho', phone: '266', flag: getFlag('LS') },
  { code: 'LT', label: 'Lithuania', phone: '370', flag: getFlag('LT') },
  { code: 'LU', label: 'Luxembourg', phone: '352', flag: getFlag('LU') },
  { code: 'LV', label: 'Latvia', phone: '371', flag: getFlag('LV') },
  { code: 'LY', label: 'Libya', phone: '218', flag: getFlag('LY') },
  { code: 'MA', label: 'Morocco', phone: '212', flag: getFlag('MA') },
  { code: 'MC', label: 'Monaco', phone: '377', flag: getFlag('MC') },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373', flag: getFlag('MD') },
  { code: 'ME', label: 'Montenegro', phone: '382', flag: getFlag('ME') },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590', flag: getFlag('MF') },
  { code: 'MG', label: 'Madagascar', phone: '261', flag: getFlag('MG') },
  { code: 'MH', label: 'Marshall Islands', phone: '692', flag: getFlag('MH') },
  { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389', flag: getFlag('MK') },
  { code: 'ML', label: 'Mali', phone: '223', flag: getFlag('ML') },
  { code: 'MM', label: 'Myanmar', phone: '95', flag: getFlag('MM') },
  { code: 'MN', label: 'Mongolia', phone: '976', flag: getFlag('MN') },
  { code: 'MO', label: 'Macao', phone: '853', flag: getFlag('MO') },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670', flag: getFlag('MP') },
  { code: 'MQ', label: 'Martinique', phone: '596', flag: getFlag('MQ') },
  { code: 'MR', label: 'Mauritania', phone: '222', flag: getFlag('MR') },
  { code: 'MS', label: 'Montserrat', phone: '1-664', flag: getFlag('MS') },
  { code: 'MT', label: 'Malta', phone: '356', flag: getFlag('MT') },
  { code: 'MU', label: 'Mauritius', phone: '230', flag: getFlag('MU') },
  { code: 'MV', label: 'Maldives', phone: '960', flag: getFlag('MV') },
  { code: 'MW', label: 'Malawi', phone: '265', flag: getFlag('MW') },
  { code: 'MX', label: 'Mexico', phone: '52', flag: getFlag('MX') },
  { code: 'MY', label: 'Malaysia', phone: '60', flag: getFlag('MY') },
  { code: 'MZ', label: 'Mozambique', phone: '258', flag: getFlag('MZ') },
  { code: 'NA', label: 'Namibia', phone: '264', flag: getFlag('NA') },
  { code: 'NC', label: 'New Caledonia', phone: '687', flag: getFlag('NC') },
  { code: 'NE', label: 'Niger', phone: '227', flag: getFlag('NE') },
  { code: 'NF', label: 'Norfolk Island', phone: '672', flag: getFlag('NF') },
  { code: 'NG', label: 'Nigeria', phone: '234', flag: getFlag('NG') },
  { code: 'NI', label: 'Nicaragua', phone: '505', flag: getFlag('NI') },
  { code: 'NL', label: 'Netherlands', phone: '31', flag: getFlag('NL') },
  { code: 'NO', label: 'Norway', phone: '47', flag: getFlag('NO') },
  { code: 'NP', label: 'Nepal', phone: '977', flag: getFlag('NP') },
  { code: 'NR', label: 'Nauru', phone: '674', flag: getFlag('NR') },
  { code: 'NU', label: 'Niue', phone: '683', flag: getFlag('NU') },
  { code: 'NZ', label: 'New Zealand', phone: '64', flag: getFlag('NZ') },
  { code: 'OM', label: 'Oman', phone: '968', flag: getFlag('OM') },
  { code: 'PA', label: 'Panama', phone: '507', flag: getFlag('PA') },
  { code: 'PE', label: 'Peru', phone: '51', flag: getFlag('PE') },
  { code: 'PF', label: 'French Polynesia', phone: '689', flag: getFlag('PF') },
  { code: 'PG', label: 'Papua New Guinea', phone: '675', flag: getFlag('PG') },
  { code: 'PH', label: 'Philippines', phone: '63', flag: getFlag('PH') },
  { code: 'PK', label: 'Pakistan', phone: '92', flag: getFlag('PK') },
  { code: 'PL', label: 'Poland', phone: '48', flag: getFlag('PL') },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508', flag: getFlag('PM') },
  { code: 'PN', label: 'Pitcairn', phone: '872', flag: getFlag('PN') },
  { code: 'PR', label: 'Puerto Rico', phone: '1', flag: getFlag('PR') },
  { code: 'PT', label: 'Portugal', phone: '351', flag: getFlag('PT') },
  { code: 'PW', label: 'Palau', phone: '680', flag: getFlag('PW') },
  { code: 'PY', label: 'Paraguay', phone: '595', flag: getFlag('PY') },
  { code: 'QA', label: 'Qatar', phone: '974', flag: getFlag('QA') },
  { code: 'RE', label: 'Reunion', phone: '262', flag: getFlag('RE') },
  { code: 'RO', label: 'Romania', phone: '40', flag: getFlag('RO') },
  { code: 'RS', label: 'Serbia', phone: '381', flag: getFlag('RS') },
  { code: 'RU', label: 'Russian Federation', phone: '7', flag: getFlag('RU') },
  { code: 'RW', label: 'Rwanda', phone: '250', flag: getFlag('RW') },
  { code: 'SA', label: 'Saudi Arabia', phone: '966', flag: getFlag('SA') },
  { code: 'SB', label: 'Solomon Islands', phone: '677', flag: getFlag('SB') },
  { code: 'SC', label: 'Seychelles', phone: '248', flag: getFlag('SC') },
  { code: 'SD', label: 'Sudan', phone: '249', flag: getFlag('SD') },
  { code: 'SE', label: 'Sweden', phone: '46', flag: getFlag('SE') },
  { code: 'SG', label: 'Singapore', phone: '65', flag: getFlag('SG') },
  { code: 'SH', label: 'Saint Helena', phone: '290', flag: getFlag('SH') },
  { code: 'SI', label: 'Slovenia', phone: '386', flag: getFlag('SI') },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47', flag: getFlag('SJ') },
  { code: 'SK', label: 'Slovakia', phone: '421', flag: getFlag('SK') },
  { code: 'SL', label: 'Sierra Leone', phone: '232', flag: getFlag('SL') },
  { code: 'SM', label: 'San Marino', phone: '378', flag: getFlag('SM') },
  { code: 'SN', label: 'Senegal', phone: '221', flag: getFlag('SN') },
  { code: 'SO', label: 'Somalia', phone: '252', flag: getFlag('SO') },
  { code: 'SR', label: 'Suriname', phone: '597', flag: getFlag('SR') },
  { code: 'SS', label: 'South Sudan', phone: '211', flag: getFlag('SS') },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239', flag: getFlag('ST') },
  { code: 'SV', label: 'El Salvador', phone: '503', flag: getFlag('SV') },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721', flag: getFlag('SX') },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963', flag: getFlag('SY') },
  { code: 'SZ', label: 'Swaziland', phone: '268', flag: getFlag('SZ') },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649', flag: getFlag('TC') },
  { code: 'TD', label: 'Chad', phone: '235', flag: getFlag('TD') },
  { code: 'TF', label: 'French Southern Territories', phone: '262', flag: getFlag('TF') },
  { code: 'TG', label: 'Togo', phone: '228', flag: getFlag('TG') },
  { code: 'TH', label: 'Thailand', phone: '66', flag: getFlag('TH') },
  { code: 'TJ', label: 'Tajikistan', phone: '992', flag: getFlag('TJ') },
  { code: 'TK', label: 'Tokelau', phone: '690', flag: getFlag('TK') },
  { code: 'TL', label: 'Timor-Leste', phone: '670', flag: getFlag('TL') },
  { code: 'TM', label: 'Turkmenistan', phone: '993', flag: getFlag('TM') },
  { code: 'TN', label: 'Tunisia', phone: '216', flag: getFlag('TN') },
  { code: 'TO', label: 'Tonga', phone: '676', flag: getFlag('TO') },
  { code: 'TR', label: 'Turkey', phone: '90', flag: getFlag('TR') },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868', flag: getFlag('TT') },
  { code: 'TV', label: 'Tuvalu', phone: '688', flag: getFlag('TV') },
  { code: 'TZ', label: 'Tanzania, United Republic of', phone: '255', flag: getFlag('TZ') },
  { code: 'UA', label: 'Ukraine', phone: '380', flag: getFlag('UA') },
  { code: 'UG', label: 'Uganda', phone: '256', flag: getFlag('UG') },
  { code: 'US', label: 'United States', phone: '1', suggested: true, flag: getFlag('US') },
  { code: 'UY', label: 'Uruguay', phone: '598', flag: getFlag('UY') },
  { code: 'UZ', label: 'Uzbekistan', phone: '998', flag: getFlag('UZ') },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784', flag: getFlag('VC') },
  { code: 'VE', label: 'Venezuela', phone: '58', flag: getFlag('VE') },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284', flag: getFlag('VG') },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340', flag: getFlag('VI') },
  { code: 'VN', label: 'Vietnam', phone: '84', flag: getFlag('VN') },
  { code: 'VU', label: 'Vanuatu', phone: '678', flag: getFlag('VU') },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681', flag: getFlag('WF') },
  { code: 'WS', label: 'Samoa', phone: '685', flag: getFlag('WS') },
  { code: 'XK', label: 'Kosovo', phone: '383', flag: getFlag('XK') },
  { code: 'YE', label: 'Yemen', phone: '967', flag: getFlag('YE') },
  { code: 'YT', label: 'Mayotte', phone: '262', flag: getFlag('YT') },
  { code: 'ZA', label: 'South Africa', phone: '27', flag: getFlag('ZA') },
  { code: 'ZM', label: 'Zambia', phone: '260', flag: getFlag('ZM') },
  { code: 'ZW', label: 'Zimbabwe', phone: '263', flag: getFlag('ZW') },
]
export const regExEmail = /^[\w-+\\.]+@([\w-]+\.)+[\w-]{2,4}$/
export const regExPhone = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/
