import styles from "./Button.module.css";
import { clsx } from "clsx";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "text" | "contained";
  color?: "primary" | "default";
}

function Button({ children, variant, color, ...rest }: ButtonProps) {
  const className = clsx(
    variant === "contained" && styles.button,
    color === "primary" && styles.buttonPrimary,
    variant === "text" && styles.buttonText
  );
  return (
    <button {...rest} className={className}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  variant: "contained",
  color: "primary",
};

export default Button;
