import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "src/providers/AuthProvider";
import { deleteUserHimselfFn, getUserOrganizationsFn } from "src/api";
import SectionContainer from "src/dashboard/components/SectionContainer";
import LoadingIndicator from "src/components/LoadingIndicator";
import { useState } from "react";
import { queryClient } from "src/query-client";

const style = {
  position: "absolute" as const,
  top: "47%",
  left: "40%",
  transform: "translate(-50%, -50)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  textAlign: "center",
};

function Organizations() {
  const [open, setOpen] = useState(false);
  const [currentOrg, setCurrentOrg] = useState<string | null>(null);
  const { user } = useAuth();
  const userId = user?.id;
  const { isFetching, data: userOrganizations } = useQuery({
    queryKey: ["userOrganizations", user],
    queryFn: () => {
      if (!user) return;
      return getUserOrganizationsFn({ userId: user.id });
    },
  });
  const { mutate: deleteOrganizationUser } = useMutation(deleteUserHimselfFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("userOrganizations");
    },
  });

  const handleClose = () => setOpen(false);

  const handleDeleteClick = async (org: string | null, userId: number) => {
    if (userId && org) {
      deleteOrganizationUser({
        userId,
        org,
      });
    }
    handleClose();
  };

  const handleOpen = (curOrg: string) => {
    setCurrentOrg(curOrg);
    setOpen(true);
  };

  const preparedData = Array.from(new Set(userOrganizations));

  if (isFetching)
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );

  return (
    <Box p={2} borderRadius={2} bgcolor="white">
      <Typography variant="h5" align="center" fontWeight="bold" mt={4} mb={5}>
        List of organizations to which you belong
      </Typography>
      {preparedData.length !== 0 ? (
        <>
          <TableContainer>
            <Table
              align="center"
              sx={{
                maxWidth: 650,
                border: "1px solid rgba(224, 224, 224, 1)",
                borderRadius: "8px",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Organization Name
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                    align="right"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preparedData?.map((org) => (
                  <TableRow key={org}>
                    <TableCell sx={{ fontSize: "16px" }}>{org}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="delete" size="medium">
                        <DeleteIcon
                          fontSize="inherit"
                          onClick={() => {
                            handleOpen(org);
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Are you sure you want to leave {`"${currentOrg}"`} organization?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "30px",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    if (userId) {
                      handleDeleteClick(currentOrg, +userId);
                    }
                  }}
                >
                  Yes
                </Button>
                <Button variant="contained" onClick={handleClose}>
                  No
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Typography variant="h5" align="center" fontWeight="bold" mt={4} mb={5}>
          You do not belong to any organization yet
        </Typography>
      )}
    </Box>
  );
}

export default Organizations;
