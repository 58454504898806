import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  MdAdd as AddIcon,
  MdEdit as EditIcon,
  MdDelete as DeleteIcon,
  MdFileUpload as FileUploadIcon,
} from "react-icons/md";
import { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  createSymbolCategoryFn,
  createSymbolFn,
  deleteSymbolCategoryFn,
  deleteSymbolFn,
  getSymbolCategoriesFn,
  getSymbolsFn,
  updateSymbolCategoryFn,
} from "src/api";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { queryClient } from "src/query-client";
import { useForm } from "react-hook-form";
import { validateRequiredField } from "src/share/utils";
import LoadingIndicator from "src/components/LoadingIndicator";
import { Images } from "src/assets";
import './SymbolSettings.module.css'
import { decryptFn } from "src/share/utils/decrypt";

function SymbolsSettings() {
  const {
    data: categories,
    isFetching: isCategoriesFetching,
    isRefetching: isCategoriesRefetching,
  } = useQuery({
    queryKey: "symbolCategories",
    queryFn: () => getSymbolCategoriesFn({ includeEmpty: true }),
  });
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState<UpdateCategoryInput | null>(null);
  const [selectedSymbolId, setSelectedSymbolId] = useState<number | null>(null);
  const [isNew, setIsNew] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSymbolClick = (symbolId: number) => () => {
    setSelectedSymbolId(prev => prev === symbolId ? null : symbolId);
  };

  useEffect(() => {
    if (isNew && categories !== undefined) {
      const length = categories?.length - 1;
      setSelectedCategoryId(categories[length].catalogID)
      setValue('catalog_name', categories[length].catalog_name)
    }

  }, [categories])

  const { data: symbols, isFetching: isSymbolsFetching } = useQuery({
    queryKey: ["symbols", selectedCategoryId],
    queryFn: () =>
      selectedCategoryId
        ? getSymbolsFn({ categoryId: selectedCategoryId })
        : [],
  });
  const { mutate: createCategory, isLoading: isCreatingCategory } = useMutation(
    createSymbolCategoryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("symbolCategories");
        resetCreateCategoryForm();
      },
    }
  );
  const { mutate: updateCategory } = useMutation(updateSymbolCategoryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("symbolCategories");
      handleCloseUpdateCategoryModal();
    },
  });
  const { mutate: deleteCategory } = useMutation(deleteSymbolCategoryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("symbolCategories");
    },
  });
  const { mutate: createSymbol, isLoading: isCreatingSymbol } = useMutation(
    createSymbolFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("symbolCategories");
        queryClient.invalidateQueries("symbols");
      },
    }
  );
  const { mutate: deleteSymbol, isLoading: isDeletingSymbol } = useMutation(
    deleteSymbolFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("symbolCategories");
        queryClient.invalidateQueries("symbols");
      },
    }
  );
  const [updateCategoryModalState, setUpdateCategoryModalState] = useState<{
    open: boolean;
    category: any;
  }>({
    open: false,
    category: null,
  });
  const {
    register,
    formState: { errors, isDirty, isValid },
    getValues,
    setValue,
    reset: resetCreateCategoryForm,
  } = useForm<CreateCategoryInput>();

  const handleOpenUpdateCategoryModal =
    (category: UpdateCategoryInput) => () => {
      setUpdateCategoryModalState({ open: true, category });
    };

  const handleCloseUpdateCategoryModal = () => {
    setUpdateCategoryModalState({ open: false, category: null });
  };

  const handleUploadSymbol = (file: File) => {
    if (!selectedCategoryId) return;
    createSymbol({ categoryId: selectedCategoryId, symbol: file });
  };

  const handleSelectCategory = (category: UpdateCategoryInput) => () => {
    setSelectedCategoryId(category.catalogID);
    setValue('catalog_name', category.catalog_name)
    setSelectedCategory(category);
  };

  const handleDeleteCategory = (categoryId: number) => () => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      deleteCategory(categoryId);
      setSelectedCategory(null)
      setSelectedCategoryId(null)
      setValue('catalog_name', "")
    }
  };

  const handleDeleteSymbol = (categoryId: number, symbolId: number) => () => {
    if (window.confirm("Are you sure you want to delete this symbol?")) {
      deleteSymbol({ categoryId, symbolId });
    }
  };


  const handleCreateCategory = async () => {
    if (!isDirty || !isValid) return;
    const { catalog_name } = getValues();
    await createCategory({ catalog_name });

    resetCreateCategoryForm({
      catalog_name: ""
    })
    setIsNew(true)
  };

  const handleUpdateCategory = (selectedCategoryId: number) => {
    if (!isDirty || !isValid) return;
    const { catalog_name } = getValues();
    updateCategory({ catalog_name, catalogID: selectedCategoryId });
  };

  if (isCategoriesFetching && !isCategoriesRefetching) {
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer sx={{
      "@media (max-width: 1625px)": {
          marginTop: "60px",
        },
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography fontSize={18} fontWeight={"bold"} marginBottom={1}>
            Catalogs
          </Typography>
          <MenuList>
            {categories?.map((category) => (
              <MenuItem
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: category.catalogID === selectedCategoryId ? '#F3F2F7' : 'white'
                  },
                  marginTop: '12px',
                  border: '1px solid #D2DAE8',
                  borderRadius: '8px',
                }}

                key={category.catalogID}
                onClick={handleSelectCategory(category)}
                selected={category.catalogID === selectedCategoryId}
              >
                <ListItemText
                  sx={{ alignItems: 'center', display: "flex", height: "40px", justifyContent: 'space-between' }}
                  primary={category.catalog_name}
                  secondary={`(${category.symbol_count})`}
                  secondaryTypographyProps={{ color: '#111111' }}
                />
              </MenuItem>
            ))}
          </MenuList>
          <Typography onClick={() => {
            setSelectedCategoryId(null);
            setSelectedCategory(null);
            setValue('catalog_name', '');
            inputRef.current?.focus();
          }} sx={{ cursor: "pointer" }} mt={1}>+ Add New Catalog</Typography>

        </Grid>
        <Grid item xs={12} md={1}>
          <Divider sx={{ marginLeft: '50px', borderColor: '#D2DAE8', width: '1px' }} orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ justifyContent: "space-between", alignItems: "center", display: 'flex' }}>
            <Box>
              <InputLabel sx={{ fontSize: 12 }}>Catalog Name</InputLabel>
              <TextField
                {...register("catalog_name", { validate: validateRequiredField })}
                error={!!errors.catalog_name}
                helperText={errors.catalog_name?.message}
                sx={{ width: 272, marginTop: 0 }}
                variant="outlined"
                margin="normal"
                inputRef={inputRef}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {selectedCategory?.catalogID !== undefined && (
                <Button
                  sx={{ color: '#303030' }}
                  onClick={handleDeleteCategory(selectedCategory.catalogID)}
                >
                  Delete
                </Button>
              )}
              <Button
                onClick={() => selectedCategoryId ? handleUpdateCategory(selectedCategoryId) : handleCreateCategory()}
                sx={{
                  width: '85px !important',
                  borderRadius: '12px',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                disabled={!isDirty}
              >
                {'Save'}
              </Button>
            </Box>
          </Box>

          <Typography mt={2} mb={2}>{`Symbols (${symbols?.length ?? 0})`}</Typography>
          <Box className="scroll" sx={{
            scrollbarWidth: 'thin',
            paddingRight:'20px',
            overflowY: 'auto', height: '500px'
          }} >
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <SymbolUploader
                  handleUpload={handleUploadSymbol}
                  isUploading={isCreatingSymbol}
                />
              </Grid>
              {symbols?.map((symbol) => (
                <Grid item xs={12} md={3} key={symbol.symbolID}>
                  <Box
                    onClick={handleSymbolClick(symbol.symbolID)}
                    sx={{
                      position: 'relative',
                      justifyContent: "center",
                      cursor: 'pointer',
                      display: "flex",
                      background: selectedSymbolId === symbol.symbolID ? '#F3F2F7' : 'white',
                      alignItems: 'center',
                      width: '158px',
                      height: '148px',
                      borderRadius: '8px',
                      border: '1px solid #D2DAE8'
                    }}>
                    {selectedSymbolId === symbol.symbolID && (
                      <CardActions sx={{ top: 0, right: 0, position: 'absolute', justifyContent: "center" }}>
                        <IconButton
                          disabled={isDeletingSymbol}
                          onClick={handleDeleteSymbol(
                            selectedCategoryId as number,
                            symbol.symbolID
                          )}
                        >
                          <DeleteIcon size={20} />
                        </IconButton>
                      </CardActions>)}
                    <img
                      src={decryptFn(symbol.symbol)}
                      alt=""
                      style={{
                        maxWidth: "100%",
                        height: "44px",
                        width: "44px",
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <UpdateCategoryModal
        open={updateCategoryModalState.open}
        categoryToUpdate={updateCategoryModalState.category}
        handleClose={handleCloseUpdateCategoryModal}
        handleUpdate={updateCategory}
      />
    </SectionContainer>
  );
}

type UpdateCategoryInput = {
  catalog_name: string;
  catalogID: number;
};

type UpdateCategoryModalProps = {
  open: boolean;
  handleClose: () => void;
  handleUpdate: (data: UpdateCategoryInput) => void;
  categoryToUpdate: any;
};

const UpdateCategoryModal: FC<UpdateCategoryModalProps> = ({
  open,
  handleClose,
  handleUpdate,
  categoryToUpdate,
}) => {
  const {
    register,
    reset,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
  } = useForm<UpdateCategoryInput>();

  useEffect(() => {
    reset(categoryToUpdate);
  }, [open]);

  const handleUpdateCategory = () => {
    if (!isDirty || !isValid) return;
    const { catalog_name } = getValues();
    handleUpdate({ catalog_name, catalogID: categoryToUpdate.catalogID });
  };

  if (!categoryToUpdate) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 500,
          backgroundColor: "white",
          p: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 1,
        }}
      >
        <Typography variant="h5">Update Category</Typography>
        <form onSubmit={handleSubmit(handleUpdateCategory)}>
          <TextField
            {...register("catalog_name", { validate: validateRequiredField })}
            error={!!errors.catalog_name}
            helperText={errors.catalog_name?.message}
            fullWidth
            margin="normal"
            label="Category Name"
          />
          <Button type="submit" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ marginLeft: 1 }}
          >
            Cancel
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

type SymbolUploaderProps = {
  handleUpload: (file: File) => void;
  isUploading: boolean;
};

const VisuallyHiddenInput = styled("input")({
  opacity: 0,
  cursor: "pointer",
  height: '100%',
  border: '1px solid black',
  position: "absolute",
  bottom: 0,
  left: 0,
  width: '100%',
});

const SymbolUploader: FC<SymbolUploaderProps> = ({
  handleUpload,
  isUploading,
}) => {
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (file) {
      handleUpload(file);
    }
  }, [file]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setFile(e.target.files[0]);
    }
  };

  if (isUploading)
    return (
      <Box>
        <Button variant="contained" disabled>
          Uploading...
        </Button>
      </Box>
    );

  return (
    <Box
      sx={{
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        background: '#F3F2F74D',
        width: '158px',
        height: '148px',
        borderRadius: '8px',
        border: '1px dashed #D2DAE8'
      }}
    >
      <img width={24} height={24} src={Images.icPlus} />
      <Typography mt={1} color="#303030">Upload Symbol</Typography>
      <VisuallyHiddenInput
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
    </Box>
  );
};

type CreateCategoryInput = {
  catalog_name: string;
};

export default SymbolsSettings;
