import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "src/layouts/AuthLayout";
import { KeyboardBackspace } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, FormControl, Grid, InputLabel, ListItemText, MenuItem, MenuList, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ContainedButton } from "src/components/Button";
import { __DEV__, countries, testingEmail, testingEmailCode, testingFirstName, testingLastName, testingPhone } from "src/share/constants";
import { useMutation } from "react-query";
import { ISymbol, SymbolCategory, addEmailFn, addPhoneFn, getCategorySymbolsFn, getSecurityQuestionsFn, getSymbolCategoriesFn, registerUserFn, updateUserSecurityQuestionsFn, updateUserSymbolFn, validateEmailFn, validatePhoneFn } from "src/api";
import OtpInput from 'react18-input-otp'
import { Autocomplete } from '@mui/material'
import React from "react";
import SecurityQuestionsForm from "src/components/SecurityQuestionsForm";
import { decryptFn } from "src/share/utils/decrypt";

interface IStep {
  step: number
  setStep(step: number): void
}

const StepContext = createContext<IStep>({ step: 1, setStep: () => { return } })

interface RegisterStepProps {
  handleReset: () => void;
  handleResetValues: (val: number) => void;
}

function RegisterStep({ handleReset, handleResetValues }: RegisterStepProps) {
  const navigate = useNavigate()
  const { step, setStep } = useContext(StepContext)
  return (
    <div className="flex flex-col">
      <div className="flex justify-between" style={{ marginTop: '48px' }}>
        <div className="pl-12">
          <Button
            onClick={() => {
              handleReset()
              if (step === 1) {
                navigate(-1)
                return
              }

              if (step === 2) {
                setStep(1)
                handleResetValues(step)
                return
              }

              if (step === 4) {
                setStep(2)
                handleResetValues(step)
                return
              }

              if (step === 6) {
                setStep(4)
                handleResetValues(step)
                return
              }

              if (step === 7) {
                setStep(6)
                handleResetValues(step)
                return
              }

              if (step === 8) {
                setStep(7)
                handleResetValues(step)
                return
              }

              if (step >= 7) {
                setStep(step - 1)
                return
              }
              if (step >= 5) {
                setStep(4)
                handleResetValues(step)
                return
              }
              if (step >= 3) {
                setStep(2)
                handleResetValues(step)
                return
              }
              setStep(1)
              return
            }}
            className="text-greyText text-lg" startIcon={<KeyboardBackspace />}>
            Back
          </Button>
        </div>

        <div className="flex justify-center">
          <Typography className="text-center text-black" style={{ fontWeight: "600", fontSize: '32px', marginBottom: '10px', textAlign: 'center' }}>
            {
              step === 1
                ?
                'Welcome!'
                :
                step === 2
                  ?
                  'Please enter your email address'
                  :
                  step === 3
                    ?
                    'Email Verification'
                    :
                    step === 4
                      ?
                      'Please enter your phone number'
                      :
                      step === 5
                        ?
                        'Phone Verification'
                        :
                        step === 6
                          ?
                          'Choose your symbol'
                          :
                          step === 7
                            ?
                            'Set your Sequence'
                            :
                            step === 8
                              ?
                              'Set your Secret Questions'
                              :
                              ''
            }
          </Typography>
        </div>
        <Typography className="text-greyText text-lg pl-20 ml-8">
          {/* {`Step ${step >= 5 ? step - 2 : step >= 3 ? step - 1 : step} of 6`} */}
        </Typography>
      </div>
      <Typography paragraph
        className="text-black text-lg self-center text-center" style={{ fontSize: 18, marginBottom: '10px' }}>
        {
          step === 1
            ?
            "Let's get started by entering your name."
            :
            step === 2
              ?
              'We need your email to verify your identity.'
              :
              step === 3
                ?
                'A message with a verification code has been sent to your email address.'
                :
                step === 4
                  ?
                  'We need your phone number to finalize your identity verification.'
                  :
                  step === 5
                    ?
                    'A message with a verification code has been sent to your phone. Enter the code to continue.'
                    :
                    step === 6
                      ?
                      <span>
                        Start by choosing a catalog of symbols according to your desired theme.<br />
                        Then, choose your symbol. this will be your secret symbol to guide you through all your logins.
                      </span>
                      :
                      step === 7
                        ?
                        <span>
                          The sequence number you choose, determines the number of times you will need to<br /> click on the application keyboard on your phone to finalize your authentication.
                        </span>
                        :
                        step === 8
                          ?
                          ''
                          :
                          ''
        }
      </Typography>
    </div>
  )
}

function RegisterPage() {
  const [step, setStep] = useState(__DEV__ ? 6 : 1);
  const [userId, setUserId] = useState<number | null>(null);
  const location = useLocation();
  const [first_name, setFirst_name] = useState(__DEV__ ? testingFirstName : '')
  const [last_name, setLast_name] = useState(__DEV__ ? testingLastName : '')
  const [email, setEmail] = useState(__DEV__ ? testingEmail : '')
  const [unique, setUnique] = useState('')
  const [emailCode, setEmailCode] = useState(__DEV__ ? testingEmailCode : '')
  const [phoneCode, setPhoneCode] = useState('')
  // const refContainedButton = useRef<HTMLButtonElement>(null)
  const [country_code, setCountry_code] = useState(__DEV__ ? '31' : countries[0].phone)
  const [phoneNumber, setPhone_number] = useState(__DEV__ ? testingPhone : '')
  const [symbolCategories, setSymbolCategories] = useState<SymbolCategory[]>()
  // const [isLoadingCategories, setIsLoadingCategories] = useState(false)
  const [selectedSymbol, setSelectedSymbol] = useState<ISymbol>()
  // const { isFetching: isLoadingCategories, data: symbolCategories } = useQuery({
  //   queryKey: ["symbolCategories"],
  //   queryFn: () => getSymbolCategoriesFn(),
  // })
  const [selectedSymbolCategoryIndex, setSelectedSymbolCategoryIndex] = useState<number>(0)
  const [sequence, setSequence] = React.useState<number>();
  const [matrixSize, setMatrixSize] = React.useState<string>();
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      alert("Session timed out. Please start again.");
      window.location.reload();
    }, 300000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (step === 9) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }


  useEffect(() => {
    setHasChanged(true)
  }, [matrixSize, sequence])

  const navigate = useNavigate()
  function handleNavigateLogin() {
    navigate('/')
  }
  const handleChangeSequence = (event: SelectChangeEvent) => {
    setSequence(parseInt(event.target.value));
  };

  const handleChangeMatrixSize = (event: SelectChangeEvent) => {
    setMatrixSize(event.target.value);
  };

  function handleSubmitEmailCode() {
    validateEmail({
      unique,
      code: emailCode
    })
  }
  function handleSubmitPhoneCode() {
    validatePhone({
      unique,
      code: phoneCode
    })
  }
  const { mutate: emailMutate, isLoading: emailLoading } = useMutation(addEmailFn, {
    onSuccess(data, variables, context) {
      setUnique(data)
      setStep(3)
    },
  })
  const { mutate: validatePhone, isLoading: isValidatingPhone } = useMutation(
    validatePhoneFn,
    {
      onSuccess: () => {
        setPhoneCode('')
        registerUser({ first_name, last_name, email, phone_number: phoneNumber, country_code: `+${country_code}` })
      },
      onError: (error: any) => {
        setPhoneCode('')
        alert(error.response.data.message);
      }
    }
  );

  const { mutate: validateEmail, isLoading: isValidatingEmail, isError: isEmailError, error: emailError } = useMutation(
    validateEmailFn,
    {
      onSuccess: () => {
        setEmailCode('');
        setStep(4);
      },
      onError: (error: any) => {
        setEmailCode('');
        alert(error.response.data.message);
      },
    }
  );
  const { mutate: registerUser, isLoading: isRegistering } = useMutation(
    registerUserFn,
    {
      onSuccess: (userId) => {
        setUserId(userId)
        setStep(6)
        // setIsLoadingCategories(true)
        getSymbolCategoriesFn({ includeEmpty: true }).then((res) => {
          // Filter categories where symbol_count is defined and greater than or equal to 16
          const filteredCategories = res.filter(
            (category) => (category.symbol_count ?? 0) >= 16
          );

          setSymbolCategories(filteredCategories);
        });
      },
    }
  );
  const { mutate: symbolsMutate } = useMutation(getCategorySymbolsFn, {
    onSuccess(data, variables, context) {
      if (symbolCategories) {
        const { index } = variables
        symbolCategories[index].symbols = data
        setSymbolCategories([...symbolCategories])
      }
      return
    },
  })
  const { mutate: phoneMutate, isLoading: phoneLoading } = useMutation(
    addPhoneFn,
    {
      onSuccess: (uniqueStr) => {
        setUnique(uniqueStr)
        setStep(5)
      },
    }
  );
  const { mutate: updateUserSymbol, isLoading: updateSymbolLoading } = useMutation(
    updateUserSymbolFn,
    {
      onSuccess: () => {
        setStep(8)
        setHasChanged(false)
      },
    }
  );
  // const { login } = useAuth();
  // const {
  //   register,
  //   handleSubmit,
  //   getValues,
  //   formState: { isValid, isDirty },
  // } = useForm<SecurityQuestionsFormInputs>();
  // const [remainingQuestions, setRemainingQuestions] = useState<SecurityQuestionOption[]>([]);
  // const { data: securityQuestions, isFetching: isQuestionsLoading } = useQuery({
  //   queryKey: ["securityQuestions"],
  //   queryFn: () => getSecurityQuestionsFn(),
  //   onSuccess: (data) => {
  //     setRemainingQuestions(data);
  //   },
  // });
  // const { mutate: updateUserSecurityQuestions, isLoading: isSubmitting } =
  //   useMutation(updateUserSecurityQuestionsFn, {
  //     onSuccess: (data) => {
  //       login(data.token, ()=>{
  //         return
  //       })
  //     },
  // });
  useEffect(() => {
    if (symbolCategories
      && !symbolCategories[selectedSymbolCategoryIndex].symbols
    ) {
      symbolsMutate({ categories: symbolCategories, index: selectedSymbolCategoryIndex })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSymbolCategoryIndex, symbolCategories])
  // useEffect(() => {
  //   // Starts from step 2 if the user is coming from the activation email
  //   if (location.state?.activateToken || location.state?.userId) {
  //     setStep(2);
  //     setUserId(location.state?.userId);
  //   }
  // }, [location.state?.activateToken, location.state?.userId]);
  useEffect(() => {
    if (__DEV__) {
      registerUser({ first_name, last_name, email, phone_number: phoneNumber, country_code: `+${country_code}` })
    }
  }, [])
  // const handleNextStep = () => {
  //   setStep(step + 1);
  // };

  // const handleRegistered = (userId: number) => {
  //   setUserId(userId);
  //   setStep(step + 1);
  // };
  function handleSubmit() {
    if (first_name.trim() === '' || last_name.trim() === '') {
      alert('First name or last name can`t be empty.');
      return;
    }

    setStep(2)
  }
  function handleReset() {
    setEmailCode("")
    setPhoneCode("")
  }

  function handleResetValues(step: number) {
    if (step === 2) {
      setFirst_name("")
      setLast_name("")
    } else if (step === 3 || step === 4) {
      setEmail("")
    } else if (step === 5 || step === 6) {
      setCountry_code(countries[0].phone)
      setPhone_number("")
    } else if (step === 7) {
      setSelectedSymbolCategoryIndex(0)
      setSelectedSymbol(undefined)
    } else if (step === 8) {
      setMatrixSize(undefined)
      setSequence(undefined)
    }
  }
  const selectedCategory = symbolCategories && symbolCategories[selectedSymbolCategoryIndex];
  return (
    <AuthLayout>
      <div className='p-4 h-screen rounded-b-lg bg-white'>
        <StepContext.Provider value={{ step, setStep }}>
          {step < 9 && <RegisterStep handleReset={handleReset} handleResetValues={handleResetValues} />}
          {
            step === 1
              ?
              <div className="flex flex-col items-center space-y-9 m-4">
                <TextField className="w-96" label='First Name' value={first_name} onChange={event => setFirst_name(event.target.value)} />
                <TextField className="w-96" label='Last Name' value={last_name} onChange={event => setLast_name(event.target.value)} />
                <ContainedButton disabled={!first_name || !last_name} onClick={handleSubmit} className="w-40 h-11" title="Next" style={{ backgroundColor: '#FB3B8C', color: "white", borderRadius: '8px' }} />
              </div>
              :
              step === 2
                ?
                <div className="flex flex-col items-center space-y-4 m-4">
                  <TextField
                    // onSubmit={()=>{
                    //   if (email) {
                    //     emailMutate({email})
                    //   }
                    // }}
                    className="w-80" label='Email' value={email} onChange={event => setEmail(event.target.value)} />
                  <ContainedButton loading={emailLoading} disabled={!email}
                    onClick={() => {
                      emailMutate({ email })
                    }}
                    style={{ backgroundColor: '#FB3B8C', color: "white", }}
                    className="w-40" title="Next" />
                </div>
                :
                step === 3
                  ?
                  <div className="flex flex-col items-center space-y-4 m-4">
                    {/* <OTPInput
              numInputs={5}
              value={emailCode}
              onChange={function (otp: string): void {
                setEmailCode(otp)
                if (otp.length >= 5) {
                  refContainedButton.current?.focus()
                }
              }}

              renderInput={(props) => <input {...props} className="text-base/loose py-2 font-inter w-12 mx-1 border"/>}
            /> */}
                    <OtpInput
                      value={emailCode}
                      numInputs={6}
                      onSubmit={handleSubmitEmailCode}
                      inputStyle='text-base/loose py-2 font-inter w-12 mx-1 border'
                      onChange={(otp: string) => {
                        setEmailCode(otp)
                      }}
                    />
                    {/* <MuiOtpInput
              length={5}
              onComplete={()=>{
                refContainedButton.current?.focus()
              }}
              TextFieldsProps={{className: 'w-12'}}
              value={emailCode}
              onChange={otp=>setEmailCode(otp)}
            /> */}
                    <div className="flex flex-col">
                      <ContainedButton
                        style={{ backgroundColor: '#FB3B8C', color: "white", }}
                        // ref={refContainedButton}
                        onClick={handleSubmitEmailCode}
                        loading={isValidatingEmail}
                        disabled={emailCode.length < 6}
                        title="Next" className="w-40" />
                      <Button
                        onClick={() => {
                          emailMutate({ email })
                        }}
                        variant='text'>
                        {
                          emailLoading
                            ?
                            <CircularProgress className='my-1' size={'18px'} color='inherit' />
                            :
                            <Typography className="text-greyText">Resend Code</Typography>
                        }
                      </Button>
                    </div>
                  </div>
                  :
                  step === 4
                    ?
                    <div className="flex flex-col items-center space-y-4 m-4">
                      <div className="flex flex-row">
                        <Autocomplete
                          disableClearable
                          options={countries}
                          defaultValue={countries[0]}
                          getOptionLabel={(option) => `${option.flag} ${option.label} (+${option.phone})`}
                          renderOption={(props, option) => (
                            <Typography
                              {...props}
                              className="text-xs cursor-pointer">
                              {option.flag} {option.label} (+{option.phone})
                            </Typography>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              setCountry_code(value.phone)
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // margin='none'
                              className="w-45 text-xs"
                              size='small'
                              label="Country Code"
                              variant="outlined"
                              InputLabelProps={{
                                className: 'text-xs',
                              }}
                              // InputProps={params.InputProps}
                              inputProps={{
                                ...params.inputProps,
                                className: 'text-xs h-2 px-0',
                                // autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        <TextField
                          // margin='none'
                          className="w-40 text-xs ml-4"
                          size='small'
                          value={phoneNumber}
                          onChange={event => {
                            // if (phone_number.length===1 && event.target.value.length === 2) {
                            //   setPhone_number(event.target.value+'-')
                            //   return
                            // }
                            setPhone_number(event.target.value)
                          }}
                          InputLabelProps={{
                            className: 'text-xs',
                          }}
                          inputProps={{
                            className: 'text-xs h-5',
                          }}
                          label='Phone Number' />
                      </div>
                      <Button
                        onClick={() => {
                          // setStep(5)
                          const phoneNumberWithoutLeadingZero = phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber;
                          phoneMutate({ phoneNumber: phoneNumberWithoutLeadingZero, countryCode: `+${country_code}` })
                        }}
                        style={{ backgroundColor: '#FB3B8C', color: "white", }}
                        variant="contained"
                        className="w-40"
                        disabled={!phoneNumber || !country_code}
                      >
                        {
                          phoneLoading
                            ?
                            <Typography className="text-whiteText text-base/loose">
                              <CircularProgress className='mt-0.5' size={'16px'} color='inherit' />
                            </Typography>
                            :
                            <Typography className="text-whiteText font-inter text-base/loose">
                              Next
                            </Typography>
                        }
                      </Button>
                    </div>
                    :
                    step === 5
                      ?
                      <div className="flex flex-col items-center space-y-4 m-4">
                        <OtpInput
                          value={phoneCode}
                          numInputs={6}
                          onSubmit={handleSubmitPhoneCode}
                          inputStyle='text-base/loose py-2 font-inter w-12 mx-1 border'
                          onChange={(otp: string) => {
                            setPhoneCode(otp)
                          }}
                        />
                        <div className="flex flex-col">
                          <ContainedButton
                            style={{ backgroundColor: '#FB3B8C', color: "white", }}
                            // ref={refContainedButton}
                            loading={isValidatingPhone || isRegistering}
                            onClick={handleSubmitPhoneCode}
                            disabled={phoneCode.length < 6}
                            title="Next" className="w-40" />
                          <Button
                            onClick={() => {
                              phoneMutate({ phoneNumber, countryCode: `+${country_code}` })
                            }}
                            variant='text'>
                            {
                              phoneLoading
                                ?
                                <CircularProgress className='my-1' size={'18px'} color='inherit' />
                                :
                                <Typography className="text-greyText">Resend Code</Typography>
                            }
                          </Button>
                        </div>
                      </div>
                      :
                      step === 6
                        ?
                        <div className="flex flex-col items-center space-y-4 m-4">

                          <Grid sx={{ justifyContent: "center" }} container spacing={2}>
                            <Grid item xs={12} md={2}>
                              <Typography fontSize={18} fontWeight={"bold"} marginBottom={1}>
                                Catalogs
                              </Typography>
                              <MenuList>
                                {symbolCategories && symbolCategories.map((category, index) => (
                                  <MenuItem
                                    sx={{
                                      '&.Mui-selected': {
                                        backgroundColor: selectedSymbolCategoryIndex === index ? '#F3F2F7' : 'white'
                                      },
                                      marginTop: '12px',
                                      border: '1px solid #D2DAE8',
                                      borderRadius: '8px',
                                    }}
                                    key={category.catalogID}
                                    onClick={() => setSelectedSymbolCategoryIndex(index)}
                                    selected={selectedSymbolCategoryIndex === index}
                                  >
                                    <ListItemText
                                      sx={{ alignItems: 'center', display: "flex", height: "40px", justifyContent: 'space-between' }}
                                      primary={category.catalog_name}
                                      secondary={`(${category.symbol_count})`}
                                      secondaryTypographyProps={{ color: '#111111' }}
                                    />
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Divider sx={{ marginLeft: '50px', borderColor: '#D2DAE8', width: '1px' }} orientation="vertical" />
                            </Grid>
                            <Grid item xs={12} md={4.5}>
                              <Box sx={{ fontSize: 20 }}>
                                {selectedCategory &&
                                  selectedCategory?.catalog_name
                                }
                              </Box>
                              <Typography mt={3}>{`Symbols (${selectedCategory?.symbols?.length ?? 0})`}</Typography>
                              <Box className="scroll" sx={{
                                scrollbarWidth: 'thin',
                                paddingRight: '20px',
                                overflowY: 'auto', height: '520px'
                              }}>
                                <Grid mt={2} container spacing={2}>
                                  {
                                    selectedCategory?.symbols
                                      ? selectedCategory.symbols.map((s) => {
                                        console.log(decryptFn(s.symbol))
                                        return(
                                        <Grid item xs={12} md={3} key={s.symbolID}>
                                          <Button
                                            onClick={() => {
                                              if (s.symbolID === selectedSymbol?.symbolID) {
                                                setSelectedSymbol(undefined)
                                                return
                                              }
                                              setSelectedSymbol(s)
                                            }}
                                            sx={{
                                              alignItems: "center",
                                              display: "flex",
                                              justifyContent: "center",
                                              borderRadius: '8px',
                                              width: '124px',
                                              height: '124px',
                                              border: s.symbolID === selectedSymbol?.symbolID
                                                ? "2px solid #FB3B8C"
                                                : "1px solid #DCDBE8",
                                            }}
                                          >
                                            <div>
                                              <img
                                                style={{
                                                  maxWidth: "100%",
                                                  aspectRatio: 1,
                                                  width: "44px",
                                                }}
                                                src={decryptFn(s.symbol)}
                                                alt={s.symbolID.toString()}
                                              />
                                            </div>
                                          </Button>
                                        </Grid>
                                      )})
                                      : (
                                        <div className="flex w-full h-full items-center justify-center">
                                          <CircularProgress color='inherit' />
                                        </div>
                                      )
                                  }
                                </Grid>
                              </Box>
                            </Grid>

                          </Grid>
                          <ContainedButton
                            // ref={refContainedButton}
                            onClick={() => {
                              setStep(7)
                            }}
                            style={{ backgroundColor: '#FB3B8C', color: "white", }}
                            disabled={!selectedSymbol}
                            title="Next" className="w-40" />
                        </div>
                        // <div className="flex flex-col items-center space-y-4 m-4">
                        //   {
                        //     symbolCategories
                        //     &&
                        //     <div className='space-x-2'>
                        //       {
                        //         symbolCategories.map((category, index) =>
                        //           <Chip key={category.catalogID}
                        //             sx={{
                        //               "&:hover": {backgroundColor: selectedSymbolCategoryIndex === index ?  '#303030' : '#EEEEEE'},
                        //               fontSize:16,
                        //               bgcolor: selectedSymbolCategoryIndex === index ? '#303030' : '#EEEEEE',
                        //               color: selectedSymbolCategoryIndex === index ? '#FFFFFFE5' : '#111111E5' }}
                        //             onClick={() => setSelectedSymbolCategoryIndex(index)}
                        //             label={category.catalog_name} color='primary' variant={'filled'} />)
                        //       }
                        //     </div>
                        //   }
                        //   <Grid justifyContent="center" alignItems={'flex-start'} container columnSpacing={2} className='w-80 h-80 overflow-auto'>
                        //     {
                        //       symbolCategories && symbolCategories[selectedSymbolCategoryIndex].symbols
                        //         ?
                        //         symbolCategories[selectedSymbolCategoryIndex].symbols?.map(symbol => {
                        //           return (
                        //             <Grid item xs={4}>
                        //               <div
                        //                 onClick={() => {
                        //                   if (symbol.symbolID === selectedSymbol?.symbolID) {
                        //                     setSelectedSymbol(undefined)
                        //                     return
                        //                   }
                        //                   setSelectedSymbol(symbol)
                        //                 }}
                        //                 style={{marginLeft:10, borderRadius:'16px', marginTop:"20px"}}
                        //                 className={`${symbol.symbolID === selectedSymbol?.symbolID ? 'border-rred' : 'border-#DCDBE8'} flex items-center w-20 h-20 justify-center border ${symbol.symbolID === selectedSymbol?.symbolID && 'border-2'}`}>
                        //                 <img className={`object-contain ${symbol.symbolID === selectedSymbol?.symbolID && ''}`} width={40} height={40} src={symbol.symbol} alt={symbol.symbolID.toString()} />
                        //               </div>
                        //             </Grid>
                        //           )
                        //         })
                        //         :
                        //         <div className="flex w-full h-full items-center justify-center">
                        //           <CircularProgress color='inherit' />
                        //         </div>
                        //     }
                        //   </Grid>
                        //   <ContainedButton
                        //     // ref={refContainedButton}
                        //     onClick={() => {
                        //       setStep(7)
                        //     }}
                        //     disabled={!selectedSymbol}
                        //     title="Next" className="w-40" />
                        // </div>
                        : step === 7
                          ?
                          <div className="flex flex-col items-center space-y-4 m-4">
                            <FormControl className="w-80">
                              <InputLabel id="demo-simple-select-label">Sequence</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sequence?.toString()}
                                label="Sequence"
                                margin='none'
                                onChange={handleChangeSequence}
                              >
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl className="w-80">
                              <InputLabel id="demo-simple-select-label">Matrix Size</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={matrixSize?.toString()}
                                label="Matrix Size"
                                margin='none'
                                onChange={handleChangeMatrixSize}
                              >
                                {["3x3", "3x4", "4x4"].map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}

                              </Select>
                            </FormControl>
                            <ContainedButton
                              style={{ backgroundColor: '#FB3B8C', color: "white", }}
                              loading={updateSymbolLoading}
                              disabled={sequence === undefined || matrixSize === undefined} onClick={() => {
                                if (userId && selectedSymbol && sequence !== undefined && matrixSize !== undefined) {
                                  const [matrixSizeW, matrixSizeH] = matrixSize.split("x").map((val) => parseInt(val));
                                  if (hasChanged)
                                    updateUserSymbol({ userId: userId.toString(), symbol: selectedSymbol.symbolID, sequence, matrixSizeH, matrixSizeW })
                                  else setStep(8)
                                }
                              }} className="w-40" title="Next" />
                          </div>
                          :
                          step === 8
                            ?
                            <div className="flex flex-col items-center space-y-4 m-4">
                              {/* {
              isQuestionsLoading
              ?
              <CircularProgress className="my-2"/>
              :
              <div>
                <ContainedButton
                  loading={false}
                  disabled={false} onClick={()=>{
                    return
                }} className="w-40" title="Sign Up"/>
              </div>
            } */}
                              <SecurityQuestionsForm
                                step={step}
                                onSubmit={() => {
                                  setStep(9)
                                }}
                                userId={userId} />
                            </div>
                            :
                            step === 9
                              ?
                              <div className="flex flex-col items-center space-y-4 m-4">
                                <img className='object-contain' width={80} height={80} src='./Frame.png' alt='frame' />
                                <Typography className="text-black text-xl font-bold">
                                  You have successfully registered!
                                </Typography>
                                <img className='object-contain' width={612} height={350} src='./Frame-1973340239.png' alt='frame' />
                                <ContainedButton
                                  style={{ backgroundColor: '#FB3B8C', color: "white", }}
                                  onClick={handleNavigateLogin}
                                  title="Already have the app? Let's go to login"
                                />
                              </div>
                              :
                              null
          }
          {/* {step === 1 && <RegisterForm onSubmit={handleRegistered} />}
      {step === 2 && (
        <SelectSymbol onSubmit={handleNextStep} userId={userId} />
      )}
      {step === 3 && (
        <SecurityQuestionsForm onSubmit={handleNextStep} userId={userId} />
      )}
      {step === 4 &&
        (location.state?.activateToken ? (
          <Navigate
            to={`/activate-group-user/${location.state.activateToken}`}
          />
        ) : (
          <Navigate to="/login" />
        ))} */}
        </StepContext.Provider>
      </div>
    </AuthLayout>
  )
}

export default RegisterPage;
