import {
  Box,
  Button,
  Popover,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  TextField,
  Autocomplete,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { MdFilterList as FilterListIcon } from "react-icons/md";
import { FC, useEffect, useState } from "react";
import { DataGrid, DataGridProps, GridColumnVisibilityModel, gridPageSelector, gridPageSizeSelector, gridRowCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { Filter, FilterColumns } from "src/share/types";
import React from "react";
import CustomPagination from "./CustomPagination";

export type PaginationModel = {
  pageSize: number;
  page: number;
};

type FilterableDataTableProps = DataGridProps & {
  initFilters: Filter[];
  filterColumns: FilterColumns;
  onPaginationChange: (paginationModel: PaginationModel) => void;
  onApplyFilters: (filters: Filter[]) => void;
  additionalToolbarButton?: React.ReactNode;
  initPaginationModel?: PaginationModel;
  columnVisibilityModel?: GridColumnVisibilityModel
};

const FilterableDataTable: FC<FilterableDataTableProps> = ({
  initFilters,
  filterColumns,
  onApplyFilters,
  onPaginationChange,
  additionalToolbarButton,
  initPaginationModel,
  columnVisibilityModel,
  ...rest
}) => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(
    initPaginationModel ?? {
      pageSize: 10,
      page: 0,
    }
  );
  const [filters, setFilters] = useState<Filter[]>(initFilters);

  useEffect(() => {
    onPaginationChange(paginationModel);
  }, [paginationModel]);

  const handleAddFilter = () => {
    setFilters([...filters, { column: "", value: "" }]);
  };

  const handleFilterChange = (
    index: number,
    field: keyof Filter,
    value: any
  ) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      if (field === "column") {
        const filterColumn = filterColumns.find((col) => col.name === value);
        if (filterColumn?.type === "timestamp") {
          // set date 2 months ago as default
          const currentDate = new Date();
          const twoMonthsAgo = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 2,
            currentDate.getDate()
          );
          newFilters[index].value = `${twoMonthsAgo
            .toISOString()
            .slice(0, 10)}T00:00`;
        } else {
          newFilters[index].value = "";
        }
      }
      newFilters[index][field] = value;
      return newFilters;
    });
  };

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleApplyFilters = () => {
    if (onApplyFilters) {
      onApplyFilters(filters);
    }
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  };

  return (
    <DataGrid
    columnVisibilityModel={columnVisibilityModel}
      disableColumnMenu
      disableColumnFilter
      pageSizeOptions={[10, 20, 50, 100]}
      {...rest}
      initialState={{
        pagination: {
          paginationModel: initPaginationModel ?? {
            pageSize: 10,
            page: 1,
          },
        },
      }}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      slots={{
        // toolbar: FilterToolbar,
        pagination: CustomPagination,
      }}
      sx={{
        fontSize: '14px',
        color: '#111111',
        mt: 2,
        border: '1px solid #DCDBE8',
        borderRadius: '8px',
        '& .MuiPaginationItem-root.Mui-selected:hover': {
          backgroundColor: '#303030',
          color: 'white',
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "break-spaces",
          fontWeight: "500",
          fontFamily: "Inter",
          lineHeight: 1.2,
        },
        ".MuiDataGrid-cell.MuiDataGrid-cell--editing": {
          backgroundColor: "transparent",
          boxShadow: 'none',
          border: "0"
        }
      }}
      checkboxSelection={false}
      slotProps={{
        toolbar: {
          filters,
          filterColumns,
          handleFilterChange,
          handleAddFilter,
          handleRemoveFilter,
          handleApplyFilters,
          additionalButton: additionalToolbarButton,
        },
      }}
    />
  );
};

type FilterToolbarProps = {
  filters: Filter[];
  filterColumns: FilterColumns;
  handleFilterChange: (index: number, field: keyof Filter, value: any) => void;
  handleAddFilter: () => void;
  handleRemoveFilter: (index: number) => void;
  handleApplyFilters: () => void;
  additionalButton?: React.ReactNode;
};

const FilterToolbar: FC<FilterToolbarProps> = ({
  filters,
  filterColumns,
  handleFilterChange,
  handleAddFilter,
  handleRemoveFilter,
  handleApplyFilters,
  additionalButton,
}) => {
  const [unfilteredColumns, setUnfilteredColumns] = useState<
    Array<{
      name: string;
      type: string;
      label: string;
      disabled: boolean;
    }>
  >([]);
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setUnfilteredColumns(() => {
      return filterColumns.map((col) => {
        const isFiltered =
          filters.find((filter) => filter.column === col.name) !== undefined;
        return { ...col, disabled: isFiltered };
      });
    });
  }, [filters]);

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
  };

  const filterOpen = Boolean(filterAnchorEl);

  const renderFilterColumn = (filter: Filter, index: number) => {
    const filterColumn = filterColumns.find(
      (col) => col.name === filter.column
    );
    if (filterColumn === undefined) return null;
    const columnType = filterColumn.type;
    if (columnType === "boolean") {
      return (
        <Checkbox
          checked={
            typeof filter.value === "string"
              ? filter.value === "true"
              : Boolean(filter.value)
          }
          onChange={(event) =>
            handleFilterChange(index, "value", event.target.checked)
          }
        />
      );
    }
    if (columnType === "singleSelect") {
      if (filterColumn.options === undefined) return null;
      return (
        <Autocomplete
          options={filterColumn.options}
          getOptionLabel={(option) => {
            return option.label;
          }}
          size="small"
          onChange={(_, newValue) => {
            handleFilterChange(index, "value", newValue?.value);
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={
            filterColumn.options.find((option) => {
              if (typeof option.value === "number")
                return option.value === parseInt(filter.value);
              return option.value === filter.value;
            }) ?? null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={filterColumn.label}
              margin="none"
              size="small"
            />
          )}
        />
      );
    }
    const inputFieldTypeMap = {
      number: "number",
      date: "date",
      timestamp: "datetime-local",
      string: "text",
    };
    return (
      <TextField
        label="Value"
        value={filter.value}
        onChange={(event) =>
          handleFilterChange(index, "value", event.target.value)
        }
        type={inputFieldTypeMap[columnType] ?? "text"}
        margin="none"
        size="small"
        fullWidth
      />
    );
  };

  const renderFilters = () => {
    return (
      <>
        {filters.map((filter, index) => (
          <React.Fragment key={index}>
            <Grid item xs={4}>
              <FormControl size="small" margin="none" fullWidth>
                <InputLabel id={`filter-column-${index}`}>Column</InputLabel>
                <Select
                  labelId={`filter-column-${index}`}
                  label="Column"
                  value={filter.column || ""}
                  onChange={(event) => {
                    handleFilterChange(
                      index,
                      "column",
                      event.target.value as string
                    );
                  }}
                >
                  {unfilteredColumns.map((col) => (
                    <MenuItem
                      value={col.name}
                      key={col.name}
                      style={{ display: col.disabled ? "none" : "flex" }}
                    >
                      {col.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {renderFilterColumn(filter, index)}
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemoveFilter(index)}
              >
                Remove
              </Button>
            </Grid>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box sx={{ marginBottom: 1, display: "flex" }}>
      {additionalButton}
      <Button
        startIcon={<FilterListIcon size={20} />}
        sx={{ marginLeft: 1 }}
        onClick={handleOpenFilter}
      >
        Filter
      </Button>
      <Popover
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: "600px", p: 2 }}>
          <Grid container spacing={2}>
            {renderFilters()}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddFilter}
              >
                Add Filter
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                sx={{ marginLeft: 1 }}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
};

export default FilterableDataTable;
