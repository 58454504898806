import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { Filter, FilterColumns } from "src/share/types";
import FilterableDataTable, {
  PaginationModel,
} from "src/dashboard/components/DataTable/FilterableDataTable";
import { useQuery } from "react-query";
import { getAuditLogsFn } from "src/api";
import { Box, Typography } from "@mui/material";
import { CiSearch } from "react-icons/ci";

const filterColumns: FilterColumns = [
  {
    name: "id",
    type: "number",
    label: "ID",
  },
  {
    name: "table_name",
    type: "string",
    label: "Table Name",
  },
  {
    name: "record_id",
    type: "number",
    label: "Record ID",
  },
  {
    name: "log_id",
    type: "number",
    label: "Log ID",
  },
  {
    name: "field_name",
    type: "string",
    label: "Field Name",
  },
  {
    name: "old_value",
    type: "string",
    label: "Old Value",
  },
  {
    name: "new_value",
    type: "string",
    label: "New Value",
  },
  {
    name: "timestamp",
    type: "timestamp",
    label: "Timestamp",
  },
];

const columns: GridColDef[] = [
  {
    field: "id", headerName: "Id", width: 80, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "table_name", headerName: "Table Name", width: 150, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "record_id", headerName: "Record ID", width: 150, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "log_id", headerName: "Log ID", width: 160, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "field_name", headerName: "Field Name", width: 160, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "old_value", headerName: "Old Value", width: 160, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "new_value", headerName: "New Value", width: 160, headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 170,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => new Date(params.value).toLocaleString(),
  },
];

function AuditLogs() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);

  const [rowCount, setRowCount] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");


  const applyFilter = (data: GridRowsProp) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row) =>
      row.id.toString().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);


  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const {
    isFetching,
    isError,
    data: auditLogs,
  } = useQuery({
    queryKey: [
      "auditLogs",
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () =>
      getAuditLogsFn({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      }),
    keepPreviousData: true,
    staleTime: 0, // always get the latest audit logs
  });

  useEffect(() => {
    if (!auditLogs) return;
    setRows(auditLogs.data);
    setRowCount(auditLogs.meta.total);
  }, [auditLogs]);

  const initFilters = useMemo(() => {
    const query = searchParams;
    const newFilters = [];
    for (const col of Array.from(query.keys())) {
      const value = query.get(col);
      if (value === null) continue;
      newFilters.push({ column: col, value });
    }
    return newFilters;
  }, []);

  const handleOnApplyFilters = (filters: Filter[]) => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});
    // set the search query of the current URL
    setSearchParams(query);
  };

  if (isError) {
    return (
      <SectionContainer>
        <Typography variant="h4">Trouble loading audit logs</Typography>
      </SectionContainer>
    );
  }

  return (
    <Box sx={{
      height: 630,
      marginBottom:10,
      width: 'fit-content',
      '& .column-style': {
        backgroundColor: '#F3F2F7',
        fontWeight: "bold"
      },
      '& .row-style': {
        color: '#111111',
        fontSize: '14px'
      },

    }}>
      <Box className="header-group">
        <div className="product-search">
          <CiSearch size={20} />
          <input
            type="text"
            placeholder="Search log"
            className="products__search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
      </Box>
      <FilterableDataTable
        loading={isFetching}
        initFilters={initFilters}
        columns={columns}
        rows={filteredRows}
        rowCount={rowCount}
        filterColumns={filterColumns}
        paginationModel={paginationModel}
        onPaginationChange={setPaginationModel}
        onApplyFilters={handleOnApplyFilters}
      />
    </Box>
  );
}

export default AuditLogs;
