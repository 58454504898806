import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getApiLogsFn = async ({
  page,
  pageSize,
  query,
}: GetApiLogsInput) => {
  const { data } = await apiRequest.get<GetApiLogsResponse>(
    ENDPOINTS.LOGS + '/api',
    {
      params: {
        ...query,
        page: page + 1,
        limit: pageSize,
      },
    }
  );
  return data;
};

export const getAuditLogsFn = async ({
  page,
  pageSize,
  query,
}: GetAuditLogsInput) => {
  const { data } = await apiRequest.get<GetAuditLogsResponse>(
    ENDPOINTS.LOGS + '/audit',
    {
      params: {
        ...query,
        page: page + 1,
        limit: pageSize,
      },
    }
  );
  return data;
};

type GetApiLogsInput = {
  page: number;
  pageSize: number;
  query?: any;
};

type GetApiLogsResponse = {
  meta: {
    total: number;
  };
  data: any;
};

type GetAuditLogsInput = GetApiLogsInput;

type GetAuditLogsResponse = GetApiLogsResponse;
