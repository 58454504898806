import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface CustomRoute {
  path: string;
  element: JSX.Element;
  title?: string | undefined;
}

export function DocumentTitle({ routes }: { routes: CustomRoute[] }) {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const routePath = location.pathname.split('/')

  useEffect(() => {
    const currentRoute = routes.find((route) => route.path === `/${routePath[1]}`);
    const pathname = location.pathname;
    if (currentRoute && currentRoute.title) {
      if (currentRoute.title === 'SyMania') {
        setTitle(`SyMania`);
      } else {
        setTitle(`SyMania  ${currentRoute.title}`);
      }
    }
    if (pathname.includes('/personal')) {
      setTitle('SyMania Personal');
    }
    if (pathname.includes('/sym-admin')) {
      setTitle('SyMania Sym Admin');
    }
    if (pathname.includes('/org-admin')) {
      setTitle('SyMania Org Admin');
    }
  }, [location]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
}
