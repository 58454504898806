import { Box, Button, Divider, IconButton, Menu, MenuItem, Modal, Snackbar, TextField, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import {
  MdEdit as EditIcon,
  MdDeleteForever as DeleteIcon,
} from "react-icons/md";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  createColorFn,
  deleteColorFn,
  getColorsFn,
  updateColorFn,
} from "src/api/services/colors.service";
import LoadingIndicator from "src/components/LoadingIndicator";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { queryClient } from "src/query-client";
import { Filter, FilterColumns } from "src/share/types";
import { validateHexField, validateRequiredField } from "src/share/utils";
import FilterableDataTable, {
  PaginationModel,
} from "../../components/DataTable/FilterableDataTable";
import { CiSearch } from "react-icons/ci";
import { IoMdClose, IoMdMore } from "react-icons/io";
import { Images } from "src/assets";
import { SketchPicker } from 'react-color';
import './ColorsSetting.modules.css'

const filterColumns: FilterColumns = [
  {
    name: "name",
    label: "Name",
    type: "string",
  },
  {
    name: "hex",
    label: "Hex",
    type: "string",
  },
];

function ColorsSettings() {
  const [rowCount, setRowCount] = useState(20);
  const [search, setSearch] = useState("");
  const [snack, setSnack] = useState(false);

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 20,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [color, setColor] = useState("");

  const { data, isFetching } = useQuery({
    queryKey: [
      "colors",
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () =>
      getColorsFn({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      }),
  });
  const [updateModalState, setUpdateModalState] = useState({
    isOpen: false,
    color: null,
  });
  const { mutate: createColor, isLoading: isCreatingColor } = useMutation(
    createColorFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("colors");
        setSnack(true)
        handleCloseCreateModal();
      },
    }
  );
  const { mutate: updateColor, isLoading: isUpdatingColor } = useMutation(
    updateColorFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("colors");
        handleCloseUpdateModal();
      },
    }
  );
  const { mutate: deleteColor, isLoading: isDeletingColor } = useMutation(
    deleteColorFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("colors");
      },
    }
  );

  useEffect(() => {
    if (!data) return;
    setRowCount(data.meta.total);
  }, [data]);

  const initFilters = useMemo(() => {
    const query = searchParams;
    const newFilters = [];
    for (const col of Array.from(query.keys())) {
      const value = query.get(col);
      if (value === null) continue;
      newFilters.push({ column: col, value });
    }
    return newFilters;
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 250,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "hex",
      headerName: "Hex",
      width: 250,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "color",
      headerName: "Color",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      width: 400,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              width: 50,
              height: 30,
              backgroundColor: `#${row.hex}`,
              borderRadius: 1,
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      width: 100,
      renderCell: (params) => (
        <ActionsCell row={params.row as UpdateColorInput} />
      ),
    },
  ];

  const ActionsCell: React.FC<{ row: UpdateColorInput }> = ({ row }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };


    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >

          <MenuItem sx={{ padding: '14px' }} onClick={() => handleOpenUpdateModal(row)}>
            <img style={{ marginRight: '12px' }} src={Images.icEdit} width="20" height="20" />
            Edit color
          </MenuItem>
          <MenuItem sx={{ padding: '14px' }} onClick={() => handleDeleteColor(row.id)}>
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete color
          </MenuItem>
        </Menu>
      </>
    );
  };

  const handleOpenUpdateModal = (row: any) => {
    setUpdateModalState({ isOpen: true, color: row });
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalState({ isOpen: false, color: null });
    setColor("");
  };

  const handleDeleteColor = (id: number) =>  {
    if (window.confirm("Are you sure you want to delete this color?")) {
      deleteColor(id);
    }
  };

  const handleOnApplyFilters = (filters: Filter[]) => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});
    // set the search query of the current URL
    setSearchParams(query);
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
    setColor("");
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
    setColor("");
  };

  if (isFetching) {
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );
  }

  const filteredRows = data?.data.filter((row) =>
    row.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Box sx={{
        marginTop: '36px',
        height: 630,
        marginBottom:10,
        width: 'fit-content',
        '& .column-style': {
          backgroundColor: '#F3F2F7',
          fontWeight: "bold"
        },
        '& .row-style': {
          color: '#111111',
          fontSize: '14px'
        },
      }}>
        <Box className="header-group">
          <div className="product-search">
            <CiSearch size={20} />
            <input
              type="text"
              placeholder="Search color"
              className="products__search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}

            />
          </div>
          <ColorsToolbar handleCreateClick={handleOpenCreateModal} />
        </Box>
        <FilterableDataTable
          loading={isFetching || isDeletingColor}
          initFilters={initFilters}
          columns={columns}
          rows={filteredRows ?? []}
          pageSizeOptions={[20, 50, 100]}
          rowCount={rowCount}
          filterColumns={filterColumns}
          initPaginationModel={{ page: 0, pageSize: 20 }}
          onPaginationChange={setPaginationModel}
          onApplyFilters={handleOnApplyFilters}
          getRowId={(row) => row.id}
        />
      </Box>
      <CreateColorModal
        color={color}
        headerText="New Color"
        isOpen={isCreateModalOpen}
        handleCreateColor={createColor}
        handleChangeComplete={(color) => setColor(color)}
        handleCancel={handleCloseCreateModal}
        isSubmitting={isCreatingColor}
      />
      <UpdateColorModal
        color={color}
        isOpen={updateModalState.isOpen}
        handleUpdateColor={updateColor}
        handleCancel={handleCloseUpdateModal}
        handleChangeComplete={(color) => setColor(color)}
        isSubmitting={isUpdatingColor}
        colorToUpdate={updateModalState.color}
      />
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setSnack(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            background: '#303030 !important',
            borderRadius: '30px'
          }
        }}
        message="A new color was created successfuly"
      />
    </>
  );
}

type ColorsToolbarProps = {
  handleCreateClick: () => void;
};

const ColorsToolbar: FC<ColorsToolbarProps> = ({ handleCreateClick }) => {
  return (
    <Box sx={{ marginBottom: 1 }}>
      <Button sx={{
        "&:hover": { backgroundColor: "#303030" },
        backgroundColor: "#303030",
        borderRadius: '12px'
      }} variant="contained" color="primary" onClick={handleCreateClick}>
        New Color
      </Button>
    </Box>
  );
};

type CreateColorInput = {
  name: string;
  hex: string;
};

type CreateColorModalProps = {
  isOpen: boolean;
  color: string;
  headerText: string;
  handleCreateColor: (color: any) => void;
  handleChangeComplete: (color: any) => void;
  handleCancel: () => void;
  isSubmitting: boolean;
};

const CreateColorModal: FC<CreateColorModalProps> = ({
  isOpen,
  color,
  headerText,
  handleCreateColor,
  handleChangeComplete,
  handleCancel,
  isSubmitting,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm<CreateColorInput>();

  useEffect(() => {
    reset();
    handleChangeComplete("");
  }, [isOpen]);

  const createColor = () => {
    if (!isDirty || !isValid) return;
    const color = getValues();
    handleCreateColor(color);
  };

  const isColorEmpty = color === ''
  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            {headerText}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <form style={{ padding: '60px', width: '100%' }} onSubmit={handleSubmit(createColor)}>
          <TextField
            {...register("name", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Name"
            fullWidth
            placeholder="Enter name"
            margin="dense"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <Box sx={{ gap: 1, display: "flex", alignItems: 'center' }}>
            <TextField
              required
              onChange={(event) => { handleChangeComplete(event.target.value); setValue('hex', event.target.value) }}
              label="Hex"
              value={color}
              margin="normal"
              fullWidth
              error={!!errors.hex}
              helperText={errors.hex?.message}
            />
            <Box sx={{ marginTop: '7px', width: 55, height: 55, borderRadius: '5px', background: color }} />
          </Box>
          <SketchPicker
            color={color || '#924E4E'}
            onChangeComplete={(color) => { handleChangeComplete(color.hex); setValue('hex', color.hex) }}
            className="custom-sketch-picker"
            presetColors={[]}
            disableAlpha={true}
          />
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting || isColorEmpty}
              >
                {'Save'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

type UpdateColorInput = {
  id: number;
  name: string;
  hex: string;
};

type UpdateColorModalProps = {
  isOpen: boolean;
  color: string;
  handleUpdateColor: (color: any) => void;
  handleChangeComplete: (color: any) => void;
  handleCancel: () => void;
  isSubmitting: boolean;
  colorToUpdate: any;
};

const UpdateColorModal: FC<UpdateColorModalProps> = ({
  isOpen,
  color,
  handleUpdateColor,
  handleChangeComplete,
  handleCancel,
  isSubmitting,
  colorToUpdate,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger
  } = useForm<UpdateColorInput>();

  useEffect(() => {
    if (isOpen && colorToUpdate) {
      reset(colorToUpdate);
      handleChangeComplete(colorToUpdate.hex ? `#${colorToUpdate.hex}` : '');
    }
  }, [isOpen, colorToUpdate]);

  const createColor = () => {
    const color = getValues();
    handleUpdateColor(color);
  };

  if (!colorToUpdate) return null;

  const isColorChanged = `#${colorToUpdate.hex}` === color
  const isNameOfTheColorChanged = colorToUpdate.name ===  getValues("name")
  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            Update Color {colorToUpdate.id}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>

        <form style={{ padding: '60px', width: '100%' }} onSubmit={handleSubmit(createColor)}>
          <TextField
            {...register("name", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Name"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <Box sx={{ gap: 1, display: "flex", alignItems: 'center' }}>
            <TextField
              required
              onChange={(event) => {
                const value = event.target.value;
                handleChangeComplete(value);
                setValue("hex", value);
                trigger("hex");
              }}
              label="Hex"
              value={color}
              margin="normal"
              fullWidth
              error={!!errors.hex}
              helperText={errors.hex?.message}
            />
             <Box sx={{ marginTop: '7px', width: 55, height: 55, borderRadius: '5px', background: color }} />
          </Box>
          <SketchPicker
            color={color}
            onChangeComplete={(color) => {
              handleChangeComplete(color.hex);
              setValue("hex", color.hex, { shouldDirty: true });
              trigger("hex");
            }}
            className="custom-sketch-picker"
            presetColors={[]}
            disableAlpha={true}
          />
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={isNameOfTheColorChanged && isColorChanged}
              >
                {'Save'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ColorsSettings;
