import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getEmailHistoryFn = async ({
  page,
  pageSize,
  query,
}: GetEmailHistoryInput) => {
  const { data } = await apiRequest.get<GetEmailHistoryResponse>(
    ENDPOINTS.EMAIL_HISTORY,
    {
      params: {
        ...query,
        page: page + 1,
        limit: pageSize,
      },
    }
  );
  return data;
};

type GetEmailHistoryInput = {
  query?: any;
  page: number;
  pageSize: number;
};

type GetEmailHistoryResponse = {
  meta: {
    total: number;
  };
  data: any;
};
