import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSecurityQuestionsFn, updateUserSecurityQuestionsFn } from "src/api";
import { useAuth } from "src/providers/AuthProvider";
import { ContainedButton } from "../Button";
import LoadingIndicator from "../LoadingIndicator";

interface SecurityQuestionsFormProps {
  onSubmit: () => void;
  userId: number | null;
  step: number;
}

type SecurityQuestion = {
  id: number;
  question: string;
};

type SecurityQuestionOption = SecurityQuestion & {
  disabled?: boolean;
};

function SecurityQuestionsForm({
  onSubmit,
  userId,
  step,
}: SecurityQuestionsFormProps) {
  const { login } = useAuth();
  const [questions, setQuestions] = useState<SecurityQuestionOption[]>([])
  const [question1Index, setQuestion1Index] = useState<number>()
  const [answer1, setAnswer1] = useState('')
  const [question2Index, setQuestion2Index] = useState<number>()
  const [answer2, setAnswer2] = useState('')
  const [question3Index, setQuestion3Index] = useState<number>()
  const [answer3, setAnswer3] = useState('')


  const { data: securityQuestions, isFetching: isQuestionsLoading, refetch } = useQuery({
    queryKey: ["securityQuestions"],
    queryFn: () => getSecurityQuestionsFn(),
    onSuccess: (data) => {
      setQuestions(data);
    },
  });

  useEffect(() => {
    if (step === 8) {
      refetch();
    }
  }, [step, refetch]);

  const { mutate: updateUserSecurityQuestions, isLoading: isSubmitting } =
    useMutation(updateUserSecurityQuestionsFn, {
      onSuccess: (data) => {
        login(data.token, onSubmit);
      },
    });

    const submitSecurityQuestionsForm = async () => {
      const trimmedAnswer1 = answer1.trim();
      const trimmedAnswer2 = answer2.trim();
      const trimmedAnswer3 = answer3.trim();
    
      if (
        question1Index === undefined || trimmedAnswer1 === '' ||
        question2Index === undefined || trimmedAnswer2 === '' ||
        question3Index === undefined || trimmedAnswer3 === ''
      ) {
        alert("Please fill all the fields with valid answers. Answers cannot be only spaces.");
        return;
      }
    
      if (!userId) {
        alert("You are not authenticated");
        return;
      }
    
      updateUserSecurityQuestions({
        userId,
        question1Id: questions[question1Index].id,
        answer1: trimmedAnswer1,
        question2Id: questions[question2Index].id,
        answer2: trimmedAnswer2,
        question3Id: questions[question3Index].id,
        answer3: trimmedAnswer3,
      });
    };

  if (!userId)
    return (
      <h1 className="text-center">You are not authenticated</h1>
    );

  if ((securityQuestions ?? []).length < 3)
    return (
      <div>
        <h1 className="text-center">Security Questions</h1>
        <p className="text-center">
          Sorry, we do not have enough security questions for you to select.
          Please contact us for support.
        </p>
      </div>
    );

  if (isQuestionsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col space-y-4 m-4">
        <FormControl className="w-80">
          <InputLabel id={`question-label1`}>{`Question 1`}</InputLabel>
          <Select
            labelId={`question-label1`}
            label={`Question 1`}
            margin='none'
            value={question1Index !== undefined ? questions[question1Index].question : undefined}
            onChange={event => {
              setQuestion1Index(parseInt(event.target.value as string));
            }}
          >
            {questions.map((question, index) => (
              <MenuItem
                disabled={question.disabled || index === question1Index || index === question2Index || index === question3Index}
                value={index} key={question.id}>{question.question}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField id='answer1' label='Answer 1' value={answer1} onChange={event => setAnswer1(event.target.value)} />
      </div>
      <div className="flex flex-col space-y-4 m-4">
        <FormControl className="w-80">
          <InputLabel id={`question-label2`}>{`Question 2`}</InputLabel>
          <Select
            labelId={`question-label2`}
            label={`Question 2`}
            margin='none'
            value={question2Index !== undefined ? questions[question2Index].question : undefined}
            onChange={event => {
              setQuestion2Index(parseInt(event.target.value as string));
            }}
          >
            {questions.map((question, index) => (
              <MenuItem
                disabled={question.disabled || index === question1Index || index === question2Index || index === question3Index}
                value={index} key={question.id}>{question.question}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField id='answer2' label='Answer 2' value={answer2} onChange={event => setAnswer2(event.target.value)} />
      </div>
      <div className="flex flex-col space-y-4 m-4">
        <FormControl className="w-80">
          <InputLabel id={`question-label3`}>{`Question 3`}</InputLabel>
          <Select
            labelId={`question-label3`}
            label={`Question 3`}
            margin='none'
            value={question3Index !== undefined ? questions[question3Index].question : undefined}
            onChange={event => {
              setQuestion3Index(parseInt(event.target.value as string));
            }}
          >
            {questions.map((question, index) => (
              <MenuItem
                disabled={question.disabled || index === question1Index || index === question2Index || index === question3Index}
                value={index} key={question.id}>{question.question}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField id='answer3' label='Answer 3' value={answer3} onChange={event => setAnswer3(event.target.value)} />
      </div>
      <ContainedButton
        loading={isSubmitting}
        disabled={question1Index === undefined || !answer1 || question2Index === undefined || !answer2 || question3Index === undefined || !answer3}
        type="submit"
        style={{ backgroundColor: '#FB3B8C', color: "white", }}
        onClick={submitSecurityQuestionsForm}
        className="w-40" title="Sign Up" />
    </div>
  );
}

export default SecurityQuestionsForm;
