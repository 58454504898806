import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UAParser from 'ua-parser-js';

const CheckDevice = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();

    if (result.device.type === 'mobile') {
      if (result.os.name === 'iOS') {
        window.location.href = 'https://apps.apple.com/us/app/symania-by-uru/id6503657043';
      } else if (result.os.name === 'Android') {
        window.location.href = 'https://play.google.com/';
      }
    } else {
      // If not a mobile device, you might want to navigate to the homepage or another appropriate page
      navigate('/');
    }
  }, [navigate]);

  return <></>;
};

export default CheckDevice;
