import { Box, Button, Divider, IconButton, Menu, MenuItem, Modal, TextField, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import {
  MdEdit as EditIcon,
  MdDeleteForever as DeleteIcon,
} from "react-icons/md";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  createSubscriptionFn,
  deleteSubscriptionFn,
  getSubscriptionsFn,
  updateSubscriptionFn,
} from "src/api/services/subscriptions.service";
import LoadingIndicator from "src/components/LoadingIndicator";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { queryClient } from "src/query-client";
import { Filter, FilterColumns } from "src/share/types";
import { validateRequiredField } from "src/share/utils";
import FilterableDataTable, {
  PaginationModel,
} from "../../components/DataTable/FilterableDataTable";
import { IoMdClose, IoMdMore } from "react-icons/io";
import { Images } from "src/assets";

const filterColumns: FilterColumns = [
  {
    name: "text",
    label: "Text",
    type: "string",
  },
];

function SubscriptionsSettings() {
  const [rowCount, setRowCount] = useState(20);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 20,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { data, isFetching } = useQuery({
    queryKey: [
      "subscriptions",
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () =>
      getSubscriptionsFn({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      }),
  });
  const [updateModalState, setUpdateModalState] = useState({
    isOpen: false,
    color: null,
  });
  const { mutate: createSubscription, isLoading: isCreatingSubscription } =
    useMutation(createSubscriptionFn, {
      onSuccess: () => {
        queryClient.invalidateQueries("subscriptions");
        handleCloseCreateModal();
      },
    });
  const { mutate: updateSubscription, isLoading: isUpdatingSubscription } =
    useMutation(updateSubscriptionFn, {
      onSuccess: () => {
        queryClient.invalidateQueries("subscriptions");
        handleCloseUpdateModal();
      },
    });
  const { mutate: deleteSubscription, isLoading: isDeletingSubscription } =
    useMutation(deleteSubscriptionFn, {
      onSuccess: () => {
        queryClient.invalidateQueries("subscriptions");
      },
    });

  useEffect(() => {
    if (!data) return;
    setRowCount(data.meta.total);
  }, [data]);

  const initFilters = useMemo(() => {
    const query = searchParams;
    const newFilters = [];
    for (const col of Array.from(query.keys())) {
      const value = query.get(col);
      if (value === null) continue;
      newFilters.push({ column: col, value });
    }
    return newFilters;
  }, []);

  const CustomEditComponent: FC<{
    params: GridRenderEditCellParams;
    handleUpdateSubscription: (color: any) => void;
  }> = ({ params, handleUpdateSubscription }) => {
    const { register, setValue } = useForm();
    const currentValue = params.row.text

    useEffect(() => {
      setValue('text', currentValue);

    }, [])
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue !== currentValue) {
        setValue('text', newValue);
        handleUpdateSubscription({ id: params.row.id, text: newValue });
      }
    };


    return (
      <input
        {...register("text", {
          required: true,
        })}
        onBlur={handleBlur} // 
        style={{
          marginLeft: '10px',
          padding: '12px',
          borderRadius: '5px',
          border: '1px solid #D2DAE8',
          width: '256px',
          height: '36px',
        }}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 250,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "text",
      headerName: "Name",
      width: 900,
      renderEditCell: (params) => (
        <CustomEditComponent
          params={params}
          handleUpdateSubscription={(value) =>
            updateSubscription(value)
          }
        />
      ),

      editable: true,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => (
        <ActionsCell row={params.row as UpdateSubscriptionInput} />
      ),
    },
  ];

  const ActionsCell: React.FC<{ row: UpdateSubscriptionInput }> = ({ row }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };


    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem sx={{ padding: '14px' }} onClick={() => handleDeleteSubscription(row.id)}>
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete subscription
          </MenuItem>
        </Menu>
      </>
    );
  };

  const handleOpenUpdateModal = (row: any) => () => {
    setUpdateModalState({ isOpen: true, color: row });
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalState({ isOpen: false, color: null });
  };

  const handleDeleteSubscription = (id: number) => () => {
    if (window.confirm("Are you sure you want to delete this color?")) {
      deleteSubscription(id);
    }
  };

  const handleOnApplyFilters = (filters: Filter[]) => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});
    // set the search query of the current URL
    setSearchParams(query);
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  if (isFetching) {
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );
  }

  return (
    <>
      <Box sx={{
        marginTop: '36px',
        height: 500,
        marginBottom:10,
        width: 'fit-content',
        '& .column-style': {
          backgroundColor: '#F3F2F7',
          fontWeight: "bold",
        },
        '& .row-style': {
          color: '#111111',
          fontSize: '14px'
        },
      }}>
        <SubscriptionsToolbar handleCreateClick={handleOpenCreateModal} />
        <FilterableDataTable
          loading={isFetching || isDeletingSubscription}
          initFilters={initFilters}
          columns={columns}
          rows={data?.data ?? []}
          pageSizeOptions={[20, 50, 100]}
          rowCount={rowCount}
          filterColumns={filterColumns}
          initPaginationModel={{ page: 0, pageSize: 20 }}
          onPaginationChange={setPaginationModel}
          onApplyFilters={handleOnApplyFilters}
          getRowId={(row) => row.id}
        // additionalToolbarButton={
        //   <SubscriptionsToolbar handleCreateClick={handleOpenCreateModal} />
        // }
        />
      </Box>
      <CreateSubscriptionModal
        isOpen={isCreateModalOpen}
        handleCreateSubscription={createSubscription}
        handleCancel={handleCloseCreateModal}
        isSubmitting={isCreatingSubscription}
      />
      <UpdateSubscriptionModal
        isOpen={updateModalState.isOpen}
        handleUpdateSubscription={updateSubscription}
        handleCancel={handleCloseUpdateModal}
        isSubmitting={isUpdatingSubscription}
        colorToUpdate={updateModalState.color}
      />
    </>
  );
}

type SubscriptionsToolbarProps = {
  handleCreateClick: () => void;
};

const SubscriptionsToolbar: FC<SubscriptionsToolbarProps> = ({
  handleCreateClick,
}) => {
  return (
    <Box sx={{ marginBottom: 1, display:'flex', justifyContent:"flex-end" }}>
      <Button sx={{
        "&:hover": { backgroundColor: "#303030" },
        backgroundColor: "#303030",
        borderRadius: '12px'
      }} variant="contained" color="primary" onClick={handleCreateClick}>
        New Subscription
      </Button>
    </Box>
  );
};

type CreateSubscriptionInput = {
  text: string;
};

type CreateSubscriptionModalProps = {
  isOpen: boolean;
  handleCreateSubscription: (color: any) => void;
  handleCancel: () => void;
  isSubmitting: boolean;
};

const CreateSubscriptionModal: FC<CreateSubscriptionModalProps> = ({
  isOpen,
  handleCreateSubscription,
  handleCancel,
  isSubmitting,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
  } = useForm<CreateSubscriptionInput>();

  useEffect(() => {
    reset();
  }, [isOpen]);

  const createSubscription = () => {
    if (!isDirty || !isValid) return;
    const color = getValues();
    handleCreateSubscription(color);
  };

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            New Subscription
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <form style={{ padding: '60px', width: '100%' }} onSubmit={handleSubmit(createSubscription)}>
          <TextField
            {...register("text", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Name"
            fullWidth
            margin="normal"
            error={!!errors.text}
            helperText={errors.text?.message}
          />
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Save'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

type UpdateSubscriptionInput = {
  id: number;
  text: string;
};

type UpdateSubscriptionModalProps = {
  isOpen: boolean;
  handleUpdateSubscription: (color: any) => void;
  handleCancel: () => void;
  isSubmitting: boolean;
  colorToUpdate: any;
};

const UpdateSubscriptionModal: FC<UpdateSubscriptionModalProps> = ({
  isOpen,
  handleUpdateSubscription,
  handleCancel,
  isSubmitting,
  colorToUpdate,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
  } = useForm<UpdateSubscriptionInput>();

  useEffect(() => {
    reset(colorToUpdate);
  }, [isOpen]);

  const createSubscription = () => {
    if (!isDirty || !isValid) return;
    const color = getValues();
    handleUpdateSubscription(color);
  };

  if (!colorToUpdate) return null;

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: 500,
          backgroundColor: "white",
          p: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" component="h2">
          Update Subscription {colorToUpdate.id}
        </Typography>
        <form onSubmit={handleSubmit(createSubscription)}>
          <TextField
            {...register("text", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Text"
            fullWidth
            margin="normal"
            error={!!errors.text}
            helperText={errors.text?.message}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{ marginLeft: 1 }}
          >
            Cancel
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default SubscriptionsSettings;
