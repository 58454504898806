import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getOAuthClientsFn = async ({
  orgId,
}: {
  orgId: number | string;
}) => {
  return await apiRequest.get(ENDPOINTS.OAUTH + `/${orgId}/clients`);
};

export const createOAuthClientFn = async (client: OAuthClient) => {
  await apiRequest.post(ENDPOINTS.OAUTH + `/${client.orgId}/clients`, client);
};

export const updateOAuthClientFn = async (client: UpdateOAuthClient) => {
  await apiRequest.put(
    ENDPOINTS.OAUTH + `/${client.orgId}/clients/${client.client_id}`,
    client
  );
};

export const deleteOAuthClientFn = async ({
  orgId,
  clientId,
}: {
  orgId: number;
  clientId: string;
}) => {
  await apiRequest.delete(ENDPOINTS.OAUTH + `/${orgId}/clients/${clientId}`);
};

type OAuthClient = {
  orgId: number;
  name: string;
  redirect_uris: string;
};

type UpdateOAuthClient = OAuthClient & {
  client_id: string;
};
