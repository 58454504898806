import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getSubscriptionsFn = async ({
  page,
  pageSize,
  query,
}: GetSubscriptionsInput) => {
  const { data } = await apiRequest.get<GetSubscriptionsResponse>(
    ENDPOINTS.SUBSCRIPTIONS,
    {
      params: {
        ...query,
        page: page ? page + 1 : 1,
        limit: pageSize,
      },
    }
  );
  return data;
};

export const getSubscriptionFn = async ({ id }: { id: string }) => {
  const { data } = await apiRequest.get<GetSubscriptionResponse>(
    `${ENDPOINTS.SUBSCRIPTIONS}/${id}`
  );

  return data;
};

export const updateSubscriptionFn = async (data: Subscription) => {
  await apiRequest.put(`${ENDPOINTS.SUBSCRIPTIONS}/${data.id}`, data);
};

export const createSubscriptionFn = async (data: NewSubscription) => {
  await apiRequest.post(ENDPOINTS.SUBSCRIPTIONS, data);
};

export const deleteSubscriptionFn = async (id: number) => {
  await apiRequest.delete(`${ENDPOINTS.SUBSCRIPTIONS}/${id}`);
};

type GetSubscriptionsInput = {
  page?: number;
  pageSize?: number;
  query?: any;
};

type GetSubscriptionsResponse = {
  meta: {
    total: number;
    page: number;
    limit: number;
  };
  data: Subscription[];
};

type GetSubscriptionResponse = {
  data: string | undefined;
};

type Subscription = {
  id: number;
  text: string;
};

type NewSubscription = Omit<Subscription, 'id'>;
