import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Container, CircularProgress } from "@mui/material";

interface AppContentProps {
  routes: Array<{
    path: string;
    name: string;
    [key: string]: any;
  }>;
}

const AppContent = ({ routes }: AppContentProps) => {
  return (
    <Container sx={{ padding: "0px !important" }}>
      <Suspense fallback={<CircularProgress />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </Container>
  );
};

export default React.memo(AppContent);
