import { Navigate } from "react-router-dom";
import { useAuth } from "src/providers/AuthProvider";

const LogoutPage = () => {
  const { logout } = useAuth();

  logout();

  return <Navigate to="/" />;
};

export default LogoutPage;
