import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import {
  MdEdit as EditIcon,
  MdDeleteForever as DeleteIcon,
} from "react-icons/md";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  getServiceProvidersFn,
  createServiceProviderFn,
  updateServiceProviderFn,
  deleteServiceProviderFn,
} from "src/api/services/saml.service";
import LoadingIndicator from "src/components/LoadingIndicator";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { queryClient } from "src/query-client";
import { validateRequiredField } from "src/share/utils";
import { env } from "src/env";
import { ENDPOINTS } from "src/api/constants";
import { CiSearch } from "react-icons/ci";
import { IoMdClose, IoMdDownload, IoMdMore } from "react-icons/io";
import { Images } from "src/assets";
import { TfiDownload } from "react-icons/tfi";

function ServiceProviderPage() {
  const { orgId } = useParams();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { data, isFetching } = useQuery({
    queryKey: ["serviceProviders", orgId],
    queryFn: () => (orgId ? getServiceProvidersFn({ orgId }) : undefined),
  });
  const [updateModalState, setUpdateModalState] = useState<{
    isOpen: boolean;
    serviceProvider: null | UpdateServiceProviderInput;
  }>({
    isOpen: false,
    serviceProvider: null,
  });
  const {
    mutate: createServiceProvider,
    isLoading: isCreatingServiceProvider,
  } = useMutation(createServiceProviderFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("serviceProviders");
      handleCloseCreateModal();
    },
  });
  const {
    mutate: updateServiceProvider,
    isLoading: isUpdatingServiceProvider,
  } = useMutation(updateServiceProviderFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("serviceProviders");
      handleCloseUpdateModal();
    },
  });
  const {
    mutate: deleteServiceProvider,
    isLoading: isDeletingServiceProvider,
  } = useMutation(deleteServiceProviderFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("serviceProviders");
    },
  });

  useEffect(() => {
    if (!data) return;
  }, [data]);

  if (!orgId) return null;

  const columns: GridColDef[] = [
    {
      field: "entity_id",
      headerName: "Entity",
      width: 240,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "application_name",
      headerName: "App Name",
      width: 230,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "name_id",
      headerName: "Name ID",
      width: 240,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "acs_url",
      headerName: "Assertion Consumer Service URL",
      width: 230,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "logout_url",
      headerName: "Logout URL",
      width: 230,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => (
        <ActionsCell row={params.row as UpdateServiceProviderInput} />
      ),
    },
  ];

  const ActionsCell: React.FC<{ row: UpdateServiceProviderInput }> = ({ row }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };


    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem sx={{ padding: '14px' }} onClick={() => { handleOpenUpdateModal(row); }}>
            <img style={{ marginRight: '12px' }} src={Images.icEdit} width="20" height="20" />
            Edit Service Provider
          </MenuItem>
          <MenuItem sx={{ padding: '14px' }} onClick={() => { handleDelete(row.entity_id); }}>
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete Service Provider
          </MenuItem>
        </Menu>
      </>
    );
  };

  const handleOpenUpdateModal = (row: any) => {
    setUpdateModalState({
      isOpen: true,
      serviceProvider: {
        entity_id: row.entity_id,
        appName: row.application_name,
        nameId: row.name_id,
        acsUrl: row.acs_url,
        logoutUrl: row.logout_url,
      },
    });
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalState({ isOpen: false, serviceProvider: null });
  };

  const handleDelete = (entityId: string) =>  {
    if (
      window.confirm("Are you sure you want to delete this service provider?")
    ) {
      deleteServiceProvider({ entityId, orgId: parseInt(orgId) });
    }
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateServiceProvider = (serviceProvider: any) =>
    createServiceProvider({ ...serviceProvider, orgId });

  const handleUpdateServiceProvider = (serviceProvider: any) =>
    updateServiceProvider({ ...serviceProvider, orgId });

  if (isFetching) {
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );
  }

  const filteredRows = data?.data.filter((row : any) =>
    row.entity_id.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <SectionContainer>
        <Box sx={{
          height: 600,
          width: 'fit-content',
          '& .column-style': {
            backgroundColor: '#F3F2F7',
            fontWeight: "bold"
          },
          '& .row-style': {
            color: '#111111',
            fontSize: '14px'
          },

        }}>
          <Box className="header-group">

            <div className="product-search">
              <CiSearch size={20} />
              <input
                type="text"
                placeholder="Search service provider"
                className="products__search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <ServiceProvidersToolbar handleCreateClick={handleOpenCreateModal} />
          </Box>
          <DataGrid
            loading={isFetching || isDeletingServiceProvider}
            columns={columns}
            rows={filteredRows ?? []}
            getRowId={(row) => row.entity_id}
            sx={{
              fontSize: '14px',
              color: '#111111',
              mt: 2,
              border: '1px solid #DCDBE8',
              borderRadius: '8px',
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "break-spaces",
                fontWeight: "500",
                fontFamily: "Inter",
                lineHeight: 1.2,
              },
            }}
          />
        </Box>

      </SectionContainer>
      <CreateSpModal
        isOpen={isCreateModalOpen}
        handleCreate={handleCreateServiceProvider}
        handleCancel={handleCloseCreateModal}
        isSubmitting={isCreatingServiceProvider}
      />
      <UpdateSpModal
        isOpen={updateModalState.isOpen}
        handleUpdate={handleUpdateServiceProvider}
        handleCancel={handleCloseUpdateModal}
        isSubmitting={isUpdatingServiceProvider}
        spToUpdate={updateModalState.serviceProvider}
      />
    </>
  );
}

type ServiceProvidersToolbarProps = {
  handleCreateClick: () => void;
};

const ServiceProvidersToolbar: FC<ServiceProvidersToolbarProps> = ({
  handleCreateClick,
}) => {
  return (
    <Box sx={{ marginBottom: 1 }}>
      <Button sx={{
        borderRadius: '12px',
        "&:hover": { backgroundColor: "#303030" },
        backgroundColor: "#303030"
      }} variant="contained" color="primary" onClick={handleCreateClick}>
        New Service Provider
      </Button>
    </Box>
  );
};

type CreateServiceProviderInput = {
  type:string;
  appName: string;
  acsUrl: string;
  logoutUrl: string;
  nameId: string;
  entityId: string;
};

type CreateServiceProviderModalProps = {
  isOpen: boolean;
  handleCreate: (servideProvider: any) => void;
  handleCancel: () => void;
  isSubmitting: boolean;
};

const CreateSpModal: FC<CreateServiceProviderModalProps> = ({
  isOpen,
  handleCreate,
  handleCancel,
  isSubmitting,
}) => {
  const serviceProviders = [
    {
      id: "office365",
      appName: "Azure AD/Office 365",
      acsUrl: "https://login.microsoftonline.com/login.srf",
      logoutUrl: "https://login.microsoftonline.com/login.srf",
      entityId: "urn:federation:MicrosoftOnline",
      nameId: "email",
      showAppName: true,
      showEntityId: false,
      showAcsUrl: false,
      showLogoutUrl: false,
      showNameID: false
    },
    {
      id: "google",
      appName: "Google Cloud / G Suite",
      acsUrl: "https://www.google.com/a/<<domain.com>>/acs",
      logoutUrl: "https://www.google.com/accounts/logout",
      entityId: "www.google.com/a/<<domain.com>>",
      nameId: "email",
      showAppName: true,
      showEntityId: true,
      showAcsUrl: true,
      showLogoutUrl: false,
      showNameID: false
    },
    {
      id: "googleProfile",
      appName: "Google SSO Profile",
      acsUrl: "https://accounts.google.com/samlrp/<<uniqueid>>/acs",
      logoutUrl: "https://www.google.com/accounts/logout",
      entityId: "https://accounts.google.com/samlrp/<<uniqueid>>",
      nameId: "email",
      showAppName: true,
      showEntityId: true,
      showAcsUrl: true,
      showLogoutUrl: false,
      showNameID: false
    },
    {
      id: "zoho",
      appName: "Zoho",
      acsUrl: "https://accounts.zoho.com/signin/samlsp/<<uniqeid>>",
      logoutUrl: "https://accounts.zoho.com/logout/samlsp/<<uniqeid>>",
      entityId: "zoho.com",
      nameId: "email",
      showAppName: true,
      showEntityId: false,
      showAcsUrl: true,
      showLogoutUrl: true,
      showNameID: false
    },
    {
      id: "Github Enterprise Cloud",
      appName: "Github Enterprise Cloud",
      acsUrl: "https://github.com/enterprises/<<slug>>/saml/consume",
      logoutUrl: "https://github.com/logout",
      entityId: "https://github.com/enterprises/<<slug>>",
      nameId: "email",
      showAppName: true,
      showEntityId: true,
      showAcsUrl: true,
      showLogoutUrl: false,
      showNameID: false
    },
    {
      id: "Github Enterprise Cloud - Orgniazation",
      appName: "Github Enterprise Cloud - Orgniazation",
      acsUrl: "https://github.com/orgs/<<slug>>/saml/consume",
      logoutUrl: "https://github.com/logout",
      entityId: "https://github.com/orgs/<<slug>>",
      nameId: "email",
      showAppName: true,
      showEntityId: true,
      showAcsUrl: true,
      showLogoutUrl: false,
      showNameID: false
    },
    {
      id: "Atlassian (Jira)",
      appName: "Atlassian (Jira)",
      acsUrl: "https://auth.atlassian.com/login/callback?connection=saml-<<uniqueid>>",
      logoutUrl: "https://id.atlassian.com/logout",
      entityId: "https://auth.atlassian.com/saml/<<uniqueid>>",
      nameId: "email",
      showAppName: true,
      showEntityId: true,
      showAcsUrl: true,
      showLogoutUrl: false,
      showNameID: false
    },
    {
      id: "custom",
      appName: "Other",
      acsUrl: "",
      logoutUrl: "",
      entityId: "",
      nameId: "email",
      showAppName: true,
      showEntityId: true,
      showAcsUrl: true,
      showLogoutUrl: true,
      showNameID: true
    }
  ]
  const [selectedApp, setSelectedApp] = useState({
    showAppName: false,
    showEntityId: false,
    showAcsUrl: false,
    showLogoutUrl: false,
    showNameID: false
  })
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
    setValue,
  } = useForm<CreateServiceProviderInput>();

  useEffect(() => {
    reset();
  }, [isOpen]);

  const createServiceProvider = () => {
    if (!isDirty || !isValid) return;
    const serviceProvider = getValues();
    handleCreate(serviceProvider);
  };
  const { orgId } = useParams();
  const [snack, setSnack] = useState(false)

  const copy = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      setSnack(true);
    } catch (err) {
      console.log(err);

    }
  };

  const handleTypeChange = async(type:string) => {
    console.log(type)
    const selectType = serviceProviders.filter(s=> s.id === type);
    if(selectType.length > 0){

      setSelectedApp(selectType[0]);
      setValue("appName", selectType[0].appName);
      setValue("acsUrl", selectType[0].acsUrl);
      setValue("entityId", selectType[0].entityId);
      setValue("logoutUrl", selectType[0].logoutUrl);
      setValue("nameId", selectType[0].nameId, { shouldDirty: true });
      console.log(getValues())
    }

  }

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            New Service Provider
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <Box className='title-modal'>
          <Box sx={{ marginTop: 2 }}>
            <Box sx={{ marginBottom: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Typography fontSize={18}>
                  SAML Connection Details
                </Typography>
                <Button
                  sx={{ color: '#111111' }}
                  href={`${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                  download="metadata.xml"
                >
                  <TfiDownload size={16} />
                  <Typography sx={{ marginLeft: '5px' }} fontSize={16}>
                    Metadata
                  </Typography>
                </Button>
              </Box>

              <Typography color="#666666" variant="body1" component="p">
                Add the following information to your SAML Service Provider
              </Typography>
            </Box>
            <Typography color="#222449" variant="body1" component="p">
              <span style={{ color: '#666666' }}>Metadata URL:</span><br></br>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                <img style={{ cursor: 'pointer' }} onClick={() => copy(` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`)} width={24} height={24} src={Images.icCopy} />
              </div>
            </Typography>
            <Typography color="#222449" mt={2} variant="body1" component="p">
              <span style={{ color: '#666666' }}>SSO URL:</span><br></br>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`}
                <img style={{ cursor: 'pointer' }} onClick={() => copy(` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`)} width={24} height={24} src={Images.icCopy} />
              </div>
            </Typography>
          </Box>
        </Box>
        <form style={{ padding: '30px 60px', width: '100%' }} onSubmit={handleSubmit(createServiceProvider)}>
        <TextField
            {...register("type")}
            select
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleTypeChange(event.target.value);
            }}
            margin="normal"
            label="Application"
            helperText="Application the application you want to configure or select custom if not listed"
          >
            {serviceProviders.map(item => {
              return <MenuItem value={item.id} key={item.id}>{item.appName}</MenuItem>;
            })}
          </TextField>
          {selectedApp.showAppName &&
          <TextField
            {...register("appName", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="App Name"
            fullWidth
            margin="normal"
            error={!!errors.appName}
            helperText={errors.appName?.message}
          />}
          {selectedApp.showEntityId &&
          <TextField
            {...register("entityId", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Entity ID"
            fullWidth
            margin="normal"
            error={!!errors.entityId}
            helperText={errors.entityId?.message}
          />}
          {selectedApp.showAcsUrl &&
          <TextField
            {...register("acsUrl", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Assertion Consumer Service URL"
            fullWidth
            margin="normal"
            error={!!errors.acsUrl}
            helperText={errors.acsUrl?.message}
          />}
          {selectedApp.showLogoutUrl &&
          <TextField
            {...register("logoutUrl", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Logout URL"
            fullWidth
            margin="normal"
            error={!!errors.logoutUrl}
            helperText={errors.logoutUrl?.message}
          />}
          {selectedApp.showNameID &&
          <TextField
            {...register("nameId")}
            select
            fullWidth
            margin="normal"
            label="Name ID"
            helperText="Select the user property to use as service provider NameID"
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="userID">User ID</MenuItem>
          </TextField>
          }
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Create'}
              </Button>
            </Box>
          </Box>
        </form>
        <Snackbar
          open={snack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => setSnack(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              background: '#303030 !important',
              borderRadius: '30px'
            }
          }}
          message="Copied to Clipboard"
        />
      </Box>
    </Modal>
  );
};

type UpdateServiceProviderInput = {
  initial_entity_id?: string;
  entity_id: string;
  appName: string;
  acsUrl: string;
  logoutUrl: string;
  nameId: string;
};

type UpdateServiceProviderModalProps = {
  isOpen: boolean;
  handleUpdate: (serviceProvider: any) => void;
  handleCancel: () => void;
  isSubmitting: boolean;
  spToUpdate: any;
};

const UpdateSpModal: FC<UpdateServiceProviderModalProps> = ({
  isOpen,
  handleUpdate,
  handleCancel,
  isSubmitting,
  spToUpdate,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
  } = useForm<UpdateServiceProviderInput>();

  const { orgId } = useParams();
  const [snack, setSnack] = useState(false)

  useEffect(() => {
    reset(spToUpdate);
  }, [isOpen]);

  const createSp = () => {
    if (!isDirty || !isValid) return;
    const serviceProvider = getValues();
    serviceProvider.initial_entity_id = spToUpdate.entity_id;
    handleUpdate(serviceProvider);
  };


  if (!spToUpdate) return null;


  const copy = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      setSnack(true);
    } catch (err) {
      console.log(err);

    }
  };
  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            Update Service Provider {spToUpdate.id}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <Box className='title-modal'>
          <Box sx={{ marginTop: 2 }}>
            <Box sx={{ marginBottom: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Typography fontSize={18}>
                  SAML Connection Details
                </Typography>
                <Button
                  sx={{ color: '#111111' }}
                  href={`${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                  download="metadata.xml"
                >
                  <TfiDownload size={16} />
                  <Typography sx={{ marginLeft: '5px' }} fontSize={16}>
                    Metadata
                  </Typography>
                </Button>
              </Box>

              <Typography color="#666666" variant="body1" component="p">
                Add the following information to your SAML Service Provider
              </Typography>
            </Box>
            <Typography color="#222449" variant="body1" component="p">
              <span style={{ color: '#666666' }}>Metadata URL:</span><br></br>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                <img style={{ cursor: 'pointer' }} onClick={() => copy(` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`)} width={24} height={24} src={Images.icCopy} />
              </div>
            </Typography>
            <Typography color="#222449" mt={2} variant="body1" component="p">
              <span style={{ color: '#666666' }}>SSO URL:</span><br></br>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`}
                <img style={{ cursor: 'pointer' }} onClick={() => copy(` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`)} width={24} height={24} src={Images.icCopy} />
              </div>
            </Typography>
          </Box>
        </Box>
        <form style={{ padding: '30px 60px', width: '100%' }} onSubmit={handleSubmit(createSp)}>
          <TextField
            {...register("appName", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="App Name"
            fullWidth
            margin="normal"
            error={!!errors.appName}
            helperText={errors.appName?.message}
          />
          <TextField
            {...register("entity_id", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Entity ID"
            fullWidth
            margin="normal"
            error={!!errors.entity_id}
            helperText={errors.entity_id?.message}
          />
          <TextField
            {...register("acsUrl", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Assertion Consumer Service URL"
            fullWidth
            margin="normal"
            error={!!errors.acsUrl}
            helperText={errors.acsUrl?.message}
          />
          <TextField
            {...register("logoutUrl", {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="Logout URL"
            fullWidth
            margin="normal"
            error={!!errors.logoutUrl}
            helperText={errors.logoutUrl?.message}
          />
          <TextField
            {...register("nameId")}
            select
            fullWidth
            margin="normal"
            label="Name ID"
            helperText="Select the user property to use as service provider NameID"
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="userID">User ID</MenuItem>
          </TextField>
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Update'}
              </Button>
            </Box>
          </Box>
        </form>
        <Snackbar
          open={snack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => setSnack(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              background: '#303030 !important',
              borderRadius: '30px'
            }
          }}
          message="Copied to Clipboard"
        />
      </Box>
    </Modal>
  );
};

export default ServiceProviderPage;
