import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import {
  getSubscriptionFn,
  getSubscriptionsFn,
} from "src/api/services/subscriptions.service";
import { Box, Button, Typography } from "@mui/material";
import SectionContainer from "src/dashboard/components/SectionContainer";
import LoadingIndicator from "src/components/LoadingIndicator";
import { getGeneralSettingsFn } from "src/api/services/general-settings.service";
import { Images } from "src/assets";

function SubscriptionOrgAdmin() {
  const [searchParams] = useSearchParams();
  const { data, isFetching } = useQuery({
    queryKey: ["subscriptions", Object.fromEntries(searchParams.entries())],
    queryFn: () =>
      getSubscriptionsFn({
        query: Object.fromEntries(searchParams.entries()),
      }),
  });
  const { data: generalSettings, isFetching: isFetchingGeneralSettings } =
    useQuery({
      queryKey: ["generalSettings"],
      queryFn: getGeneralSettingsFn,
    });

  const orgId = window.location.pathname.split("/").filter(Boolean)[2];

  const { data: subscription, isFetching: isFetchingSubscription } = useQuery({
    queryKey: ["subscription"],
    queryFn: () => getSubscriptionFn({ id: orgId }),
  });

  if (isFetching || isFetchingGeneralSettings || isFetchingSubscription) {
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );
  }

  return (
    <Box pt={5.5} >
      <Typography
        style={{
          fontSize: 30,
          fontWeight: "bold",
        }}
        sx={{
          "@media (max-width: 1670px)": {
           textAlign: 'center',
          },
        }}
      >
        The right plan for your business
      </Typography>
      <Box
        sx={{
          display: "flex",
          paddingRight: "90px",
          paddingLeft: "90px",
          gap: "85px",
          marginBottom: 5,
        }}
      >
        {data?.data.map((sub) => {

          return (
            <Box
              key={sub.id}
              sx={{
                width: 'calc(100% - 384.67px)',
                height: '587px',
                padding: '40px 32px',
                position: "relative",
                borderRadius: '24px',
                border: '1px solid #DCDBE8',
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>{sub.text}</Typography>
              {JSON.stringify(subscription) === JSON.stringify(sub.text) ?
                <Button
                  sx={{
                    position: 'absolute',
                    bottom: 20,
                    width: "85%",
                    "&:hover": { backgroundColor: "#F4FCF1" },
                    backgroundColor: "#F4FCF1", color: '#1B1B1B !important', gap: '5px', alignItems: "center"
                  }}
                  className="button-group" variant="contained" >
                  <img src={Images.icCheck} />
                  Current Plan
                </Button> :
                <Button
                  sx={{
                    position: 'absolute',
                    bottom: 20,
                    width: "85%",
                    "&:hover": { backgroundColor: "#111111" },
                    backgroundColor: "#111111"
                  }}
                  className="button-group" variant="contained" >
                  <Link
                    to={
                      generalSettings?.contact_upgrade_url
                        ? generalSettings?.contact_upgrade_url
                        : ""
                    }
                  >Upgrade</Link>
                </Button>}
            </Box>
          )
        })}
      </Box>
    </Box>
  );
}
export default SubscriptionOrgAdmin;
