import { Avatar, Box, Button } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { deleteUserImageFn, updateUserImageFn } from "src/api";
import { Images } from "src/assets";
import { useAuth } from "src/providers/AuthProvider";
import styles from "./UpdateUserImageForm.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { getInitialsAndColor } from "src/share/utils/getInitialsAndColor";


type UpdateUserImageFormInputs = {
  image: File;
};

interface UpdateUserImageFormProps {
  fullname: string
  imageURL?: string | null;
  onSubmit: () => void;
}

function UpdateUserImageForm({ imageURL, onSubmit, fullname }: UpdateUserImageFormProps) {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateUserImageFormInputs>();
  const [isDirty, setIsDirty] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const { mutate: updateUserImage, isLoading: isSubmitting } = useMutation(
    updateUserImageFn,
    {
      onSuccess: () => {
        onSubmit();
        setIsDirty(false);
        // alert("User image updated successfully");
      },
    }
  );

  const { mutate: deleteUserImage } = useMutation(
    deleteUserImageFn,
    {
      onSuccess: () => {
        onSubmit();
      },
    }
  );

  const submitUpdateUserImageForm = async () => {
    console.log(isValid);

    if (!user) return;
    const formData = new FormData();
    formData.append("image", image as File);
    console.log(formData, 'fdsfdf')
    updateUserImage({ userId: user.id, imageFile: formData });
  };
  const remove = () => {
    if (!user) return;
    setImage(null)
    deleteUserImage(user.id)
  }

  const changeImage = (e: ChangeEvent<HTMLInputElement>) => {
    setImage(e.target.files?.[0] || null);
    setIsDirty(true);
  };

  useEffect(() => {
    if (image !== null && isDirty) {
      submitUpdateUserImageForm();
    }
  }, [image, isDirty]);

  const { initials, color } = getInitialsAndColor(fullname || "User");

  return (
    <form>
      <Box
        sx={{
          margin: "0px 80px 0px 5px",
          width: 180,
          height: 180,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          top: 90,
          zIndex: 0,
        }}
      >
        <Avatar
          src={(image && URL.createObjectURL(image)) || imageURL || ""}
          sx={{
            width: 180,
            height: 180,
            // border: "3.2px solid white",
            backgroundColor: imageURL ? 'transparent' : color,
          }}
          alt="avatar"
        >
          {(!image && !imageURL) && initials }
        </Avatar>
        {location.pathname.includes('profile') && (
          <Box sx={{
            alignItems: 'center',
            marginTop: '23px',
            width: '150px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <label htmlFor="imageInput">
              <Button
                sx={{
                  "&:hover": { backgroundColor: "white", border: '1px solid #303030' },
                  color: '#111111',
                  border: '1px solid #303030',
                  borderRadius: '12px',
                  fontSize: '14px',
                  position: 'relative',
                  overflow: 'hidden',
                  cursor: 'pointer'
                }}
                variant="outlined"
              >
                Replace image
              </Button>
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                style={{
                  opacity: 0,
                  width: 130,
                  position: 'absolute',
                  top: 205,
                  left: 23,
                }}
                {...register("image", { required: true })}
                onChange={changeImage}
              />
            </label>
            <Button
              onClick={() => remove()}
              sx={{
                "&:hover": { backgroundColor: "white" },
                color: '#666666',
                fontSize: '14px',
                marginTop: "16px"
              }}
            >
              Remove image
            </Button>
          </Box>
        )}
      </Box>
      {errors.image && <span>This field is required</span>}
    </form>
  );
}

export default UpdateUserImageForm;
