import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

type LoadingIndicatorProps = {
  variant?: "fixed" | "fullWidth" | "fullScreen";
};

const containerDefaultStyles = {
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ variant = "fixed" }) => {
  let containerStyles;
  if (variant === "fixed") {
    containerStyles = {
      ...containerDefaultStyles,
      marginX: "auto",
      maxWidth: "500px",
      borderRadius: 2,
      padding: 2,
      aspectRatio: 1,
    };
  }
  if (variant === "fullWidth") {
    containerStyles = {
      ...containerDefaultStyles,
      padding: 2,
      width: "100%",
      height: "100%",
    };
  }
  if (variant === "fullScreen") {
    containerStyles = {
      ...containerDefaultStyles,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
    };
  }

  return (
    <Box sx={containerStyles}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingIndicator;
