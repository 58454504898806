import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error: any) => {
        alert(error.response.data.message);
      },
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
    mutations: {
      onError: (error: any) => {
        alert(error.response.data.message);
      },
    },
  },
});
