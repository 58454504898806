import { useState } from "react";
import { Typography, Popover, Box } from "@mui/material";

function LongTextCellWithPopover({ value }: { value: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography onClick={handleClick} variant="inherit">
        {value}
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 2,
            boxSizing: "border-box",
            maxWidth: "500px",
            maxHeight: "500px",
            overflow: "scroll",
            wordWrap: "break-word",
          }}
        >
          <Typography>{value}</Typography>
        </Box>
      </Popover>
    </div>
  );
}

export default LongTextCellWithPopover;
