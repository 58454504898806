import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Modal,
  TextField,
  Typography,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  Pagination,
  Switch,
  Divider,
  SwitchProps,
  Grid,
  SnackbarOrigin,
  Snackbar,
  Select,
} from "@mui/material";
import { RequireRole } from "src/routes";
import { USER_ROLES } from "src/share/constants";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  Organization,
  createOrganizationFn,
  deleteOrganizationFn,
  getOrganizationsFn,
  updateOrganizationFn,
} from "src/api";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { queryClient } from "src/query-client";
import SectionContainer from "src/dashboard/components/SectionContainer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { getSubscriptionsFn } from "src/api/services/subscriptions.service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IoMdClose, IoMdMore } from "react-icons/io";
import { Images } from "src/assets";
import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdKeyboardDoubleArrowDown } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import styled from "@emotion/styled";
import { useCalendarState } from "@mui/x-date-pickers/internals";

const OrganizationsHome = () => {
  const [rows, setRows] = useState<Organization[]>();
  const [filteredRows, setFilteredRows] = useState<Organization[]>();
  const [isDropdownListShow, setIsDropdownListShow] = useState(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const applyFilter = (data: any) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row: any) =>
      row.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);

  const [organizationModal, setOrganizationModal] = useState<{
    isOpen: boolean;
    headerText: string;
    organization?: EditOrganizationInput;
  }>({
    isOpen: false,
    headerText: "New Organization",
  });
  const {
    isFetching,
    isError,
    data: orgs,
  } = useQuery({
    queryKey: "organizations",
    queryFn: () => getOrganizationsFn(),
    keepPreviousData: true,
  });

  const { mutate: createOrganization, isLoading: isCreatingOrganization } =
    useMutation(createOrganizationFn, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "organizations" });
        closeOrganizationModal();
        handleClick()
      },
    });
  const { mutate: updateOrganization, isLoading: isUpdatingOrganization } =
    useMutation(updateOrganizationFn, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "organizations" });
        closeOrganizationModal();
      },
    });

  const { mutate: deleteOrganization } = useMutation(
    deleteOrganizationFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "organizations" });
        alert("The organization was succefully deleted");
      },
    }
  );

  useEffect(() => {
    if (!orgs) return;
    setRows(orgs);
  }, [orgs]);

  const handleCreateClick = () => {
    setOrganizationModal({
      isOpen: true,
      headerText: "New Organization",
    });
  };

  const handleCreateOrganization = async (
    organization: CreateOrganizationInput
  ) => {
    createOrganization({
      orgName: organization.orgName,
      isEnabled: Boolean(organization.isEnabled),
      minSequence: organization.minSequence,
      subscriptionText: organization.subscriptionText,
      parentOrgName: organization.parentOrgName,
      parentOrgId: Boolean(organization.parentOrgId),
    });
  };

  const handleUpdateOrganization = async (
    organization: EditOrganizationInput
  ) => {
    updateOrganization({
      orgId: organization.orgId,
      orgName: organization.orgName,
      isEnabled: Boolean(organization.isEnabled),
      minSequence: organization.minSequence,
      subscriptionText: organization.subscriptionText,
      parentOrgName: organization.parentOrgName ?? null,
      parentOrgId: Boolean(organization.parentOrgId),
    });
  };

  const handleDeleteClick = async (orgId: number) => {
    if (!orgId) return;
    if (window.confirm("Are you sure you want to delete this organization?")) {
      deleteOrganization({
        orgId,
      });
    }
  };

  const handleCancel = () => {
    setIsDropdownListShow(false);
    closeOrganizationModal();
  };

  const closeOrganizationModal = () => {
    setIsDropdownListShow(false);
    setOrganizationModal((prevOrgModal) => ({
      ...prevOrgModal,
      isOpen: false,
    }));
  };

  useEffect(() => {
    if (!orgs) return;
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const orgName = queryParams.get('org_name');

      if (orgName && filteredRows) {
        const organizationIndex = filteredRows.findIndex(org => org.name.toLowerCase() === orgName.toLowerCase());
        if (organizationIndex !== -1) {
          const newPage = Math.floor(organizationIndex / rowsPerPage) + 1;
          setPage(newPage);
          const rowIndexOnPage = organizationIndex % rowsPerPage;

          setTimeout(() => {
            const tableBody = document.querySelector('.MuiTableBody-root');
            if (tableBody) {
              const rows = tableBody.querySelectorAll('tr');
              if (rows[rowIndexOnPage]) {
                rows[rowIndexOnPage].scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }
          }, 0);

          navigate({ search: '' }, { replace: true });
        }
      }
    }
  }, [location.search, filteredRows, rowsPerPage, navigate]);

  const handleEditClick = useCallback(
    (id: number) => () => {
      setIsDropdownListShow(true);
      if (rows) {
        const findOrganizationById = (
          rows: Organization[]
        ): Organization | undefined => {
          for (const row of rows) {
            if (row.id === id) {
              return row;
            }
            if (row.subRows && row.subRows.length > 0) {
              const found = findOrganizationById(row.subRows);
              if (found) {
                return found;
              }
            }
          }
          return undefined;
        };

        const organization = findOrganizationById(rows);

        if (!organization) return;

        if (!organization) return;
        setOrganizationModal({
          isOpen: true,
          headerText: "Edit Organization",
          organization: {
            orgId: organization.id,
            orgName: organization.name,
            isEnabled: organization.isEnabled,
            minSequence: organization.minSequence,
            subRows: organization.subRows,
            parentOrgId: organization.parentOrgId,
            // minSequenceId: !!organization.minSequence,
            subscriptionText: organization.subscriptionText,
            parentOrgName: organization.parentOrgName,
          },
        });
      } else return;
    },
    [rows, setOrganizationModal]
  );

  const columns = useMemo<MRT_ColumnDef<Organization>[]>(
    //column definitions...
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 70,
      },
      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row }) => (
          <Link href={`${row.original.id}`}>{row.original.name}</Link>
        ),
      },

      {
        accessorKey: "minSequence",
        header: "Min Sequence",
      },
      {
        accessorKey: "isEnabled",
        header: "Is Enabled",
        Cell: ({ row }) => {
          return row.original.isEnabled ? <CheckIcon /> : <CloseIcon />;
        },
      },
      {
        header: "Actions",
        Cell: ({ row }) => (
          <Button onClick={handleEditClick(row.original.id)}>
            <EditIcon />
          </Button>
        ),
      },
      {
        accessorKey: "subscriptionText",
        header: "Subscription",
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return row.original.subscriptionText
            ? row.original.subscriptionText
            : "";
        },
      },
    ],
    [handleEditClick]
  );

  const table = useMaterialReactTable({
    columns,
    data: rows || [],
    enableExpanding: true,
    state: {
      isLoading: isFetching,
    },
    initialState: {
      density: "compact",
    },
    // renderTopToolbarCustomActions: () => (
    //   <OrganizationToolbar handleCreateClick={handleCreateClick} />
    // ),
  });

  if (isError) {
    return (
      <SectionContainer>
        <Typography variant="h4">Trouble loading organizations</Typography>
      </SectionContainer>
    );
  }



  const ActionsCell: React.FC<{ orgId: number }> = ({ orgId }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };



    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >

          <MenuItem onClick={handleEditClick(orgId)} sx={{ padding: '14px' }}>
            <img style={{ marginRight: '12px' }} src={Images.icEdit} width="20" height="20" />
            Edit organization
          </MenuItem>
          <MenuItem onClick={() => handleDeleteClick(orgId)} sx={{ padding: '14px' }} >
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete organization
          </MenuItem>
        </Menu >
      </>
    );
  };


  const handleExpand = (rowId: number) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter(id => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  return (
    <RequireRole role={USER_ROLES.SYM_ADMIN}>
      <>
        <Box
          sx={{
            height: 600,
            width: '100%',
            display: 'flex',
            flexDirection: "column",
            alignItems: 'flex-end',
            '& .column-style': {
              backgroundColor: '#F3F2F7',
              fontWeight: "bold"
            },
          }}
          pt={5}>
          <Box className="header-group">

            <div className="product-search">
              <CiSearch size={20} />
              <input
                type="text"
                placeholder="Search organization"
                className="products__search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <OrganizationToolbar handleCreateClick={handleCreateClick} />
          </Box>
          <TableContainer
            sx={{
              fontSize: '14px',
              color: '#111111',
              mt: 2,
              border: '1px solid #DCDBE8',
              width: '100%',
              height: "600px",
              borderRadius: '8px 8px 0 0',
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "break-spaces",
                fontWeight: "500",
                fontFamily: "Inter",
                lineHeight: 1.2,
              },
              "& .MuiDataGrid-columnHeader": {
                height: "unset",
              },
              "& .MuiDataGrid-columnHeaders": {
                maxHeight: "200px",
              },
            }}
          >
            <Table>
              <TableHead className="column-style">
                <TableRow>
                  <TableCell><MdKeyboardDoubleArrowDown size={20} /></TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Min Sequence</TableCell>
                  <TableCell>Is Enabled</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row) => (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton onClick={() => handleExpand(row.id)}>
                          {expandedRows.includes(row.id) ? (
                            <MdKeyboardArrowDown size={20} />
                          ) : (
                            <MdKeyboardArrowRight size={20} />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        <Link style={{ textDecoration: 'none', color: "#111111" }} href={`${row.id}`} >
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell>{row.minSequence}</TableCell>
                      <TableCell>
                        {row.isEnabled ? <img src={Images.icYes} /> : <img src={Images.icNo} />}
                      </TableCell>
                      <TableCell>{row.subscriptionText || ''}</TableCell>
                      <TableCell>
                        <ActionsCell orgId={row.id} />
                      </TableCell>
                    </TableRow>
                    {expandedRows.includes(row.id) && row.subRows && (
                      row.subRows.map(subRow => (
                        <TableRow key={subRow.id}>
                          <TableCell></TableCell>
                          <TableCell>{subRow.id}</TableCell>
                          <TableCell>{subRow.name}</TableCell>
                          <TableCell>{subRow.minSequence}</TableCell>
                          <TableCell>
                            {subRow.isEnabled ? <img src={Images.icYes} /> : <img src={Images.icNo} />}
                          </TableCell>
                          <TableCell>{subRow.subscriptionText || ''}</TableCell>
                          <TableCell>
                            <ActionsCell orgId={subRow.id} />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{
              padding: '5px 0',
              borderRadius: '0 0 8px 8px',
              width: '100%',
              justifyContent: 'flex-end',
              display: "flex",
              borderTop: "0px",
              border: '1px solid #DCDBE8',
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#303030',
                  color: 'white',
                },
              },
              '& .MuiPaginationItem-root.Mui-selected:hover': {
                backgroundColor: '#303030',
                color: 'white',
              },
            }}
            color="primary"
            count={Math.ceil((filteredRows?.length ?? 0) / rowsPerPage)}
            onChange={handleChangePage}
            page={page}
            size="large"
          ></Pagination>

          {/* <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#303030',
                  color: 'white',
                },
              },
            }}
            count={rows?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

        </Box>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={handleClose}
          sx={{
            '.MuiSnackbarContent-root': {
              background: '#303030 !important',
              borderRadius: '30px'
            }
          }}
          message="A new organization was created successfuly"
        />
        <OrganizationFormModal
          isSubmitting={isCreatingOrganization || isUpdatingOrganization}
          handleCreateOrganization={handleCreateOrganization}
          handleUpdateOrganization={handleUpdateOrganization}
          isOpen={organizationModal.isOpen}
          handleCancel={handleCancel}
          headerText={organizationModal.headerText}
          organization={organizationModal.organization}
          data={rows}
          isDropdownListShow={isDropdownListShow}
        />
      </>
    </RequireRole>
  );
};

interface OrganizationToolbarProps {
  handleCreateClick: () => void;
}


function OrganizationToolbar({ handleCreateClick }: OrganizationToolbarProps) {
  return (
    <Box sx={{ marginBottom: 1 }}>
      <Button
        sx={{
          width: 'auto !important',
          borderRadius: '12px',
          "&:hover": { backgroundColor: "#111111" },
          backgroundColor: "#111111",
        }}
        variant="contained" onClick={handleCreateClick}>
        New Organization
      </Button>
    </Box>
  );
}

interface OrganizationFormModalProps {
  isOpen: boolean;
  handleCreateOrganization: (
    org: CreateOrganizationInput
  ) => Promise<void> | void;
  handleUpdateOrganization: (
    org: EditOrganizationInput
  ) => Promise<void> | void;
  headerText: string;
  handleCancel: () => void;
  organization?: EditOrganizationInput;
  isSubmitting: boolean;
  data?: Organization[];
  isDropdownListShow: boolean;
}

function OrganizationFormModal({
  isOpen,
  handleCreateOrganization,
  handleUpdateOrganization,
  headerText,
  organization,
  handleCancel,
  isSubmitting,
  data,
  isDropdownListShow,
}: OrganizationFormModalProps) {
  const [searchParams] = useSearchParams();
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit: formHandleSubmit,
    getValues,
    setValue,
    reset,
    control,
  } = useForm<CreateOrganizationInput | EditOrganizationInput>({
    defaultValues: organization,
  });

  const { data: subscriptions } = useQuery({
    queryKey: ["subscriptions", Object.fromEntries(searchParams.entries())],
    queryFn: () =>
      getSubscriptionsFn({
        query: Object.fromEntries(searchParams.entries()),
      }),
  });



  useEffect(() => {
    reset(
      organization ?? {
        orgId: undefined,
        orgName: "",
        isEnabled: true,
        minSequence: null,
        subscriptionText: null,
        parentOrgName: "",
        parentOrgId: true,
      }
    );
  }, [reset, organization, isOpen]);

  const sendSubmit = async () => {
    if (!isValid) {
      return;
    }
    const isNew = organization === undefined;
    const newOrganization = getValues();
    if (isNew) {
      await handleCreateOrganization(newOrganization);
      return;
    }
    await handleUpdateOrganization({
      ...newOrganization,
      orgId: organization.orgId,
    });
  };

  let isCheckBoxShow = false;
  if (organization?.subRows === undefined) {
    isCheckBoxShow = true;
  } else {
    isCheckBoxShow =
      organization?.subRows.length === 0 && organization.parentOrgId === null;
  }

  let isSubscriptionsFieldShown = false;
  if (organization?.subRows !== undefined || organization?.subRows) {
    isSubscriptionsFieldShown = true;
  }

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }: { theme?: any }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#352A44' : '#352A44',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#352A44',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const isSequanceEnable = getValues("minSequence")
  const isMinSequanceShown = headerText === 'Edit Organization' && Boolean(isSequanceEnable)
  const curSequance = organization?.minSequence

  const preparedData = data?.filter((org) => org.id !== organization?.orgId);
  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            {headerText}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <form style={{ padding: '60px', width: '100%' }} onSubmit={formHandleSubmit(sendSubmit)}>
          {organization && (
            <Typography variant="body1">
              Organization ID: {organization.orgId}
            </Typography>
          )}
          <TextField
            {...register("orgName", { required: true })}
            helperText={errors.orgName && errors.orgName.message}
            error={!!errors.orgName}
            label="Organization Name"
            fullWidth
            margin="normal"
            required
            defaultValue={getValues("orgName")}
          />
          <Controller
            name="minSequenceId"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <>
                <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Typography color="#797880" variant="body1">Min Sequence</Typography>
                  {curSequance ? (
                    <IOSSwitch
                      defaultChecked={isMinSequanceShown}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        onChange(isChecked);
                        if (!isChecked && organization) {
                          setValue("minSequence", null);
                        }

                        if (isChecked && organization) {
                          setValue("minSequence", organization.minSequence)
                        }
                      }}
                    />
                  ) : (
                    <IOSSwitch
                      checked={!!value}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        onChange(isChecked);
                        if (!isChecked && organization) {
                          setValue("minSequence", null);
                        }
                      }}
                    />
                  )}
                </Box>
                {(value || isMinSequanceShown) && (
                  <Controller
                    name="minSequence"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <Select
                          sx={{
                            mt: 2
                          }}
                          fullWidth
                          id="sequence-select"
                          placeholder="Sequence"
                          value={value ? value.toString() : ""}
                          onChange={(e) => {
                            const newValue = e.target.value === "" ? null : Number(e.target.value);
                            onChange(newValue);
                          }}
                        >
                          {[2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                            <MenuItem key={number} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </Select>
                      </>

                    )}
                  />
                )}
              </>
            )}
          />
          {isSubscriptionsFieldShown && (
            <TextField
              {...register("subscriptionText", {
                required: false,
                setValueAs: (value) => {
                  return value === "" ? null : value;
                },
              })}
              select
              type="string"
              label="Select Subscription"
              margin="normal"
              value={getValues("subscriptionText") || ""}
              fullWidth
            >
              {subscriptions?.data?.map((option) => (
                <MenuItem key={option.id} value={option.text}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          )}

          <Controller
            name="isEnabled"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography color="#797880" variant="body1">Enabled</Typography>
                <IOSSwitch
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              </Box>
            )}
          />
          {isCheckBoxShow && isDropdownListShow && (
            <Controller
              name="parentOrgId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography color="#797880" variant="body1">Is Child Organization</Typography>
                    <IOSSwitch
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  </Box>
                  {value && (
                    <TextField
                      {...register("parentOrgName", {
                        required: false,
                        setValueAs: (value) => {
                          return value === undefined ? "" : value;
                        },
                      })}
                      select
                      type="string"
                      label="Select Organization"
                      margin="normal"
                      //eslint-disable-next-line no-constant-condition
                      value={undefined ? "" : getValues("parentOrgName")}
                      fullWidth
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: { maxHeight: 200, overflowY: "auto" },
                          },
                        },
                      }}
                    >
                      {preparedData?.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </>
              )}
            />
          )}
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                disabled={!isDirty || isSubmitting}
              >
                {organization ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

type CreateOrganizationInput = {
  orgName: string;
  isEnabled?: boolean;
  minSequence?: number | null;
  minSequenceId?: boolean;
  subRows: Organization[];
  parentOrgId: boolean;
  subscriptionText: string | null;
  parentOrgName: string | null;
};

type EditOrganizationInput = CreateOrganizationInput & {
  orgId: number;
};

export default OrganizationsHome;
