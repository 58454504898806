import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "src/providers/AuthProvider";
import { useState } from "react";
import CodeModal from "../CodeModal";
import { useMutation } from "react-query";
import { addPhoneFn, updateUserPhoneFn, validatePhoneFn } from "src/api";
import { countriesPhoneOptions } from "src/share/constants";

type UpdateUserPhoneFormInputs = {
  countryCode: string;
  phone: string;
};

interface UpdateUserPhoneFormProps {
  countryCode: string;
  phone: string;
  onSubmit: () => void;
}

function UpdateUserPhoneForm({ countryCode, phone, onSubmit }: UpdateUserPhoneFormProps) {
  const { user } = useAuth();
  const {
    register,
    control,
    formState: { isDirty, isValid, errors },
    handleSubmit,
    getValues,
    reset,
    trigger,
  } = useForm<UpdateUserPhoneFormInputs>({
    defaultValues: {
      countryCode,
      phone,
    },
  });
  const { mutate: addPhone, isLoading: isAddingPhone } = useMutation(
    addPhoneFn,
    {
      onSuccess: (uniqueStr) => {
        setPhoneUniqueStr(uniqueStr);
        setModalOpen(true);
      },
    }
  );
  const { mutate: validatePhone, isLoading: isValidatingPhone } = useMutation(
    validatePhoneFn,
    {
      onSuccess: () => {
        submitUpdateUserPhoneForm();
      },
    }
  );
  const { mutate: updateUserPhone, isLoading: isUpdatingUserPhone } =
    useMutation(updateUserPhoneFn, {
      onSuccess: () => {
        reset(
          {},
          {
            keepValues: true,
            keepIsValid: false,
            keepTouched: false,
            keepErrors: false,
            keepIsSubmitted: false,
          }
        );
        onSubmit()
        alert("Phone updated");
      },
    });
  const [phoneUniqueStr, setPhoneUniqueStr] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const submitUpdateUserPhoneForm = async () => {
    const isFormValid = await trigger();
    if (!isFormValid || !user) return;

    updateUserPhone({
      userId: user.id,
      countryCode: getValues("countryCode"),
      phoneNumber: getValues("phone"),
    });
  };

  const requestAddPhone = async () => {
    addPhone({
      countryCode: getValues("countryCode"),
      phoneNumber: getValues("phone"),
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const requestValidatePhone = async (code: string) => {
    if (!phoneUniqueStr) return;
    closeModal();
    validatePhone({ unique: phoneUniqueStr, code });
  };

  return (
    <>
      <form style={{ marginTop: 20, maxWidth: 600 }} onSubmit={handleSubmit(requestAddPhone)}>
        <Box sx={{ width: '600px', display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
          <Controller
            name="countryCode"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={countriesPhoneOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                value={countriesPhoneOptions.find(
                  (option) => option.value === value
                )}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    onChange("");
                  } else {
                    onChange(newValue.value);
                  }
                }}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '240px' }}
                    variant="outlined"
                    label="Country code"
                    error={errors.countryCode ? true : false}
                    helperText={
                      errors.countryCode ? errors.countryCode.message : null
                    }
                  />
                )}
              />
            )}
          />
          <TextField
            sx={{ width: '344px',marginBottom:'15px' }}
            {...register("phone", { required: "Phone number is required" })} // Adding validation rule
            margin="normal"
            fullWidth
            label="Phone"
            variant="outlined"
            error={errors.phone ? true : false}
            helperText={errors.phone ? errors.phone.message : null}
          />
        </Box>
        <Button
          sx={{
            "&:hover": { backgroundColor: "#111111" },
            width: "140px",
            height: "40px",
            padding: "10px 20px",
            borderRadius: "12px",
            color: "white",
            fontSize: 16,
            marginTop: 2,
            textTransform: "none",
            backgroundColor: "#111111",
          }}
          type="submit"
          variant="contained"
          disabled={
            !isDirty ||
            isAddingPhone ||
            isValidatingPhone ||
            isUpdatingUserPhone ||
            !isValid
          }
        >
          Update
        </Button>
      </form>
      <CodeModal
        screen={"phone"}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        onSubmit={requestValidatePhone}
      />
    </>
  );
}

export default UpdateUserPhoneForm;
