import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateUserSequenceForGroupUserActivationFn } from "src/api";
import AuthLayout from "src/layouts/AuthLayout";

type UpdateSequenceInputs = {
  sequence: number;
};

function UpdateSequenceForGroupUserActivationPage() {
  const { activateGroupUserToken } = useParams();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm<UpdateSequenceInputs>();
  const navigate = useNavigate();
  const { mutate: updateUserSequence, isLoading: isSubmitting } = useMutation(
    updateUserSequenceForGroupUserActivationFn,
    {
      onSuccess: () => {
        navigate(`/activate-group-user/${activateGroupUserToken}`);
      },
    }
  );

  const handleUpdateUserSequence = async () => {
    if (!isValid) return;
    if (!activateGroupUserToken) return;
    updateUserSequence({
      token: activateGroupUserToken,
      sequence: getValues("sequence"),
    });
  };

  const minSequence = location.state?.minSequence
    ? parseInt(location.state.minSequence)
    : 1;

  return (
    <AuthLayout>
      <Box
        sx={{
          borderRadius: 2,
          maxWidth: "500px",
          margin: "0 auto",
          backgroundColor: "white",
          overflow: "hidden",
          padding: 2,
        }}
      >
        <Typography variant="h4" component="h1">
          Update Sequence
        </Typography>
        <form onSubmit={handleSubmit(handleUpdateUserSequence)}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Sequence</InputLabel>
            <Select
              {...register("sequence", { required: true })}
              label="Sequence"
              defaultValue={getValues("sequence")}
            >
              <MenuItem value="" style={{ display: "none" }}></MenuItem>
              {Array.from(
                { length: 10 - minSequence },
                (_, i) => i + minSequence
              ).map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        </form>
      </Box>
    </AuthLayout>
  );
}

export default UpdateSequenceForGroupUserActivationPage;
