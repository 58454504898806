import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getUserDevicesFn = async ({ userId }: GetUserDevicesInput) => {
  const { data } = await apiRequest.get<GetUserDevicesResponse[]>(
    ENDPOINTS.USERS + `/${userId}/devices`
  );
  return data;
};

export const disableUserDevice = async ({ userID, UUID }: UserDevices) => {
  const { data } = await apiRequest.put(
    ENDPOINTS.USERS + `/${userID}/devices`,
    { UUID }
  );

  return data;
};

type GetUserDevicesInput = {
  userId: number | string;
};

export type UserDevices = {
  UUID: string;
  created_at: string;
  last_activity: string;
  enabled: number;
  token: string;
  userID: number;
};

type GetUserDevicesResponse = UserDevices;
