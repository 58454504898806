import {
  Box,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { getSecurityQuestionsFn, updateUserSecurityQuestionsFn } from "src/api";
import { useAuth } from "src/providers/AuthProvider";
import LoadingIndicator from "../LoadingIndicator";
import { queryClient } from "src/query-client";

type FormErrors = {
  answer1?: { message: string };
  answer2?: { message: string };
  answer3?: { message: string };
};

type SecurityQuestionsFormInputs = {
  question1Id: number;
  answer1: string;
  question2Id: number;
  answer2: string;
  question3Id: number;
  answer3: string;
};

interface UpdateSecurityQuestionsFormProps {
  question1Id: number;
  question2Id: number;
  question3Id: number;
  answer1: string;
  answer2: string;
  answer3: string;
}

type SecurityQuestion = {
  id: number;
  question: string;
};

type SecurityQuestionOption = SecurityQuestion & {
  disabled?: boolean;
};

function UpdateSecurityQuestionsForm({
  question1Id,
  question2Id,
  question3Id,
  answer1,
  answer2,
  answer3,
}: Partial<UpdateSecurityQuestionsFormProps>) {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid, isDirty, errors},
    watch,
  } = useForm<SecurityQuestionsFormInputs>({
    defaultValues: {
      question1Id: question1Id || ("" as any),
      question2Id: question2Id || ("" as any),
      question3Id: question3Id || ("" as any),
      answer1,
      answer2,
      answer3,
    },
  });
  const typedErrors = errors as FormErrors;

  const [remainingQuestions, setRemainingQuestions] = useState<
    SecurityQuestionOption[]
  >([]);
  const { isFetching: isQuestionsLoading, data: securityQuestions } = useQuery({
    queryKey: ["securityQuestions"],
    queryFn: () => getSecurityQuestionsFn(),
  });
  const { mutate: updateSecurityQuestions, isLoading: isSubmitting } =
    useMutation(updateUserSecurityQuestionsFn, {
      onSuccess: () => {
        reset(
          {},
          {
            keepValues: true,
            keepIsValid: false,
            keepTouched: false,
            keepErrors: false,
            keepIsSubmitted: false,
          }
        );
        queryClient.invalidateQueries(["userSecrets"]);
        alert("Security questions updated successfully");
      },
    });

  useEffect(() => {
    setRemainingQuestions(() => {
      const { question1Id, question2Id, question3Id } = getValues();
      return (securityQuestions ?? []).map((question: SecurityQuestion) => {
        return {
          ...question,
          disabled: [question1Id, question2Id, question3Id].includes(
            question.id
          ),
        };
      });
    });
  }, [securityQuestions]);

  const updateRemainingQuestions = () => {
    const { question1Id, question2Id, question3Id } = getValues();
    setRemainingQuestions(() => {
      return (securityQuestions ?? []).map((question) => {
        return {
          ...question,
          disabled: [question1Id, question2Id, question3Id].includes(
            question.id
          ),
        };
      });
    });
  };

  const submitSecurityQuestionsForm = async () => {
    if (!user) return;
    if (!isValid) {
      alert("Please fill all the fields");
      return;
    }
    updateSecurityQuestions({
      userId: user.id,
      question1Id: getValues("question1Id"),
      answer1: getValues("answer1"),
      question2Id: getValues("question2Id"),
      answer2: getValues("answer2"),
      question3Id: getValues("question3Id"),
      answer3: getValues("answer3"),
    });
  };

  const answers = watch(["answer1", "answer2", "answer3"]);
  const isAnyAnswerEmpty = answers.some(answer => !answer?.trim());

  if (isQuestionsLoading) return <LoadingIndicator variant="fullWidth" />;

  if (!securityQuestions || securityQuestions.length < 3)
    return (
      <div>
        <h1 className="text-center">Security Questions</h1>
        <p className="text-center">
          Sorry, we do not have enough security questions for you to select.
          Please contact us for support.
        </p>
      </div>
    );

  return (
    <form onSubmit={handleSubmit(submitSecurityQuestionsForm)}>
      <div>
        <Box mb={2} sx={{ marginTop: 11, fontWeight: 500, fontSize: 20 }}>
          Security questions in case of stolen devices
        </Box>
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index}>
            <Box>
              <TextField
                {...register(`question${index + 1}Id` as any, {
                  onChange: () => {
                    updateRemainingQuestions();
                  },
                })}
                select
                margin="normal"
                fullWidth
                variant="standard"
                defaultValue={getValues(`question${index + 1}Id` as any)}
                label={`Question ${index + 1}`}
                id={`question${index + 1}`}
              >
                {remainingQuestions.map((question) => (
                  <MenuItem
                    key={question.id}
                    value={question.id}
                    style={{ display: question.disabled ? "none" : "flex" }}
                  >
                    {question.question}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <TextField
              {...register(`answer${index + 1}` as any, {
                required: true,
                validate: {
                  noSpaces: value => value.trim() !== '' || 'Input cannot contain only spaces',
                },
              })}
              type="text"
              variant="standard"
              sx={{
                "& .MuiInput-underline::before": {
                  borderBottomColor: "#DCDBE8", // Change 'red' to your desired color
                },
                "& fieldset": { border: "none" },
                "& .MuiInputBase-input": {
                  padding: "14px 0px",
                  WebkitTextFillColor: "#111111",
                },
              }}
              InputLabelProps={{ style: { color: "#919099" } }}
              label={`Answer ${index + 1}`}
              margin="normal"
              error={Boolean((typedErrors as Record<string, { message: string }>)[`answer${index + 1}`])}
              helperText={(typedErrors as Record<string, { message: string }>)[`answer${index + 1}`]?.message}
              fullWidth
            />
          </div>
        ))}
      </div>
      <div>
        <Button
          sx={{
            "&:hover": { backgroundColor: "#303030" },
            mt: 3,
            textTransform: "none",
            backgroundColor: "#303030",
            width: 244,
            height: 48,
            borderRadius: "12px",
          }}
          variant="contained"
          type="submit"
          disabled={!isDirty || isSubmitting || isAnyAnswerEmpty}
        >
          Update
        </Button>
      </div>
    </form>
  );
}

export default UpdateSecurityQuestionsForm;
