import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getSymbolCategoriesFn = async (
  { includeEmpty }: GetSymbolCategoriesInput = { includeEmpty: false }
) => {
  const { data } = await apiRequest.get<GetSymbolCategoriesResponse>(
    ENDPOINTS.SYMBOL_CATEGORIES,
    { params: { include_empty: includeEmpty } }
  );
  return data;
};

export const createSymbolCategoryFn = async (
  category: Omit<SymbolCategory, 'catalogID'>
) => {
  await apiRequest.post(ENDPOINTS.SYMBOL_CATEGORIES, category);
};

export const updateSymbolCategoryFn = async (category: SymbolCategory) => {
  await apiRequest.put(
    `${ENDPOINTS.SYMBOL_CATEGORIES}/${category.catalogID}`,
    category
  );
};

export const deleteSymbolCategoryFn = async (categoryId: number) => {
  await apiRequest.delete(`${ENDPOINTS.SYMBOL_CATEGORIES}/${categoryId}`);
};

export const getCategorySymbolsFn = async ({ index, categories }: {index: number, categories: Array<SymbolCategory>}) => {
  const {catalogID} = categories[index]
  return getSymbolsFn({categoryId: catalogID})
};

export const getSymbolsFn = async ({ categoryId }: GetSymbolsInput) => {
  const { data } = await apiRequest.get<GetSymbolsResponse>(
    ENDPOINTS.SYMBOL_CATEGORIES + `/${categoryId}`
  );
  return data;
};


export const createSymbolFn = async (symbol: CreateSymbolInput) => {
  const formData = new FormData();
  formData.append('image', symbol.symbol);
  await apiRequest.post(
    `${ENDPOINTS.SYMBOL_CATEGORIES}/${symbol.categoryId}`,
    formData
  );
};

export const deleteSymbolFn = async ({
  categoryId,
  symbolId,
}: DeleteSymbolInput) => {
  await apiRequest.delete(
    `${ENDPOINTS.SYMBOL_CATEGORIES}/${categoryId}/${symbolId}`
  );
};

type GetSymbolCategoriesInput = {
  includeEmpty?: boolean;
};

type GetSymbolCategoriesResponse = Array<SymbolCategory>;

type GetSymbolsInput = {
  categoryId: number;
};

export type ISymbol = {
  symbolID: number;
  symbol: string;
}
type GetSymbolsResponse = Array<ISymbol>;

type CreateSymbolInput = {
  categoryId: number;
  symbol: File;
};

type DeleteSymbolInput = {
  categoryId: number;
  symbolId: number;
};

export type SymbolCategory = {
  catalogID: number;
  catalog_name: string;
  symbol_count?: number;
  symbols?: GetSymbolsResponse
};
