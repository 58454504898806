import { Box, Grid } from "@mui/material";

import { useQuery } from "react-query";
import { getUserSecrets } from "src/api";
import LoadingIndicator from "src/components/LoadingIndicator";
import UpdateSecurityQuestionsForm from "src/components/UpdateSecurityQuestionsForm";
import UpdateSymSettingsForm from "src/components/UpdateSymSettingsForm";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { useAuth } from "src/providers/AuthProvider";

function SecuritySettings() {
  const { user } = useAuth();
  const { isFetching, data: userSecrets } = useQuery({
    queryKey: ["userSecrets", user],
    queryFn: () => {
      if (!user) return;
      return getUserSecrets({ userId: user.id });
    },
  });

  if (isFetching)
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );

  const { symSettings, userSecurityQuestions, minSequence } = userSecrets ?? {
    symSettings: null,
    userSecurityQuestions: null,
    minSequence: 2,
  };

  return (
    <Box m={2}>
      <UpdateSecurityQuestionsForm
        question1Id={userSecurityQuestions?.question1.id}
        question2Id={userSecurityQuestions?.question2.id}
        question3Id={userSecurityQuestions?.question3.id}
        answer1={userSecurityQuestions?.question1.answer}
        answer2={userSecurityQuestions?.question2.answer}
        answer3={userSecurityQuestions?.question3.answer}
      />
    </Box>
  );
}

export default SecuritySettings;
