import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getGeneralSettingsFn = async () => {
  const { data } = await apiRequest.get<GeneralSettings>(
    ENDPOINTS.GENERAL_SETTINGS
  );
  return data;
};

export const updateGeneralSettingsFn = async (data: GeneralSettings) => {
  await apiRequest.put(ENDPOINTS.GENERAL_SETTINGS, data);
};

type GeneralSettings = {
  default_f_sequence_length_allowed: number;
  default_f_symbol_location_allowed: number;
  default_f_combo_allowed: number;
  min_seq: number;
  max_f_symbol: number;
  max_f_sequence: number;
  max_f_combo: number;
  contact_upgrade_url: string;
  subscription_upg_explanation: string;
};
