function Page403() {
  return (
    <div>
      <h1>403</h1>
      <p>Unauthorized</p>
    </div>
  );
}

export default Page403;
