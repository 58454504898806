import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { Filter, FilterColumns } from "src/share/types";
import FilterableDataTable, {
  PaginationModel,
} from "src/dashboard/components/DataTable/FilterableDataTable";
import LongTextCellWithPopover from "src/dashboard/components/LongTextCellWithPopover";
import { useQuery } from "react-query";
import { getApiLogsFn } from "src/api";
import { Box, Typography } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import AuditLogs from "./AuditLogs";
import EmailHistory from "./EmailHistory";

const filterColumns: FilterColumns = [
  {
    name: "id",
    type: "number",
    label: "Id",
  },
  {
    name: "source",
    type: "string",
    label: "Source",
  },
  {
    name: "endpoint",
    type: "string",
    label: "Endpoint",
  },
  {
    name: "response_status",
    type: "string",
    label: "Response Status",
  },
  {
    name: "timestamp",
    type: "timestamp",
    label: "Timestamp",
  },
  {
    name: "ip_address",
    type: "string",
    label: "IP Address",
  },
  {
    name: "phone_number",
    type: "string",
    label: "User Phone Number",
  },
  {
    name: "method",
    type: "string",
    label: "Method",
  },
];

const columns: GridColDef[] = [
  {
    field: "id", headerName: "Id", width: 80,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "method", headerName: "Method", width: 120,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "source", headerName: "Source", width: 150,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "endpoint", headerName: "Endpoint", width: 160,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "response_status", headerName: "Response Status", width: 170,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 160,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => {
      return new Date(params.value).toLocaleString();
    },
  },
  {
    field: "ip", headerName: "IP Address", width: 180,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
  },
  {
    field: "request_body",
    headerName: "Request Body",
    width: 200,
    headerClassName: "column-style",
    cellClassName: 'row-style',
    align: 'left',
    headerAlign: "left",
    renderCell: (params) => {
      return <LongTextCellWithPopover value={params.value} />;
    },
  },
  // {
  //   field: "response_body",
  //   headerName: "Response Body",
  //   width: 300,
  //   renderCell: (params) => {
  //     return <LongTextCellWithPopover value={params.value} />;
  //   },
  // },
  // { field: "user_id", headerName: "User ID", width: 70 },
];

function ApiLogs() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);

  const [rowCount, setRowCount] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const applyFilter = (data: GridRowsProp) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row) =>
      row.id.toString().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const {
    isFetching,
    isError,
    data: apiLogs,
  } = useQuery({
    queryKey: [
      "api-logs",
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () =>
      getApiLogsFn({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      }),
    keepPreviousData: true,
    staleTime: 0, // always get the latest api logs
  });

  useEffect(() => {
    if (!apiLogs) return;
    setRows(apiLogs.data);
    setRowCount(apiLogs.meta.total);
  }, [apiLogs]);

  const initFilters = useMemo(() => {
    const query = searchParams;
    const newFilters = [];
    for (const col of Array.from(query.keys())) {
      const value = query.get(col);
      if (value === null) continue;
      newFilters.push({ column: col, value });
    }
    return newFilters;
  }, []);

  const handleOnApplyFilters = (filters: Filter[]) => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});
    // set the search query of the current URL
    setSearchParams(query);
  };

  if (isError) {
    return (
      <SectionContainer sx={{ height: 600 }}>
        <Typography variant="h4">Trouble loading API logs</Typography>
      </SectionContainer>
    );
  }

  return (
    <>
      <Box
        mt={3}
        sx={{
          display: 'flex',
          gap: '18px',
          marginBottom:3,
          "@media (max-width: 1640px)": {
              marginTop: "87px",
          },
        }}
      >
        <Typography onClick={() => setActiveTab(1)} sx={{cursor:'pointer', opacity: activeTab == 1 ? 1 : 0.6 }} fontWeight={activeTab == 1 ? 'bold' : 'normal'} color={'#292929'}>API Logs</Typography>
        <Typography onClick={() => setActiveTab(2)} sx={{cursor:'pointer', opacity: activeTab == 2 ? 1 : 0.6 }} fontWeight={activeTab == 2 ? 'bold' : 'normal'} color={'#292929'}>Audit Logs</Typography>
        <Typography onClick={() => setActiveTab(3)} sx={{cursor:'pointer', opacity: activeTab == 3 ? 1 : 0.6 }} fontWeight={activeTab == 3 ? 'bold' : 'normal'} color={'#292929'}>Email History</Typography>
      </Box>
      {activeTab == 1 ?
        <Box sx={{
          height: 630,
          marginBottom:10,
          width: 'fit-content',
          '& .column-style': {
            backgroundColor: '#F3F2F7',
            fontWeight: "bold"
          },
          '& .row-style': {
            color: '#111111',
            fontSize: '14px'
          },

        }}>
          <Box>
            <div className="product-search">
              <CiSearch size={20} />
              <input
                type="text"
                placeholder="Search log"
                className="products__search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </Box>
          <FilterableDataTable
            loading={isFetching}
            initFilters={initFilters}
            columns={columns}
            rows={filteredRows}
            rowCount={rowCount}
            filterColumns={filterColumns}
            paginationModel={paginationModel}
            onPaginationChange={setPaginationModel}
            onApplyFilters={handleOnApplyFilters}
          />
        </Box>
        : activeTab == 2 ?
          <AuditLogs />
          :
          <EmailHistory />
      }
    </>

  );
}

export default ApiLogs;
