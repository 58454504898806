import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const addEmailFn = async ({ email }: AddEmailInput) => {
  const { data } = await apiRequest.post<AddEmailResponse>(ENDPOINTS.EMAILS, {
    email,
  });
  return data.unique;
};

export const validateEmailFn = async ({ unique, code }: ValidateEmailInput) => {
  await apiRequest.post(ENDPOINTS.CODES + '/validate', { unique, code });
};

type AddEmailInput = {
  email: string;
};

type AddEmailResponse = {
  unique: string;
};

type ValidateEmailInput = {
  unique: string;
  code: string;
};

export function emailLogin(email: string) {
  return apiRequest.post<{token: string}>('users/login', {
    email,
  })
}

export function phoneLogin({country_code, phone}: {country_code: string, phone: string}) {
  return apiRequest.post<{token: string}>('users/login', {
    country_code, phone
  })
}
