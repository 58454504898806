import { BoxProps, Box } from "@mui/material";
import { FC } from "react";

export const SectionContainer: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box pt={5} {...rest}>
      {children}
    </Box>
  );
};

export default SectionContainer;
