import { Button, ButtonProps, CircularProgress, ThemeProvider } from '@mui/material'
import React from 'react'
import { lightTheme } from 'src/share/themas'

export const ContainedButton = React.forwardRef<HTMLButtonElement, ButtonProps & { title: string, loading?: boolean }>(({ loading, title, ...others }, ref) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Button
        sx={{ height: '52px', borderRadius: '12px' }}
        ref={ref}
        className='rounded-md text-base' variant='contained' {...others}>
        {
          loading
            ?
            <CircularProgress className='my-1' size={'18px'} color='inherit' />
            :
            title
        }
      </Button>
    </ThemeProvider>
  )
})
