import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getOrganizationsFn = async () => {
  const { data } = await apiRequest.get<GetOrganizationsResponse[]>(
    ENDPOINTS.ORGANIZATIONS
  );
  return data;
};

export const getUserOrganizationsFn = async ({
  userId,
}: GetUserOrganozationsInput) => {
  const { data } = await apiRequest.get<string[]>(
    ENDPOINTS.USERS + `/${userId}/organizations`
  );
  return data;
};

type GetUserOrganozationsInput = {
  userId: number | string;
};

export const deleteUserHimselfFn = async ({
  userId,
  org,
}: deleteUserByHismself) => {
  await apiRequest.post(
    ENDPOINTS.USERS + `/${userId}/delete-userOrgByHimself`,
    {
      userId,
      org,
    }
  );
};

export const createOrganizationFn = async (
  createOrgDetails: CreateOrganizationInput
) => {
  await apiRequest.post(ENDPOINTS.ORGANIZATIONS, createOrgDetails);
};

export const updateOrganizationFn = async ({
  orgId,
  ...updatedOrgDetails
}: UpdateOrganizationInput) => {
  await apiRequest.put(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}`,
    updatedOrgDetails
  );
};

export const deleteOrganizationFn = async ({
  orgId,
}: DeleteOrganiztionInput) => {
  await apiRequest.delete(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/deleteOrg`,
  );
};

export const getOrgUsersFn = async ({
  orgId,
  page,
  pageSize,
  query,
}: GetOrganizationUsersInput) => {
  const { data } = await apiRequest.get<GetOrganizationUsersResponse>(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/users`,
    {
      params: {
        ...query,
        page: page + 1,
        limit: pageSize,
      },
    }
  );
  return data;
};

export const updateRequiredCookiesFn = async({ requiredCookie, orgId }: UpdateRequiredCookies) => {
  await apiRequest.post(ENDPOINTS.ORGANIZATIONS + `/${orgId}/updateRequiredCookies`, { requiredCookie })
}

export const deleteOrganizationUserFn = async ({
  userId,
  groupId,
}: deleteOrgUser) => {
  await apiRequest.post(ENDPOINTS.ORGANIZATIONS + '/delete-user', {
    userId,
    groupId,
  });
};

export const resendActivationEmailFn = async ({
  userId,
  groupId,
}: ResendActivationEmailInput) => {
  await apiRequest.post(ENDPOINTS.ORGANIZATIONS + '/resend-activation-link', {
    userId,
    groupId,
  });
  alert('Activation Email Sent');
};

export const importUsersCsvFn = async ({
  orgId,
  file,
}: ImportUsersCsvInput) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/import-users`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

type GetOrganizationsResponse = Organization;

export type Organization = {
  id: number;
  name: string;
  isEnabled: boolean;
  minSequence: number;
  parentOrgId: boolean;
  subscriptionText: string | null;
  subRows: Organization[];
  parentOrgName: string | null;
};

export type CreateOrganizationInput = {
  orgName: string;
  isEnabled: boolean;
  minSequence: number | null | undefined;
  subscriptionText: string | null;
  parentOrgName?: string | null;
  parentOrgId: boolean;
};

export type UpdateOrganizationInput = Required<CreateOrganizationInput> & {
  orgId: number;
};

type UpdateRequiredCookies = {
  requiredCookie: boolean;
  orgId: number | string;
}

type DeleteOrganiztionInput = {
  orgId: number | string;
};

type GetOrganizationUsersInput = {
  orgId: number | string;
  page: number;
  pageSize: number;
  query?: any;
};

type GetOrganizationUsersResponse = {
  data: any[];
  meta: {
    total: number;
  };
  requiredCookies: {
    required_cookie: boolean;
  }
};

type ResendActivationEmailInput = {
  userId: number;
  groupId: number;
};

type ImportUsersCsvInput = {
  orgId: number | string;
  file: any;
};
type deleteUserByHismself = {
  userId: string | number;
  org: string;
};

type deleteOrgUser = {
  userId: number;
  groupId: number;
};
