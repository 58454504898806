import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  getSymbolCategoriesFn,
  getSymbolsFn,
  updateUserSymbolFn,
} from "src/api";
import { useAuth } from "src/providers/AuthProvider";
import { queryClient } from "src/query-client";
import { ChangeEvent } from 'react';
import LoadingIndicator from "../LoadingIndicator";
import './UpdateSymSettingsForm.module.css'
import { decryptFn } from "src/share/utils/decrypt";
type SymSettingsFormInputs = {
  sequence: number;
  symbol: number;
  matrixSize: string;
};

interface UpdateSymSettingsFormProps {
  sequence: number;
  catalogId: number;
  symbolId: number;
  matrixSize: string;
}

function UpdateSymSettingsForm({
  sequence,
  catalogId,
  symbolId,
  matrixSize,
  minSequence,
}: Partial<UpdateSymSettingsFormProps> & { minSequence: number }) {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
    control,
    reset,
  } = useForm<SymSettingsFormInputs>({
    defaultValues: {
      sequence: sequence || ("" as any),
      symbol: symbolId,
      matrixSize: matrixSize || "3x4",
    },
  });
  const [symbolCategoryId, setSymbolCategoryId] = useState<number>(
    catalogId || 1
  );
  const [curSeq, setCurSeq] = useState<any>(sequence);
  const [curMatrixSize, setCurMatrixSize] = useState<any>(matrixSize);
  const [curSymbol, setCurSymbol] = useState<any>(symbolId)


  const handleSymbolChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurSymbol(event.target.value)
  }

  const handleSequenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurSeq(event.target.value);
  };

  const handleMatrixSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurMatrixSize(event.target.value)
  }

  const isSameMatrixSize = String(curMatrixSize) === matrixSize;
  const isSameSymbol = Number(curSymbol) === symbolId;
  const isSameSeq = curSeq === sequence;

  const { isFetching: isCategoriesLoading, data: symbolCategories } = useQuery({
    queryKey: ["symbolCategories"],
    queryFn: () => getSymbolCategoriesFn({ includeEmpty: true }),
  });
  const { isFetching: isSymbolsLoading, data: symbols } = useQuery({
    queryKey: ["symbols", symbolCategoryId],
    queryFn: () => getSymbolsFn({ categoryId: symbolCategoryId }),
  });
  const { mutate: updateUserSymbol, isLoading: isSubmitting } = useMutation(
    updateUserSymbolFn,
    {
      onSuccess: (updatedField) => {
        reset(
          {},
          {
            keepValues: true,
            keepIsValid: false,
            keepTouched: false,
            keepIsSubmitted: false,
          }
        );
        queryClient.invalidateQueries(["userSecrets"]);
        alert(`Symania settings updated`);
      },
    }
  );

  const submitSelectSymbolForm = async () => {
    if (!isValid) {
      alert("Please select a symbol");
      return;
    }
    if (!user?.id) {
      return;
    }
    const [matrixSizeW, matrixSizeH] = getValues("matrixSize")
      .split("x")
      .map((val) => parseInt(val));
    updateUserSymbol({
      userId: user.id,
      symbol: getValues("symbol"),
      sequence: getValues("sequence"),
      matrixSizeH,
      matrixSizeW,
    });
  };

  const handleSymbolCategoryChange = (e: number) => {
    setSymbolCategoryId(e);
    if (e === catalogId) {
      setCurSymbol(symbolId);
    }
  };

  if (isCategoriesLoading) {
    return <LoadingIndicator variant="fullWidth" />;
  }

  return (
    <form onSubmit={handleSubmit(submitSelectSymbolForm)}>
      <Grid
        sx={{
            "@media (max-width: 1550px)": {
              marginTop: "40px",
              },
            }}
        container
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <FormControl fullWidth margin="normal">
            <Box mt={3} sx={{ fontWeight: 500, fontSize: 20 }}>
              Your sequence
            </Box>
            <TextField

              {...register("sequence", { required: true })}
              select
              sx={{
                marginTop: '32px',
                '& .MuiInputBase-root': {
                  height: '46px',
                },
              }}
              margin="normal"
              fullWidth
              onChange={handleSequenceChange}
              variant="outlined"
              inputProps={{ IconComponent: () => null }}
              label="Sequence"
              defaultValue={sequence}
            >
              <MenuItem value={sequence} style={{ display: "none" }}></MenuItem>
              {Array.from(
                { length: 10 - minSequence },
                (_, i) => i + minSequence
              ).map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>
            <Box mt={5} mb={2} sx={{ fontWeight: 500, fontSize: 20 }}>
              Your personal symbol
            </Box>
            <Typography fontSize={18} fontWeight={"bold"} mt={2} marginBottom={1}>
              Catalogs
            </Typography>
            {isSymbolsLoading ? (
              <LoadingIndicator variant="fullWidth" />
            ) : (
              <MenuList>
                {symbolCategories?.map((category) => (
                  <MenuItem
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: category.catalogID === symbolCategoryId ? '#F3F2F7' : 'white'
                      },
                      marginTop: '12px',
                      border: '1px solid #D2DAE8',
                      borderRadius: '8px',
                    }}

                    key={category.catalogID}
                    onClick={() => handleSymbolCategoryChange(category.catalogID)}
                    selected={category.catalogID === symbolCategoryId}
                  >
                    <ListItemText
                      sx={{ alignItems: 'center', display: "flex", height: "40px", justifyContent: 'space-between' }}
                      primary={category.catalog_name}
                      secondary={`(${category.symbol_count})`}
                      secondaryTypographyProps={{ color: '#111111' }}
                    />
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </FormControl>
          <Grid mt={3} xs={6} container spacing={1}>
            <Button
              sx={{
                "&:hover": { backgroundColor: "#303030" },
                mt: 3,
                textTransform: "none",
                backgroundColor: "#303030",
                height: 48,
                ml: '15px',
                width: 244,
                borderRadius: "12px",
              }}
              variant="contained"
              type="submit"
              disabled={(isSameSeq && isSameMatrixSize && isSameSymbol) ||
                (!getValues("symbol") && symbolCategoryId === catalogId)}
            >
              Update
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ position: "relative" }} item xs={12} md={1}>
        <Divider sx={{ position: 'absolute', marginLeft:'40px', top: 230, height: '50%',  borderColor: '#D2DAE8', width: '1px' }} orientation="vertical" />

        </Grid>
        <Grid item xs={12} md={6.7}>
          <Box mt={5} sx={{ fontWeight: 500, fontSize: 20 }}>
            Matrix size
          </Box>
          <Controller
            rules={{ required: true }}
            control={control}
            name="matrixSize"
            render={({ field }) => (
              <RadioGroup sx={{ gap: 1.2, margin: '32px 0', marginLeft: '10px' }}
                row {...field}>
                {["3x3", "3x4", "4x4"].map((size) => (
                  <FormControlLabel
                   onChange={() => setCurMatrixSize(size)}
                    value={size}
                    control={<Radio sx={{ display: 'none' }} />}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '46px',
                          width: '158px',
                          borderRadius: '5px',
                          border: `1px solid ${field.value === size ? '#5E5C78' : '#DCDBE8'}`,
                          backgroundColor: field.value === size ? '#F3F2F7' : '#FFFFFF',
                        }}>
                        <Typography fontWeight={field.value === size ? 'bold' : 'normal'} color={field.value === size ? '#111111' : '#797880'} fontSize={18}>{size}</Typography>
                      </Box>
                    }
                  />))}
              </RadioGroup>
            )}
          />
          <Box mt={10} sx={{ fontWeight: 500, fontSize: 20 }}>
            {symbolCategories?.find(item => item.catalogID == symbolCategoryId)?.catalog_name}
          </Box>
          <Box className="scroll" sx={{
            scrollbarWidth: 'thin',
            paddingRight:'20px',
            overflowY: 'auto', height: '520px'
          }} >
          <Grid mt={2} container spacing={2}>
            {(symbols ?? []).length === 0
              ? "Sorry. We don't have any symbols to select. Please contact us for support."
              : (symbols ?? []).map((s) => (
                <Grid item xs={12} md={3} key={s.symbolID}>
                  <Button
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: '8px',
                      width: '124px',
                      height: '124px',
                      paddingLeft: '8px',
                      border:
                          curSymbol == s.symbolID
                            ? "2px solid #FB3B8C"
                            : "1px solid #DCDBE8",
                    }}
                  >
                    <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        {...register("symbol", { required: true })}
                        value={s.symbolID}
                        onChange={handleSymbolChange}
                        defaultChecked={
                          s.symbolID === symbolId &&
                          symbolCategoryId === catalogId
                        }
                      />
                      <img
                        style={{
                          maxWidth: "100%",
                          aspectRatio: 1,
                          width: "44px",
                        }}
                        src={decryptFn(s.symbol)}
                        alt={s.symbolID.toString()}
                      />
                    </label>

                  </Button>
                </Grid>
              ))}
          </Grid>
          </Box>
          {/* <Box mt={9} mb={2} sx={{ fontWeight: 500, fontSize: 20 }}>
            Matrix size
          </Box>
          <TextField
            {...register("matrixSize", { required: true })}
            select
            margin="normal"
            fullWidth
            onChange={handleMatrixSizeChange}
            sx={{
              "& .MuiInput-underline::before": {
                borderBottomColor: "#DCDBE8", // Change 'red' to your desired color
              },
              "& fieldset": { border: "none" },
              "& .MuiInputBase-input": {
                paddingTop: "8px",
                WebkitTextFillColor: "#111111",
              },
            }}
            InputLabelProps={{ style: { color: "#919099" } }}
            variant="standard"
            inputProps={{ IconComponent: () => null }}
            label="Matrix Size"
            defaultValue={matrixSize}
          >
            {["3x3", "3x4", "4x4"].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </TextField> */}
        </Grid>
      </Grid>
      <div>
      </div>
    </form>
  );
}

export default UpdateSymSettingsForm;
