import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Typography,
} from "@mui/material";
import LoadingIndicator from "src/components/LoadingIndicator";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { useAuth } from "src/providers/AuthProvider";
import { formatDate } from "src/share/utils/formatDate";
import {
  UserDevices,
  disableUserDevice,
  getUserDevicesFn,
} from "src/api/services/devices.service";

const style = {
  position: "absolute" as const,
  top: "47%",
  left: "40%",
  transform: "translate(-50%, -50)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  textAlign: "center",
};

function Devices() {
  const [open, setOpen] = useState(false);
  const [currentDevice, setCurrentDevice] = useState<UserDevices | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userDevices, setUserDevices] = useState<UserDevices[] | undefined>();

  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    getUserDevicesFn({ userId: user.id })
      .then((devices) => {
        setUserDevices(devices);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user devices:", error);
        setIsLoading(false);
      });
  }, [user]);

  const handleOpen = (device: UserDevices) => {
    setCurrentDevice(device);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleDisableDevice = () => {
    if (currentDevice) {
      disableUserDevice(currentDevice)
        .then(() => {
          setUserDevices((prevDevices) =>
            prevDevices?.filter((device) => device.UUID !== currentDevice.UUID)
          );
          handleClose();
        })
        .catch((error) => {
          console.error("Error disabling device:", error);
        });
    }
  };

  if (isLoading)
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    );

  return (
    <Box p={2} borderRadius={2} bgcolor="white">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              UUID
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              CREATED AT
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              LAST ACTIVITY
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userDevices?.map((device) => (
            <TableRow
              key={device.UUID}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{device.UUID}</TableCell>
              <TableCell align="center">
                {formatDate(device.created_at)}
              </TableCell>
              <TableCell align="center">
                {formatDate(device.last_activity)}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => {
                    handleOpen(device);
                  }}
                >
                  Disable
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Are you sure you want to disable this device?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "30px",
              marginTop: "10px",
            }}
          >
            <Button variant="contained" onClick={handleDisableDevice}>
              Yes
            </Button>
            <Button variant="contained" onClick={handleClose}>
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Devices;
